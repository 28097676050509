import React, { useEffect, useState } from "react";

import { useToast } from 'rc-toastr';

import {
    FiX,
} from "react-icons/fi";

import TextField from '@mui/material/TextField';

import './Account.css';

import Verified from '../../../assets/images/dashboard/verified.png';

import {
    ChangeName,
    GetDeviceId, 
    ResetDeviceId
} from '../../../api/api';

import { useUser } from '../../../context/UserContext';



const AccountSettingPage = () => {
    
    const { toast } = useToast();
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');

    const { user, setUser } = useUser();
   
    const [openChangeName, setOpenChangeName] = useState(false);

    const [nameState, setNameState] = useState('');
    const [emailState, setEmailState] = useState('');
    const [deviceState, setDeviceState] = useState('');

    //Reset
    const [showResetModal, setShowResetModal] = useState(false);

    const handleShowResetModal = async () => {
        if (deviceState != null) {
            setShowResetModal(true);
        } else {
            toast.warning("You must be logged in with only one device");
        }
    }

    const handleResetDeviceId = async () => {
        var data = localStorage.getItem('myAccount');
        if (data !== null) {
            data = JSON.parse(data);
            const { addressWallet, email } = data;
            if (addressWallet !== '' && email !== '') {
                if (deviceState !== null) {
                    const resetDevice = await ResetDeviceId(addressWallet, email, token);
                    if (resetDevice.status.code === 200) {
                        setDeviceState(null);
                        setShowResetModal(false);
                        toast.success("Reset succesfully!");
                    } else {
                        toast.warning(resetDevice.status.message);
                    }
                } else {
                    toast.warning("Reset fail!");
                }
            }
        }
    }

    const init = async () => {
        var data = localStorage.getItem('myAccount');
        if (data !== null) {
            data = JSON.parse(data);
            setNameState(data.name);
            setEmailState(data.email);
        }
    }

    const getDeviceID = async () => {
        var data = localStorage.getItem('myAccount');
        if (data !== null) {
            data = JSON.parse(data);
            const { addressWallet, email } = data;
            if (addressWallet !== '' && email !== '') {
                const getData = await GetDeviceId(addressWallet, email, token);
                if (getData.status.code === 200) {
                    if (getData.data !== null) {
                        setDeviceState(getData.data.deviceId);
                    } else {
                        setDeviceState(null);
                    }
                }
            }
        }
    }

    const ApplyName = async () => {
        let data = localStorage.getItem('myAccount');
       
        if (data !== null) {
            data = JSON.parse(data);
            const { email, addressWallet } = data;
            if (nameState !== '') {
                const change = await ChangeName(email, addressWallet, nameState, token);
                if (change.status.code === 200) {
                    const data_result = {
                        ...data,
                        name : nameState
                    };
                    const user_result = {
                        ...user,
                        name : nameState
                    };

                    setUser(user_result);
                    localStorage.setItem('myAccount', JSON.stringify(data_result));

                    toast.success(change.status.message);

                    setTimeout(() => {
                        setOpenChangeName(false);
                    }, 2000);

                } else {
                    toast.warning(change.status.message);
                }
            } else {
                toast.warning('Name is empty !');
            }
        }
    }

    useEffect(() => {
        init();
        getDeviceID();
    }, []);


    return (
        <>
            <h3 className="title_main">ACCOUNT SETTINGS</h3>
                <h3 className="">GAME PROFILE</h3>
                <div className="info_name">
                    <label>Name</label>
                    <div className="name">
                        <span>{nameState}</span>
                        <button className="btn btn-changeName" onClick={() => { setOpenChangeName(true) }}>Change Name</button>
                    </div>
                </div>
                <div className="info_email">
                    <label>Email</label>
                    <div className="email">
                        <span>{emailState}</span>
                        <img src={Verified} alt="" />
                    </div>
                </div>
                <div className="info_name">
                    <label>Reset Device</label>
                    <div className="name">
                        <span className="text-truncate-line-one">{deviceState == null ? "You can log in on any device" : deviceState}</span>
                        <button className="btn btn-changeName" onClick={() => handleShowResetModal()}>Reset</button>
                    </div>
                </div>

                {
                    showResetModal && (
                        <div className="popUp show">
                            <div className="popUp_Content popUp_Checkout">
                                <FiX className="close-popUp" size={24} onClick={() => { setShowResetModal(!showResetModal) }} />
                                <span>Do you want to reset DeviceId or not?</span>
                                <div className="d-flex align-items-center justify-content-center gap-1 btn-wrapCheckOut">
                                    <button className="btn btn-cancel" onClick={() => { setShowResetModal(!showResetModal) }}>NO</button>
                                    <button className="btn btn-confirmCheckOut" onClick={handleResetDeviceId}>YES</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                openChangeName && (
                    <div className="popUp show popUpWrapper_LinkToAccount">
                        <div className="popUp_Content popUp_LinkToAccount">
                            <FiX className="close-popUp" size={24} onClick={() => {
                                setOpenChangeName(false);
                            }} />
                            <h3 className="text-primary text-uppercase">Change name</h3>
                            <div className="w100">
                                <TextField id="outlined-basic" label="Name" variant="outlined" value={nameState} onChange={(e) => setNameState(e.target.value)} />
                            </div>
                            {/* {
                                nameStatus && (
                                    <>
                                        <Alert className="noti-item" severity="error">Change name error!</Alert>
                                        <Alert className="noti-item" severity="success">Change name success!</Alert>
                                    </>
                                )
                            } */}
                            <button className="btn btn-main btn-linkAccount" onClick={ApplyName}>Apply</button>
                        </div>
                    </div>
                )
            }
        </>
    );

}

export default AccountSettingPage;
