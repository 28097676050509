import React, {
    useState,
    useEffect,
    useCallback
} from "react";

import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { jwtDecode } from "jwt-decode";
import { useUser } from './context/UserContext';

import RouteGuard from "./components/RouteGuard/RouteGuard";

import { setAuthToken } from "./helpers/setAuthToken";

import { TonConnectUIProvider } from '@tonconnect/ui-react';

import IndexPage from './pages/IndexPage';
import DashBoardPage from './pages/DashBoard';

//page
import ConnectPage from "./pages/DashBoard/Connect/Index";
import AccountPage from "./pages/DashBoard/Account/Account";
import RacerBox from "./pages/DashBoard/RacerBox/RaceBox";
import WhiteListPage from "./pages/DashBoard/WhiteList/whiteList";
import MarketPlace from "./pages/DashBoard/Market/Market";
import RacerBoxDetail from "./pages/DashBoard/RacerBox-Detail/Detail";
import OpenRacerBox from "./pages/DashBoard/OpenRacerBox/Open";
import Affiliates from './pages/DashBoard/Affiliates/Affiliates';

function App() {

    const { setUser } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    //check jwt token
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
    if (token) {
        setAuthToken(token);
    }

    const logOut = useCallback(() => {
        setUser(null);
        localStorage.clear();
        if(location.pathname === '/main-app'){
            navigate("/main-app");
        } else{
            navigate("/");
        }
       
    }, []);

    useEffect(() => {
        let timerRef = null;
        const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
        if (token) {
            const decoded = jwtDecode(token);

            const expiryTime = (new Date(decoded.exp * 1000)).getTime();

            const currentTime = (new Date()).getTime();

            const timeout = expiryTime - currentTime;

            const onExpire = () => {
                logOut();
            };

            if (timeout > 0) {
                // token not expired, set future timeout to log out and redirect
                timerRef = setTimeout(onExpire, timeout);
            } else {
                // token expired, log out and redirect
                onExpire();
            }

            // Clear any running timers on component unmount or token state change
            return () => {
                clearTimeout(timerRef);
            };
        }
    }, [logOut, navigate]);

    return (
        <TonConnectUIProvider manifestUrl="https://meta-racers.com/tonconnect-manifest.json">
            <Routes>
                <Route exact path="/" element={<IndexPage />} />
                <Route exact path="/connect" element={<DashBoardPage><ConnectPage /></DashBoardPage>} />
                
                
                <Route exact path="/market" element={<DashBoardPage><MarketPlace /></DashBoardPage>} />
                <Route exact path="/racer-box-detail" element={<DashBoardPage><RacerBoxDetail /></DashBoardPage>} />
                <Route exact path="/open-box-detail" element={<DashBoardPage><OpenRacerBox /></DashBoardPage>} />
                <Route exact path="/affiliates" element={<DashBoardPage><Affiliates /></DashBoardPage>}>
              </Route>
                <Route element={<RouteGuard />}>
                    <Route exact path="/my-account" element={<DashBoardPage><AccountPage /></DashBoardPage>} />
                    <Route exact path="/white-list" element={<DashBoardPage><WhiteListPage /></DashBoardPage>} />
                    <Route exact path="/racer-box" element={<DashBoardPage><RacerBox /></DashBoardPage>} />
                    <Route exact path="/racer-box-detail" element={<DashBoardPage><RacerBoxDetail /></DashBoardPage>} />
                    <Route exact path="/open-box-detail" element={<DashBoardPage><OpenRacerBox /></DashBoardPage>} />
                </Route>
            </Routes>
        </TonConnectUIProvider>
    );

}

export default App;
