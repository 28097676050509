import React, { useEffect, useState } from "react";

import moment from 'moment';
import 'moment-timezone';

import Pagination from 'react-bootstrap/Pagination';

import {
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import NotFound from '../../../assets/images/dashboard/notfound.svg';
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';

const NotFoundData = () => {
    return (
        <div className="data-notFound">
            <img src={NotFound} alt="" />
            <span className="text-primary">Sorry, no result found.</span>
        </div>
    )
}

const HistoryTonTable = ({data}) => {

    const [selectedList, setSelectedList] = useState(data);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = selectedList ? selectedList.slice(indexOfFirstItem, indexOfLastItem) : selectedList;

    const totalItems = selectedList.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="table-responsive pt-4">
                <table className="table table-bordered">
                    <thead>
                        <tr className="text-center text-white">
                            <th>Date</th>
                            <th>AmountSend</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItems.length ?
                                currentItems.map((item, index) => {
                                    return (
                                        <tr className="text-center" key={index}>
                                            <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                            <td>
                                                <span className="fw-bolder">{item.amountSend} <img src={TonCoin} alt="" width={16} /></span>
                                            </td>
                                            <td>
                                                <span className="fw-bolder">{item.status}</span>
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>
                                        <NotFoundData />
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                selectedList.length ?
                    <Pagination className="justify-content-end">
                        <Pagination.Prev
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage > 1 ? prevPage - 1 : prevPage
                                )
                            }
                            disabled={prevDisabled}
                        >
                            <FiChevronsLeft className="text-primary" />
                        </Pagination.Prev>
                        {pageNumbers.map((number) => (
                            <Pagination.Item
                                key={number}
                                active={number === currentPage}
                                onClick={() => handlePageChange(number)}
                            >
                                {number}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                )
                            }
                            disabled={nextDisabled}
                        >
                            <FiChevronsRight className="text-primary" />
                        </Pagination.Next>
                    </Pagination>
                    :
                    ""
            }
        </>
    );

}

export default HistoryTonTable;
