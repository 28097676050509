import React, { useEffect, useState } from 'react';

import { FiFileText, FiUsers, FiBarChart, FiShield, FiGift, FiSettings, FiTarget } from "react-icons/fi";

import './Affiliates.css';

import Banner from '../../../assets/images/home/banner-min.png';

import { useToast } from 'rc-toastr';

import REFERRALABI from './../abi/Referral.json';

import {
    CreateAccountWithRef, GetSponsor
} from '../../../api/api';

const LoadingPage = ({ content }) => {
    return (
        <div className="loading-wrapper loading-Openbox">
            <div className="loading-container">
                <p className="mb-0">Please wait</p>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                {
                    content ?
                        <span className="please-wait-text">{content}</span>
                        :
                        ''
                }
            </div>
        </div>
    )
}


const Affiliates = ({ web3, address }) => {

    const { toast } = useToast();

    const queryParams = new URLSearchParams(window.location.search);
    const ref = queryParams.get('ref');


    //Loading
    const [onLoading, setOnLoading] = useState(false);


    const [emailCreate, setEmailCreate] = useState('')
    const [passwordCreate, setPasswordCreate] = useState('');
    const [rePasswordCreate, setRePasswordCreate] = useState('');
    const [addressSupportor, setAddressSupportor] = useState('');
    const [readOnlyAddressSupportor, setReadOnlyAddressSupportor] = useState(false);
    const [disableButtonCreate, setDisableButtonCreate] = useState(false);

    const addressReferralSC = '0x4BE4439724FAbE3d5173022E6F62f465C5E96B07';

    function validateCreateAccount(emailCreate, passwordCreate, rePasswordCreate, addressSupportor) {
        var validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailCreate == null || emailCreate == '') {
            toast.warning('Email is required !');
            return false;
        } else if (validEmail.test(String(emailCreate.trim()).toLowerCase()) === false) {
            toast.warning('Input email wrong !');
            return false;
        } else if (passwordCreate == null || passwordCreate == '') {
            toast.warning('Password is required !');
            return false;
        }
        else if (rePasswordCreate == null || rePasswordCreate == '') {
            toast.warning('Renew-password is required !');
            return false;
        }
        else if (passwordCreate !== rePasswordCreate) {
            toast.warning("Password and Renew-password don't math !");
            return false;
        }
        else if (passwordCreate !== rePasswordCreate) {
            toast.warning("Password and Renew-password don't math !");
            return false;
        }
        else if (addressSupportor == address) {
            toast.warning("You can not use your address for The Address Supportor!");
            return false;
        }
        return true;
    }


    const handleCreateAccount = async () => {

        setOnLoading(true);
        setDisableButtonCreate(true);
        var checkValidate = validateCreateAccount(emailCreate, passwordCreate, rePasswordCreate, addressSupportor);
        if (checkValidate) {
            const getSponsor = await GetSponsor(address);
            
            if (getSponsor.isSuccess) {
                toast.warning("You've got a supporter!");
                setOnLoading(false);
                setDisableButtonCreate(false);
            } else {
                try {
                    const accept = await CreateAccountWithRef(address, emailCreate, passwordCreate, addressSupportor);
                    if (accept.status.code === 200) {
                        toast.warning('You created account sucessfully!')
                        setOnLoading(false);
                        //setDisableButtonCreate(false);
                        setTimeout(() => {
                            window.location.href = "/connect";
                        }, 1000);
                    } else {
                        toast.warning(accept.messageError)
                        setOnLoading(false);
                        setDisableButtonCreate(false);
                    }
                } catch (error) {
                    toast.warning(error.status.code);
                    setOnLoading(false);
                    setDisableButtonCreate(false);
                }
            }
        } else{
            setOnLoading(false);
            setDisableButtonCreate(false);
        }

    }

    return (
        <>
            <div className='Affiliates'>
                <section className="Affiliates-formWrapper" style={{ backgroundImage: `url(${Banner})` }}>
                    <div className='Affiliates-form'>
                        <div className='title'>
                            <span>BECOME PARTNERS OF METARACERS</span>
                        </div>
                        <div className='form-register'>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" placeholder="Email" value={emailCreate} onChange={e => setEmailCreate(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" className="form-control" placeholder="Your password" value={passwordCreate} onChange={e => setPasswordCreate(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Re Password</label>
                                <input type="password" className="form-control" placeholder="Your password" value={rePasswordCreate} onChange={e => setRePasswordCreate(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <label>Address Supportor</label>
                                <input type="text" className="form-control" placeholder="Address Supportor" value={addressSupportor} readOnly={readOnlyAddressSupportor} onChange={e => setAddressSupportor(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <button className="btn btn-CreateAccount" onClick={() => handleCreateAccount()} disabled={disableButtonCreate}>
                                    Create Account
                                </button>
                            </div>
                        </div>
                        {/* <div className='Affiliates-link'>
                        <a href="#">Đăng nhập vào hệ thống đối tác</a>
                    </div> */}
                    </div>
                </section>
                <section className='Affiliates-boxWrap'>
                    <div className='container'>
                        <div className='row row-eq-height'>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <div className='Affiliates-card'>
                                    <div className='card-icon'>
                                        <FiFileText size={48} />
                                    </div>
                                    <span className='card-title'>10% Direct Commission</span>
                                    <span className='card-content'>Commission for transaction service fee up to 15%.</span>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <div className='Affiliates-card'>
                                    <div className='card-icon'>
                                        <FiUsers size={48} />
                                    </div>
                                    <span className='card-title'>1-1 Professional Support</span>
                                    <span className='card-content'>1-1 online support on Telegram to facilitate your customers system development.</span>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <div className='Affiliates-card'>
                                    <div className='card-icon'>
                                        <FiBarChart size={48} />
                                    </div>
                                    <span className='card-title'>Real Time Payment</span>
                                    <span className='card-content'>Commission is paid in real time; data is updated in real time.</span>
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <div className='Affiliates-card'>
                                    <div className='card-icon'>
                                        <FiShield size={48} />
                                    </div>
                                    <span className='card-title'>Transparent Data</span>
                                    <span className='card-content'>Multidimensional reports facilitating investigation of transaction data and commission details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='Affiliates-benefit'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='Affiliates-benefitTitle'>
                                    Commission rates two-times higher than the standard of the industry
                                </div>
                                <div className='Affiliates-container'>
                                    <div className='Affiliates-containerLeft'>
                                        <p className='Affiliates-containerLeft-top'>
                                            <span>Highest commission rate per transaction in the industry</span>
                                            <span>10% Profit from transaction</span>
                                        </p>
                                        <p className="Affiliates-circle Affiliates-circle--brand">10<span className="Affiliates__number-small">%</span></p>
                                        <p className="Affiliates-circle Affiliates-circle--blue">5<span className="Affiliates__number-small">%</span></p>
                                        <div className="Affiliates-left-bottom">
                                            <span>Commission for sub-branches</span>
                                            <span>5% profit from sub-branches</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='Affiliates-partner'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='Affiliates-partnerTitle'>
                                    Benefits of partners
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12'>
                                <div className='Affiliates-partnerBox'>
                                    <div className='Affiliates-partnerBox-icon'>
                                        <FiGift size={48} />
                                    </div>
                                    <div className='Affiliates-partnerBox-text'>
                                        <span className='Affiliates-partnerBox-text__Title'>Most competitive commission rate per transaction</span>
                                        <span>Benefit applied to most effective partners only</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12'>
                                <div className='Affiliates-partnerBox'>
                                    <div className='Affiliates-partnerBox-icon'>
                                        <FiSettings size={48} />
                                    </div>
                                    <div className='Affiliates-partnerBox-text'>
                                        <span className='Affiliates-partnerBox-text__Title'>Partners system</span>
                                        <span>Most comprehensive agency system: support for daily revenue claim</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12'>
                                <div className='Affiliates-partnerBox'>
                                    <div className='Affiliates-partnerBox-icon'>
                                        <FiTarget size={48} />
                                    </div>
                                    <div className='Affiliates-partnerBox-text'>
                                        <span className='Affiliates-partnerBox-text__Title'>Communication support</span>
                                        <span>Customized marketing documents in combination with marketing activities based on requests to support various marketing strategies of partners.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {
                onLoading && (
                    <LoadingPage />
                )
            }
        </>
    );

}

export default Affiliates;
