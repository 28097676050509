import React, { useEffect, useState, useCallback, useMemo } from "react";
import ReactPaginate from 'react-paginate';
import { FiX, FiChevronRight, FiChevronLeft, FiSquare, FiCheckSquare } from "react-icons/fi";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';

import 'react-tabs/style/react-tabs.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Market.css';

import Level from '../../../assets/images/car/level.png';
import RacerCoin from '../../../assets/images/coin/coin.svg';
import NotFound from '../../../assets/images/dashboard/notfound.svg';
import { racer_car } from '../../../assets/images/index';
import { homes } from '../../../assets/images/index';

import { Redirect, Link } from 'react-router-dom';

import Pagination from "../../../components/Pagination/Pagination";

import { useNavigate } from "react-router-dom";


import { data_typeCar } from './carInfo';


import {
    GetDataMarket, getPrice, SortDataMarket
} from '../../../api/api';

const loadImagePosition = (carId) => {
    const param = typeof carId == 'undefined' ? 1 : parseInt(carId.toLowerCase().split("_")[1]);
    return (racer_car[param - 1])
}

// const goToCarDetail = (tokenId, carId, itemId, history) => {
//     history.push('/car-detail?id=' + tokenId + '&name=' + carId + '&item=' + itemId);
// }

const ItemsCar = ({ currentItems, usdPriceState, history }) => {
    return (
        <>
            {currentItems &&
                currentItems.map((car, index) => (
                    <div className="racer-item racer-item-detail" key={index}>
                        <Link to={'/car-detail?id=' + car.tokenId + '&name=' + car.carId + '&item=' + car.itemId + '&price=' + car.price} >
                            <div className="racer-level">
                                <span>Level</span>
                                <h3>{car.level}</h3>
                            </div>
                            <div className="racer-car" style={{ backgroundImage: `url(${homes.OverlayItem})` }}>
                                <img src={loadImagePosition(car.carId)} alt="" />
                            </div>
                            <div className="racer-textContent">
                                <div className="racer-name">
                                    <span className="font-16 text-truncate-line-one">{car.name}</span>
                                    <span className="selling">Seller:
                                        {typeof car.seller == 'undefined' ? '' : `${car.seller.slice(0, 8)}...${car.seller.slice(car.seller.length - 8, car.seller.length)}`}
                                    </span>
                                </div>
                                <div className="racer-price">
                                    <div className="d-flex align-items-center justify-content-center">
                                        <img src={RacerCoin} alt="" />
                                        <span>{car.price} MRS</span>
                                    </div>
                                    <div className="market-place-button" >
                                        <NumberFormat type="text" value={(parseFloat(car.price) * usdPriceState).toFixed(2)} displayType={'text'} thousandSeparator={true} suffix={' USD'}
                                            renderText={(value, props) => <span className="usd">( {value} )</span>} />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>

                ))}
        </>
    );
}

const OpenChooseCar = ({ setSelected, selected }) => {
    return data_typeCar.map((type, index) => {
        return (
            <TypeCarItem data={type} selected={selected.includes(type)} key={index} onClick={() => {
                setSelected((selected) => {
                    const index_Car = selected.indexOf(type);
                    return index_Car === -1 ? [type] : [];
                })
            }} />
        )
    })
}

const TypeCarItem = ({ data, selected, onClick }) => {
    return (
        <>
            <div className="CarTypeItem" onClick={onClick}>
                <img src={data.image} alt="" />
                {/* <span>{data.class_car == 1 ? "Common" : (data.class_car == 2 ? 'Epic' : 'Legendary')}</span> */}
                <span>{data.name}</span>
                <div className="CarTypeItem_check">
                    {
                        selected ? <FiCheckSquare /> : <FiSquare />
                    }
                </div>
            </div>
        </>
    )
}

const ChooseCarItem = ({ selected }) => {
    return selected.map((selected, index) => {
        return (
            <div className="chooseCarImg" key={index}>
                <img src={selected.image} alt="" />
            </div>
        )
    });
}

const OpenPopUpChoose = ({ onClose, onApply, selectedItem }) => {
    const [selected, setSelected] = useState(selectedItem);
    return (
        <div className="popUp show popUpWrapper_ChooseCar">
            <div className="popUp_Content popUp_ChooseCar">
                <FiX className="close-popUp" size={24} onClick={onClose} />
                <div className="d-flex align-items-center">
                    <span>Click on image to select / unselect</span>
                    <span className="text-primary clear" onClick={() => { setSelected([]) }}>Clear filter</span>
                </div>
                <div className="listCarType">
                    <OpenChooseCar setSelected={setSelected} selected={selected} />
                </div>
                <button className="btn btn-apply" onClick={() => { onApply(selected) }}>Apply</button>
            </div>
        </div>
    );
}

const NotFoundCar = () => {
    return (
        <div className="data-notFound">
            <img src={NotFound} alt="" />
            <span className="text-primary">Sorry, Market Comming Soon.</span>
            {/* <span>Please try another search</span> */}
        </div>
    )
}

const LoadingPage = ({ content }) => {
    return (
        <div className="loading-wrapper loading-Openbox">
            <div className="loading-container">
                <p className="mb-0">Please wait</p>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                {
                    content ?
                        <span className="please-wait-text">{content}</span>
                        :
                        ''
                }
            </div>
        </div>
    )
}

const raritys = [
    {
        value: 1,
        label: 'Common'
    },
    {
        value: 2,
        label: 'Epic'
    },
    {
        value: 3,
        label: 'Legendary'
    }
]

const pageSize = 16;

const MarketPlace = ({ onConnect, resetApp, address, usdPrice, bnbPrice }) => {

    const history = useNavigate();

    const [selected, setSelected] = useState([]);
    const [openChooseCar, setOpenChooseCar] = useState(false);
    const [rarity, setRarity] = useState([]);
    const [priceRanger, setPrice] = useState([0, 100000]);
    const [currentItems, setCurrentItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterCar, setFilterCar] = useState('Latest');
    const [onLoading, setOnLoading] = useState(true);

    const [usdPriceState, setUsdPriceState] = useState(usdPrice);
    const [bnbPriceState, setBnbPriceState] = useState(bnbPrice);



    const handleChangePrice = (event, newValue) => {
        setPrice(newValue);
        // filterRangerPrice(newValue);

    };

    // change rarity

    const handleSearchRarity = (e) => {
        setRarity(e.target.value);
    }

    const handleSorts = async (event) => {
       setFilterCar(event.target.value);     
    };

    const handleClearFilter = () => {
        setRarity([]);
        setFilterCar('Latest');
        setPrice([0, 100000]);
        setSelected([]);
    }

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return currentItems.slice(firstPageIndex, lastPageIndex);
    }, [currentItems, currentPage]);


    // const types =  rarity.toString();

    const getAllDataMarket = useCallback(async () => {
        setOnLoading(true);
        // const getData = await GetDataMarket(
        //     rarity == null || rarity == [] ? null : rarity,
        //     selected == [] || typeof selected == 'undefined' ? null : selected[0].car_id,
        //     priceRanger[0],
        //     priceRanger[1],
        //     null);      
        const getData = await GetDataMarket(
            rarity === null || rarity.length === 0 ? null : rarity,
            selected.length === 0 ? null : selected[0].car_id,
            priceRanger[0],
            priceRanger[1],
            filterCar
        );
        if (getData.isSuccess) {
            setCurrentPage(1);
            setOnLoading(false);
            setCurrentItems(getData.data);
        } else {
            setOnLoading(false);
            setCurrentItems([])
        }

    });

    const getDataBySort = async (sort) => {
        setOnLoading(true);
        const getData = await SortDataMarket(sort);
        if (getData.isSuccess) {
            setCurrentPage(1);
            setOnLoading(false);
            setCurrentItems(getData.data);
        } else {
            setOnLoading(false);
            setCurrentItems([]);
        }
    }

    const getPriceOfMetaracers = async (address) => {
        if (address !== '') {
            getPrice().then((result) => {
                setUsdPriceState(result.usdPrice);
                setBnbPriceState(result.bnbPrice);
            })
        }
    }


    return (
        <>
            <section className="racermarket-wrapper">
                <Grid container spacing={2} className="grid-mobile">
                    <Grid item lg={3} md={3} xs={12} className="pr-4 pr-xs-0">
                        <div className="CarFilterBox">
                            <div className="CarFilterBoxTitle d-flex align-items-center justify-content-between">
                                <span>Filters</span>
                                <span className="text-primary clear" onClick={handleClearFilter}>Clear filter</span>
                            </div>
                            <div className="CarFilterBoxContent">
                                <h3 className="FilterTitle">CAR</h3>
                                {
                                    selected.length
                                        ? <div className="CarFilterBoxContent_Wrap">
                                            <ChooseCarItem selected={selected} />
                                        </div>
                                        : <span className="FilterNotifi">No hero selected</span>

                                }
                                <button className="btn btn-chooseCar" onClick={() => { setOpenChooseCar(true) }}>
                                    Choose Car
                                </button>
                            </div>
                        </div>
                        <div className="CarFilterBox">
                            <div className="CarFilterBoxTitle d-flex align-items-center justify-content-between"></div>
                            <div className="CarFilterBoxContent">
                                <h3 className="FilterTitle">RARITY</h3>
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="rarity" name="rarity-group" className="rarity-wrap">
                                        {
                                            raritys.map((item, index) =>
                                                <FormControlLabel key={index} value={item.value} control={<Radio className="checkBoxFilter" onChange={handleSearchRarity} />} label={item.label} />
                                            )
                                        }
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="CarFilterBox">
                            <div className="CarFilterBoxTitle d-flex align-items-center justify-content-between"></div>
                            <div className="CarFilterBoxContent">
                                <h3 className="FilterTitle">PRICE RANGE</h3>
                                <Box sx={{ width: "90%" }} className="mt-5 slider-ranger">
                                    <Slider
                                        className="RangerPrice"
                                        value={priceRanger}
                                        step={2000}
                                        min={0}
                                        max={100000}
                                        onChange={handleChangePrice}
                                        valueLabelDisplay="on"
                                        marks
                                    />
                                </Box>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={9} md={9} xs={12}>
                        <div className="ShortByCar">
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Sorts</InputLabel>
                                <Select
                                    className="filter-content"
                                    labelId="select-label"
                                    id="selected-item"
                                    label="Latest"
                                    value={filterCar}
                                    onChange={handleSorts}
                                >
                                    <MenuItem value="Latest">Latest</MenuItem>
                                    <MenuItem value="CheapestItem">Cheapest Item</MenuItem>
                                    <MenuItem value="MostExpensive">Most Expensive</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <NotFoundCar />
                    </Grid>
                </Grid>
            </section>
            {
                openChooseCar && (
                    <OpenPopUpChoose onClose={() => {
                        setOpenChooseCar(false);
                        setSelected([]);
                    }} onApply={(selected) => {
                        setOpenChooseCar(false);
                        setSelected(selected);
                    }}
                        selectedItem={selected}
                    />
                )
            }

            {/* {
                onLoading && (
                    <LoadingPage />
                )
            } */}

        </>

    );
}

export default MarketPlace;
