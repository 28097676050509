import React, { useEffect, useState } from "react";
import { useTonAddress } from '@tonconnect/ui-react';

import { useNavigate, Link } from "react-router-dom";
import { useToast } from 'rc-toastr';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

import { useUser } from '../../../context/UserContext';
import Pagination from "../../../components/Pagination/Pagination";

import NotFound from '../../../assets/images/dashboard/notfound.svg';
import EpicBox from '../../../assets/images/box/box-save2.png';
import { homes } from '../../../assets/images/index';

import {
    checkExistAccountWithAddress, ChangeName, GetBalanceEMRS, GetNFTFromAddress, GetNumberBox,
    getBalanceOfToken, GetDeviceId, ResetDeviceId, DepositToken, WithdrawToken, getBalanceOfBNB, getPrice,
    GetMRSBalanceFromAddress, GetBNBBalanceFromAddress, GetNFTSelling, GetNFTPlaying,
    InviteUser,
    AcceptInvite,
    GetListReferral,
    GetListHistoryOfWhoInvite,
    LoadReferralToDatabase, GetSponsor,
    GetNumberUnbox
} from '../../../api/api';


const LoadImagePosition = (carId) => {
    // if (carId == 0) Car_010
    const param = "car_" + parseInt(carId.toLowerCase().split("_")[1]).toString() + "-min.png";
    return (<img src={require('../../../assets/images/car/' + param)} alt="" />)
}

const LoadingPage = ({ content }) => {
    return (
        <div className="loading-wrapper loading-Openbox">
            <div className="loading-container">
                <p className="mb-0">Please wait</p>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                {
                    content ?
                        <span className="please-wait-text">{content}</span>
                        :
                        ''
                }
            </div>
        </div>
    )
}

const PageSize = 6;

const InventorySettingPage = () => {

    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
    const { toast } = useToast();
    const { user, setUser } = useUser();
    const history = useNavigate();

    const userFriendlyAddress = useTonAddress();

    const [currentPage, setCurrentPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [checkShowData, setShowData] = useState(true);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [filterCar, setFilterCar] = useState('Processing');
    const [numberBoxOfUser, setNumberBoxOfUser] = useState(0);
    
    const [nftFromAddress, setNftFromAddress] = useState([]);

    const handleChange = async (event) => {

        if (event.target.value === "Processing") {
            getNFTofAddress();
            setFilterCar('Processing');
        }
    };

    const getNFTofAddress = async () => {

        var data = localStorage.getItem('myAccount');
        if (data !== null) {
            data = JSON.parse(data);
            const { addressWallet } = data;
            if (addressWallet !== '') {
                const getAllNFTFromAddress = await GetNFTFromAddress(addressWallet, token);
                if (getAllNFTFromAddress.status.code === 200) {
                    setNftFromAddress(getAllNFTFromAddress.data);
                }
            }
        }
    }

    const goToCarDetail = (tokenId, carId, itemId) => {
        history.push('/my-car?id=' + tokenId + '&name=' + carId + '&item=' + itemId + '&filter=' + filterCar.toLowerCase());
    }

    return (
        <>
            <Tabs forceRenderTabPanel>
                <TabList className="tabs-inventory">
                    <Tab>
                        <span>CARS</span>
                    </Tab>
                    <Tab>
                        <span>METARACERS BOX</span>
                    </Tab>
                </TabList>
                <TabPanel>
                    <div className="filter_car">
                        <div className="total_car">Cars: {nftFromAddress.length}</div>
                        <div className="filter">
                            <FormControl fullWidth>
                                <InputLabel id="select-label">Filters</InputLabel>
                                <Select
                                    className="filter-content"
                                    labelId="select-label"
                                    id="selected-item"
                                    label="Processing"
                                    value={filterCar}
                                    onChange={handleChange}
                                >
                                    {/* <MenuItem value="All">All items</MenuItem> */}
                                    <MenuItem value="Processing">Processing</MenuItem>
                                    {/* <MenuItem value="Selling">Selling</MenuItem> */}
                                    <MenuItem value="Selling">Selling</MenuItem>
                                    <MenuItem value="Playing">Playing</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    {
                        currentItems.length > 0 ?
                            <>
                                <div className="list-racers">
                                    {/* <ItemsCar currentItems={currentItems} /> */}
                                    <>
                                    {currentItems &&
                                            currentItems.map((car, index) => (
                                                <a className="racer-item" key={index} onClick={() => goToCarDetail(car.tokenId, car.carId, car.itemId == null || typeof car.itemId == 'undefined' ? 0 : car.itemId)}>
                                                    <div className="racer-level">
                                                        <span>Level</span>
                                                        <h3>{car.level}</h3>
                                                    </div>
                                                    <div className="racer-car" style={{ backgroundImage: `url(${homes.OverlayItem})` }}>
                                                        {/* <img src={car.image} alt="" /> */}{
                                                            LoadImagePosition(car.carId)
                                                        }
                                                    </div>
                                                    <div className="racer-textContent">

                                                        <div className="racer-price">
                                                            <span className="font-12">ID: {car.tokenId}</span>
                                                            <Tooltip title={car.name}>
                                                                <span className="font-16 text-truncate-line-one">{car.name}</span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </a>
                                            ))}
                                    </>
                                </div>
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={currentPage}
                                    totalCount={nftFromAddress.length}
                                    pageSize={PageSize}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </>
                            :
                            <div className="data-notFound">
                                <img src={NotFound} alt="" />
                                <span className="text-primary">Sorry, no result found.</span>
                                <span>Please try another search</span>
                            </div>

                    }
                </TabPanel>
                <TabPanel>
                    <div className="myRacerbox">
                        <div className="racerBox">
                            <div className="racerBox-img">
                                <img src={EpicBox} alt="" />
                            </div>
                            <div className="racerBox-text text-center">
                                <h3 className="text-primary">RACER BOX</h3>
                                <span>You have: <strong className="text-primary">{numberBoxOfUser} boxes</strong></span>
                            </div>
                            <Link to="/open-box-detail" className="btn-openBox" >
                                <span>Open Box</span>
                            </Link>
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </>
    );

}

export default InventorySettingPage;
