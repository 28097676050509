import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { useToast } from 'rc-toastr';
import NumberFormat from 'react-number-format';
import { useTonAddress } from '@tonconnect/ui-react';

import axios from 'axios';
import { useTonConnectUI } from '@tonconnect/ui-react';
import Pagination from 'react-bootstrap/Pagination';

import TonWeb from "tonweb";
import { beginCell } from '@ton/ton';

import Button from '@mui/material/Button';

import {
    FiX,
    FiCopy
} from "react-icons/fi";

import { BsQrCodeScan } from "react-icons/bs";
import { MdHistory } from "react-icons/md";

import RacerCoin from '../../../assets/images/coin/coin.svg';
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';
import HouseGlass from '../../../assets/images/dashboard/hourglass.png';
import NotFound from '../../../assets/images/dashboard/notfound.svg';

import HistoryTonTable from "./historyTON";

import {
    CheckDepositTon,
    GetUserInfor,
    HistoryDepositTon
} from '../../../api/api';

import { useUser } from '../../../context/UserContext';

const NotFoundData = () => {
    return (
        <div className="data-notFound">
            <img src={NotFound} alt="" />
            <span className="text-primary">Sorry, no result found.</span>
        </div>
    )
}

const WalletSettingPage = ({ bnbPrice, usdPrice, balanceBNB, balanceMRS, bnbUsdt }) => {

    const { toast } = useToast();
    const { user, setUser } = useUser();
    const history = useNavigate();
    // TON Config
    const TonApiKey = process.env.REACT_APP_TON_API_KEY;
    const addressWallet = localStorage.getItem('addressWallet');
    const userFriendlyAddress = useTonAddress();
    const addressBalance = user ? user.addressWallet : userFriendlyAddress;

    const accountData = localStorage.getItem('myAccount') && JSON.parse(localStorage.getItem('myAccount'));
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
    const hash = localStorage.getItem("hash") && localStorage.getItem("hash").replace(/"/g, '');
    const [tonConnectUI, setOptions] = useTonConnectUI();

    const [balanceBNBState, setBalanceBNBState] = useState(balanceBNB);
    const [balanceMRState, setBalanceMRState] = useState(balanceMRS);
    const [usdPriceState, setUsdPriceState] = useState(usdPrice);
    const [bnbPriceState, setBnbPriceState] = useState(bnbPrice);
    const [showConfirmClaim, setCloseConfirmClaim] = useState(false);

    const minWithdraw = 200,
        maxWithdraw = 500,
        rateFeeWithdraw = 0.03,
        feeChargable = 0.1;

    const [openClaimHouseGlass, setOpenClaimHouseGlass] = useState(false);
    const [openDeposit, setopenDeposit] = useState(false);
    const [openDepositTonQR, setopenDepositTonQR] = useState(false);
    const [openDepositTonModal, setopenDepositTonModal] = useState(false);
    const [openClaim, setopenClaim] = useState(false);
    const [tonAccountBalance, setTonAccountBalance] = useState(0);


    const [historyDepositMRS, setHistoryDepositMRS] = useState(false);
    const [historyDepositTon, setHistoryDepositTon] = useState(false);
    const [textDepositButton, setTextDepositButton] = useState("DEPOSIT");
    const [disableButtonDeposit, setDisableButtonDeposit] = useState(false);
    const [amountDeposit, setAmountDeposit] = useState(0);
    const [amountDepositTon, setAmountDepositTon] = useState(0);
    const [balanceTONInGame, setBalanceTONInGame] = useState(0);
    const [dataHistoryDepositTon, setDataHistoryDepositTon] = useState([]);
    const [checkHistoryDepositTon, setCheckHistoryDepositTon] = useState(false);
    const [balanceEMRS, setBalanceEMRS] = useState(0);

    const minDeposit = 30, maxDeposit = 2000;

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    const handleConfirmClaim = () => {
        setCloseConfirmClaim(true);
    }

    const handleClaimHourGlass = () => {
        setOpenClaimHouseGlass(false);
        setopenClaim(false);
    }

    const handleClaimWithdraw = async () => {
        toast.warning('Comming Soon...');
    }

    const handleDeposit = async () => {
        toast.warning('Comming Soon...');
    }

    const handleDepositTon = async () => {
        try {
            if (tonAccountBalance < amountDepositTon) {
                toast.warning('Your balance is insufficient');
            } else if (amountDepositTon < feeChargable || amountDeposit > 100) {
                toast.warning('The minimum Deposit Amount is 0.1 TON and the maximum is 100 TON.');
            } else {
                // const address = new tonweb.utils.Address(process.env.REACT_APP_DEPOSIT);
                // const hexAddress = address.toString(false, true, true);
                const amountDepositTonInput = (amountDepositTon * 10 ** 9);

                const currentDate = new Date();
                const currentDateTimestamp = (currentDate.setSeconds(currentDate.getSeconds())) / 1000;

                const comment = "DEP " + amountDepositTon + " TON :" + String(parseInt(currentDateTimestamp));

                //Lấy cho a cái số seqno trước khi sendTransaction
                //`https://tonapi.io/v2/wallet/${user.addressWallet}/seqno`

                const config_addressBalance = {
                    method: 'get',
                    maxBodyLength: Infinity,
                    url: `https://tonapi.io/v2/wallet/${addressBalance}/seqno`,
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${TonApiKey}`
                    }
                };
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                SendTonTransaction(data.seqno, comment, amountDepositTonInput, addressWallet);
            }
        } catch (error) {
        }
    }

    const handleOpenModalHistoryTON = async () => {
        setHistoryDepositTon(true);
        getHistoryTON();
    }

    const getHistoryTON = async () => {
        const historyData = await HistoryDepositTon(token);
        setDataHistoryDepositTon(historyData.status.code === 200 ? historyData.data : []);
    }

    const SendTonTransaction = async (seqno, comment, amountDepositTonInput) => {

        const body = beginCell()
            .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            .storeStringTail(comment) // write our text comment
            .endCell();

        try {
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: process.env.REACT_APP_DEPOSIT,
                        amount: String(amountDepositTonInput),
                        payload: body.toBoc().toString("base64"),
                        // address: hexAddress,
                        // // amount: tonweb.utils.toNano(amountDepositTon)
                        // amount: amountDeposit
                    }
                ]
            }
            const send = await tonConnectUI.sendTransaction(transaction);
            if (send && String(send.boc).length > 0) {
                const checkTon = await CheckDepositTon(accountData.addressWallet, accountData.email, amountDepositTonInput, comment, seqno, token, hash);

                //Chỗ này gọi tới /checkDepositTon Truyền các biến sau vào url /checkDepositTon 
                //mail ,  addressWallet , amountDeposit , comment , seqno 

                //Thưc hiện mở trang History
                getHistoryTON();
                setTimeout(() => {
                    setHistoryDepositTon(true);
                    setopenDepositTonModal(false);
                }, 3000)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/blockchain/accounts/${addressBalance}`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${TonApiKey}`
                }
            };

            try {
                const response = await axios.request(config_addressBalance);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const balance = (data.balance / 10 ** 9).toFixed(5);
                    setTonAccountBalance(balance);
                    const updatedUser = {
                        ...user,
                        TonBalance: balance
                    };

                    setUser(updatedUser);

                } else {
                
                }
            } catch (error) { }
        };

        const fetchDataUserInfo = async () => {
            try {
                const data = await GetUserInfor(token);
                if (data.status.code === 200) {
                    const Ton_Balance = typeof data.data.user.Ton_Balance == 'undefined' ? 0 : parseFloat(data.data.user.Ton_Balance);
                    setBalanceTONInGame(Ton_Balance);
                    setBalanceEMRS(data.data.user.eMRSBalanceInGame);
                    const updatedUser = {
                        ...user,
                        Ton_Balance: Ton_Balance,
                        eMRSBalanceInGame: data.data.user.eMRSBalanceInGame,
                        defaultCar: data.data.user.defaultCar,
                        defaultCarToken: data.data.user.defaultCarToken,
                        numberOfBox: data.data.user.numberOfBox,

                    };
                    setUser(updatedUser);
                }
            } catch (error) { }

            try {
                getHistoryTON();
            } catch (error) { }


        }

        // Call API initially
        fetchData();
        fetchDataUserInfo();

        // Call API every 10 seconds
        const interval = setInterval(() => {
            fetchData();
        }, 30000);

        // Clear interval on component unmount
        return () => clearInterval(interval);
    }, []);


    return (
        <>
            <h3 className="title_main">WALLET</h3>
            <div className="wallet-wrapper">
                <div className="wallet-box wallet-boxIcon">
                    <div className="d-flex">
                        <div className="wallet-boxIconLeft">
                            <span>Total Balance</span>
                            <div className="money">
                                <h2 className="text-primary">{tonAccountBalance} TON</h2>
                                <span>0 USD</span>
                            </div>
                            <div className="money">
                                <h2 className="text-primary">{balanceTONInGame} TON (In-Game)</h2>
                                <span>0 USD</span>
                            </div>
                        </div>
                        <div className="wallet-boxIconRight">
                            <img src={TonCoin} alt="" />
                        </div>
                        <div className="wallet-qr" onClick={() => (setopenDepositTonQR(true))}>
                            <BsQrCodeScan />
                        </div>
                        <div className="wallet-qr history-qr" onClick={handleOpenModalHistoryTON}>
                            <MdHistory />
                        </div>
                    </div>
                    <Button className="btn-main btn-desposit" onClick={() => (setopenDepositTonModal(true))}>
                        Deposit TON
                    </Button>
                </div>
                <div className="wallet-box wallet-boxIcon">
                    <div className="d-flex">
                        <div className="wallet-boxIconLeft">
                            <span>MetaRacers Coin (MRS)</span>
                            <div className="money">
                                <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true} suffix={' MRS'}
                                    renderText={(value, props) => <h2 className="text-primary">{value}</h2>} />
                                <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true} suffix={' USD'}
                                    renderText={(value, props) => <span>{value}</span>} />

                            </div>
                            <div className="money">
                                <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true} suffix={' MRS'}
                                    renderText={(value, props) => <h2 className="text-primary">{value}</h2>} />
                                <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true} suffix={' USD'}
                                    renderText={(value, props) => <span>{value}</span>} />

                            </div>
                        </div>
                        <div className="wallet-qr" onClick={() => (setopenDepositTonQR(true))}>
                            <BsQrCodeScan />
                        </div>
                        <div className="wallet-qr history-qr" onClick={() => (setHistoryDepositMRS(true))}>
                            <MdHistory />
                        </div>
                        <div className="wallet-boxIconRight">
                            <img src={RacerCoin} alt="" />
                        </div>
                    </div>
                    <Button className="btn-main btn-desposit" onClick={() => (setopenDeposit(true))}>
                        Deposit MRS
                    </Button>
                </div>
            </div>
            <div className="mt-5">
                <h3 className="title_main">IN GAME CURRENCY</h3>
                <div className="wallet-wrapper">
                    <div className="wallet-box wallet-boxIcon">
                        <div className="d-flex">
                            <div className="wallet-boxIconLeft">
                                <span>MetaRacers Coin (eMRS)</span>
                                <div className="money">
                                    <NumberFormat type="text" value={balanceEMRS} displayType={'text'} thousandSeparator={true} suffix={' EMRS'}
                                        renderText={(value, props) => <h2 className="text-primary">{value}</h2>} />
                                    <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true} suffix={' USD'}
                                        renderText={(value, props) => <span>{value}</span>} />
                                </div>
                                <span className="text-primary font-12 mt-2">Min convertable: 200 eMRS</span>
                            </div>
                            <div className="wallet-boxIconRight">
                                <img src={RacerCoin} alt="" />
                            </div>
                        </div>
                        <Button className="btn btn-main btn-claim-wrap" onClick={() => { setopenClaim(true) }}
                        >
                            Claim
                        </Button>
                    </div>
                    <div className="wallet-box wallet-boxIcon">
                        <div className="d-flex">
                            <div className="wallet-boxIconLeft">
                                <span>Price Of MRS</span>
                                <div className="money">
                                    <h2 className="text-primary">MRS/TON PRICE : 0</h2>
                                    <span>USDT PRICE : $0</span>
                                </div>
                            </div>
                            <div className="wallet-boxIconRight">
                                <img src={RacerCoin} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                openDepositTonQR && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim">
                                <FiX className="close-popUp" size={24} onClick={() => setopenDepositTonQR(false)} />
                                <div className="p-2">
                                    <span className="d-block mb-3 text-center">Deposit Address: <span className="text-primary hexAddress">{process.env.REACT_APP_DEPOSIT}<FiCopy size={20} title="Sao chép nội dung" className="ml-2 copy text-warning" onClick={() => copyToClipBoard(process.env.REACT_APP_DEPOSIT)} /></span></span>
                                    <div className='text-center d-flex align-items-center justify-content-center flex-column qr-code-ton'>
                                        <QRCode
                                            value={process.env.REACT_APP_DEPOSIT}
                                            size={200}
                                            logoImage={TonCoin}
                                            qrStyle='dots'
                                            logoWidth={50}
                                            bgColor='#FFFFFF'
                                            logoOpacity={1}
                                            fgColor='#000000'
                                            quietZone={10}
                                            ecLevel='L'
                                            eyeRadius={100}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {
                historyDepositTon && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim popU_history">
                                <FiX className="close-popUp" size={24} onClick={() => setHistoryDepositTon(false)} />
                                <HistoryTonTable data={dataHistoryDepositTon} />
                            </div>
                        </div>
                    </>
                )
            }

            {
                historyDepositMRS && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim popU_history">
                                <FiX className="close-popUp" size={24} onClick={() => setHistoryDepositMRS(false)} />
                                <div className="table-responsive pt-4">
                                    <table className="table">
                                        <thead>
                                            <tr className="text-white">
                                                <th scope="col">#</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Created Date</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <NotFoundData />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            {
                openDeposit && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim">
                                <FiX className="close-popUp" size={24} onClick={() => setopenDeposit(false)} />
                                <div>
                                    <h3>DEPOSIT TOKEN</h3>
                                    <span className="font-12">You are about to convert MRS to eMRS token</span>
                                    {/* <span className="font-12">Available: {availableTokenDeposit}</span> */}
                                    <NumberFormat type="text" value={parseFloat(balanceMRState).toFixed(2)} displayType={'text'} thousandSeparator={true}
                                        renderText={(value, props) => <span className="font-12">Available: {value}</span>} />
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between iconToken-box">
                                            <div className="iconToken-wrap deposit-wrap">
                                                <input placeholder="Amount" className="amout" type="number" onChange={(e) => setAmountDeposit(e.target.value)} value={amountDeposit}
                                                    disabled={(balanceMRState >= minDeposit && balanceMRState <= maxDeposit) || balanceMRState > maxDeposit ? false : true}
                                                />
                                                <div className="d-flex align-items-center justify-content-between icon-token">
                                                    <img src={RacerCoin} alt="" />
                                                    <span>MRS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="d-block mt-2 text-left text-primary font-12">*Min convertable: 30 | Max convertable: 2000</span>
                                    {/* <Alert severity="error">Reach rank Bronze 1 to start deposit your token</Alert> */}
                                    <button disabled={disableButtonDeposit} className="btn btn-claim" onClick={handleDeposit}>{textDepositButton}</button>
                                </div>

                            </div>
                        </div>
                    </>
                )
            }

            {
                openDepositTonModal && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim">
                                <FiX className="close-popUp" size={24} onClick={() => setopenDepositTonModal(false)} />
                                <div>
                                    <h3>DEPOSIT TOKEN</h3>
                                    <span className="font-12">You are about to convert MRS to eMRS token</span>
                                    {/* <span className="font-12">Available: {availableTokenDeposit}</span> */}
                                    <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true}
                                        renderText={(value, props) => <span className="font-12">Available: {value}</span>} />
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between iconToken-box">
                                            <div className="iconToken-wrap deposit-wrap">
                                                <input placeholder="Amount" className="amout" type="number" onChange={(e) => setAmountDepositTon(e.target.value)} value={amountDepositTon}
                                                />
                                                <div className="d-flex align-items-center justify-content-between icon-token">
                                                    <img src={TonCoin} alt="" />
                                                    <span>TON</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-claim" onClick={handleDepositTon}>DEPOSIT</button>
                                </div>

                            </div>
                        </div>
                    </>
                )
            }

            {
                openClaim && (
                    <>
                        <div className="popUp popUpWrapper_claim">
                            <div className="popUp_Content popUp_claim">
                                <FiX className="close-popUp" size={24} onClick={() => setopenClaim(false)} />
                                {
                                    !openClaimHouseGlass ?
                                        <div>
                                            <h3>CLAIM TOKEN</h3>
                                            <span className="font-12">You are about to convert eMRS to MRS token</span>
                                            <NumberFormat type="text" value={0} displayType={'text'} thousandSeparator={true}
                                                renderText={(value, props) => <span className="font-12">Available: {value}</span>} />
                                            <div className="d-flex flex-column align-items-center justify-content-between ">

                                                <span className="d-block mt-2 text-left text-primary font-12">*Convertable: {minWithdraw}</span>
                                                <div className="token-info-emrs">
                                                    <span>3% fee of {minWithdraw} <b className="text-primary">eMRS</b> will be translated and charged in <b className="text-primary">eMRS</b></span>
                                                    <span>Fee chargable: 0.3 eMRS, min fee: 0.3 eMRS</span>
                                                    <span>Time for claim is from <b className="text-primary">2:00 UTC</b> to <b className="text-primary">11:00 UTC</b></span>
                                                    <span>Your MRS will be transferred in <b className="text-primary">12-24 hours</b></span>
                                                    <span>Each withdrawal: <b className="text-primary">200 eMRS/time</b></span>
                                                    <span>Maximum withdrawal per day is: <b className="text-primary">400 eMRS</b></span>
                                                    <span>Rate: <b className="text-primary">1 eMRS = 1 MRS</b></span>


                                                </div>
                                                <button className="btn btn-claim" onClick={handleConfirmClaim}>Claim</button>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            <div className="showHourGlass">
                                                <img src={HouseGlass} alt="" />
                                                <h3>REQUEST SUMMITED</h3>
                                                <span>Your request to claim token is being reviewed, the amount will be automatically added into your wallet once approved. Please check back affter 12-24 hours. </span>
                                                <button className="btn btn-claim" onClick={handleClaimHourGlass}>OK</button>
                                            </div>
                                        </>
                                }

                            </div>
                        </div>
                    </>
                )
            }

            {
                showConfirmClaim && (
                    <div className="popUp show">
                        <div className="popUp_Content popUp_Checkout">
                            <FiX className="close-popUp" size={24} onClick={() => { setCloseConfirmClaim(!showConfirmClaim) }} />
                            <span>Do you want to claim token or not?</span>
                            <div className="d-flex align-items-center justify-content-between btn-wrapCheckOut">
                                <button className="btn btn-cancel" onClick={() => { setCloseConfirmClaim(!showConfirmClaim) }}>NO</button>
                                <button className="btn btn-confirmCheckOut" onClick={handleClaimWithdraw}>YES</button>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    );

}

export default WalletSettingPage;
