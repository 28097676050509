import React, { useState, useEffect, useRef } from "react";

import {
    Link,
    useNavigate
} from "react-router-dom";

import NumberFormat from 'react-number-format';

import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { IoIosLink } from "react-icons/io";

import { useUser } from '../../context/UserContext';
import { useTon } from '../../context/TONContext';


//css
import './Header.css';
import LogoMeta from '../../assets/images/logo_Metacar.png';
import RacerCoin from '../../assets/images/coin/crypto-coin.png';
import TonCoin from '../../assets/images/coin/ton_symbol.svg';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


const HeaderDashBoard = ({ children, onConnect, resetApp, address, balanceMRS, balanceBNB }) => {

    const userFriendlyAddress = useTonAddress();
    const name_account = userFriendlyAddress ? `${userFriendlyAddress.slice(0, 12)}...${userFriendlyAddress.slice(userFriendlyAddress.length - 8, userFriendlyAddress.length)}` : "";

    const [tonConnectUI] = useTonConnectUI();

    const { user, setUser } = useUser();
    const { tonBalance, setTonBalance } = useTon();

    const navigate = useNavigate();


    const [menuOpen, setMenuOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const toggleClassName = menuOpen ? 'show' : '';
    const toggleAccountClassName = accountOpen ? 'show' : '';

    const myRef = useRef();

    const handleClickOutside = e => {
        if (!myRef?.current?.contains(e.target) ) {
            setAccountOpen(false);
        }
    };

    const handleClickInside = () => {
        setAccountOpen(!accountOpen);
    };

    const handleLogOut = async () => {
        localStorage.clear();
        await tonConnectUI.disconnect();
        navigate('/connect');
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    // useEffect(() => {
    //     function handleResize() {
    //         setWindowDimensions(getWindowDimensions());
    //     }
    //     window.addEventListener('resize', handleResize);
    //     if (windowDimensions.width >= 767) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //         return () => {
    //             window.removeEventListener('resize', handleResize);
    //             document.removeEventListener('mousedown', handleClickOutside);
    //         }
    //     } else {
    //         return () => {
    //             window.removeEventListener('resize', handleResize);
    //             document.removeEventListener('mousedown', handleClickOutside);
    //         }
    //     }

    // }, [windowDimensions]);


    return (
        <div className="header-wrapper">
            <header className="header header-content">
                <div className="logo-header">
                    <a href='/'>
                        <div className="box-img-flex">
                            <img src={LogoMeta} alt="" />
                        </div>
                    </a>
                </div>
                <div className="menu-main" id="menu-main">
                    <ul>
                        {/* <li className="active">
                            <a href="https://about.meta-racers.com/">
                                <span>Contact us</span>
                            </a>
                        </li> */}
                        <li>
                            <Link to="/racer-box">
                                <span>Metaracer Boxes</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/white-list">
                                <span>Whitelist</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/market" className="market-place-button">
                                <span>Market place</span>
                            </Link>
                        </li>
                        {
                            userFriendlyAddress === '' ?
                                <li data-menuanchor="" className="button-wallet">
                                    <div className="box-btn-main">
                                        <Link to="/connect" className="btn-main">
                                            <span> <IoIosLink /> Connect</span>
                                        </Link>
                                    </div>
                                </li>
                                :
                                null
                        }

                        {
                            userFriendlyAddress !== '' ?
                                <li className="">
                                    <div ref={myRef} className="account" onClick={() => {
                                        setAccountOpen(!accountOpen);
                                        handleClickInside()
                                    }}>
                                        <p className="total">
                                            <span>0</span> MRS
                                        </p>
                                        <img src={RacerCoin} alt="" />
                                        <div className={`account-box ${accountOpen ? 'show' : ''}`}>
                                            <div className="account-box__inner">
                                                <h3> {name_account}
                                                </h3>
                                                <p> Your Balance</p>
                                                <div className="account-row dflex align-center">
                                                    <img src={TonCoin} alt="" />
                                                    <div className="account__name">
                                                        <h5>Balance</h5>
                                                        <p>{tonBalance ? tonBalance : 0}</p>
                                                    </div>
                                                </div>
                                                <div className="account-row dflex align-center">
                                                    <img src={RacerCoin} alt="" />
                                                    <div className="account__name">
                                                        <h5>Balance</h5>
                                                        <p>0</p>
                                                    </div>
                                                </div>
                                                <div className="links">
                                                    <Link to="/my-account" className="dflex align-center">
                                                        <svg fill="currentColor" viewBox="0 0 13 15" height="15" width="17px" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                                                            <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M12.3593 11.7194C12.3593 14.094 9.10285 14.3685 6.65401 14.3685L6.47877 14.3684C4.91858 14.3646 0.947266 14.2661 0.947266 11.705C0.947266 9.37882 4.07287 9.06794 6.50307 9.05622L6.82925 9.05602C8.38934 9.0598 12.3593 9.15831 12.3593 11.7194ZM6.65401 10.1366C3.58414 10.1366 2.02795 10.6639 2.02795 11.705C2.02795 12.7554 3.58414 13.2878 6.65401 13.2878C9.72316 13.2878 11.2786 12.7605 11.2786 11.7194C11.2786 10.669 9.72316 10.1366 6.65401 10.1366ZM6.65401 0.0527344C8.76351 0.0527344 10.4789 1.76887 10.4789 3.87837C10.4789 5.98787 8.76351 7.70328 6.65401 7.70328H6.63096C4.52578 7.69679 2.82046 5.97994 2.82764 3.8762C2.82764 1.76887 4.54379 0.0527344 6.65401 0.0527344ZM6.65401 1.08155C5.11151 1.08155 3.85646 2.33587 3.85646 3.87837C3.85143 5.41582 5.0971 6.66942 6.63312 6.67518L6.65401 7.18959V6.67518C8.19579 6.67518 9.45011 5.42015 9.45011 3.87837C9.45011 2.33587 8.19579 1.08155 6.65401 1.08155Z" fill="#6D6D6D"></path>
                                                        </svg>
                                                        <p>My profile</p>
                                                    </Link>
                                                    <a className="dflex align-center" onClick={handleLogOut}>
                                                        <svg fill="currentColor" viewBox="0 0 17 16" height="16" width="17px" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                                                            <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M11.8799 0.841797C14.3491 0.841797 16.3577 2.85032 16.3577 5.32029V10.6791C16.3577 13.1483 14.3491 15.1576 11.8799 15.1576H5.42576C2.95654 15.1576 0.947266 13.1483 0.947266 10.6791V5.32029C0.947266 2.85032 2.95654 0.841797 5.42576 0.841797H11.8799ZM11.8799 1.96179H5.42576C3.57403 1.96179 2.06726 3.46856 2.06726 5.32029V10.6791C2.06726 12.5308 3.57403 14.0376 5.42576 14.0376H11.8799C13.7316 14.0376 15.2377 12.5308 15.2377 10.6791V10.47L12.7752 10.4705C11.3588 10.4705 10.206 9.31837 10.2052 7.9027C10.2052 6.48553 11.3581 5.33268 12.7752 5.33194L15.2377 5.33149V5.32029C15.2377 3.46856 13.7316 1.96179 11.8799 1.96179ZM15.2377 6.45148L12.7752 6.45193C11.9756 6.45268 11.3252 7.10302 11.3252 7.90195C11.3252 8.70014 11.9763 9.35048 12.7752 9.35048L15.2377 9.35003V6.45148ZM13.1169 7.29566C13.426 7.29566 13.6769 7.54654 13.6769 7.85566C13.6769 8.16478 13.426 8.41566 13.1169 8.41566H12.884C12.5748 8.41566 12.324 8.16478 12.324 7.85566C12.324 7.54654 12.5748 7.29566 12.884 7.29566H13.1169ZM8.92537 4.23023C9.23449 4.23023 9.48537 4.48111 9.48537 4.79023C9.48537 5.09935 9.23449 5.35023 8.92537 5.35023H4.89413C4.58501 5.35023 4.33413 5.09935 4.33413 4.79023C4.33413 4.48111 4.58501 4.23023 4.89413 4.23023H8.92537Z" fill="#969696"></path>
                                                        </svg>
                                                        <p>Log out</p>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </li>
                                :
                                null
                        }
                    </ul>
                </div>
                <div className="btn-menu-mobile" onClick={() => setMenuOpen(!menuOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </header>
            <div className={`y-mobile-menu ${toggleClassName}`}>
                <div className="m-menu__title">
                    <div href="#" className="m-menu-close" onClick={() => setMenuOpen(false)}>+</div>
                </div>
                <ul>
                    <li className="active">
                        <Link to="/">
                            <span>Contact us</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/racer-box">
                            <span>Metaracer Boxes</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/market" className="market-place-button">
                            <span>Market place</span>
                        </Link>
                    </li>
                    {
                        userFriendlyAddress === '' ?
                            <li data-menuanchor="" className="button-wallet">
                                <div className="box-btn-main">
                                    <Link to="/connect" className="btn-main">
                                        <span> <IoIosLink /> Connect</span>
                                    </Link>
                                </div>
                            </li>
                            :
                            null
                    }

                    {
                        userFriendlyAddress !== '' ?

                            <li className="">
                                <div ref={myRef} className="account" onClick={() => {
                                    // setAccountOpen(!accountOpen);
                                    handleClickInside()
                                }}>
                                    <p className="total">
                                        <span>0</span> MRS
                                    </p>
                                    <img src={RacerCoin} alt="" />
                                    <div className={`account-box ${toggleAccountClassName}`}>
                                        <div className="account-box__inner">
                                            <h3> {name_account}
                                            </h3>
                                            <p> Your Balance</p>
                                            <div className="account-row dflex align-center">
                                                <img src={TonCoin} alt="" />
                                                <div className="account__name">
                                                    <h5>Balance</h5>
                                                    <p>{user ? user.TonBalance : 0}</p>         
                                                </div>
                                            </div>
                                            <div className="account-row dflex align-center">
                                                <img src={RacerCoin} alt="" />
                                                <div className="account__name">
                                                    <h5>Balance</h5>
                                                    <p>0</p>
                                                </div>
                                            </div>
                                            <div className="links">
                                                <Link to="/my-account" className="dflex align-center">
                                                    <svg fill="currentColor" viewBox="0 0 13 15" height="15" width="17px" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                                                        <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M12.3593 11.7194C12.3593 14.094 9.10285 14.3685 6.65401 14.3685L6.47877 14.3684C4.91858 14.3646 0.947266 14.2661 0.947266 11.705C0.947266 9.37882 4.07287 9.06794 6.50307 9.05622L6.82925 9.05602C8.38934 9.0598 12.3593 9.15831 12.3593 11.7194ZM6.65401 10.1366C3.58414 10.1366 2.02795 10.6639 2.02795 11.705C2.02795 12.7554 3.58414 13.2878 6.65401 13.2878C9.72316 13.2878 11.2786 12.7605 11.2786 11.7194C11.2786 10.669 9.72316 10.1366 6.65401 10.1366ZM6.65401 0.0527344C8.76351 0.0527344 10.4789 1.76887 10.4789 3.87837C10.4789 5.98787 8.76351 7.70328 6.65401 7.70328H6.63096C4.52578 7.69679 2.82046 5.97994 2.82764 3.8762C2.82764 1.76887 4.54379 0.0527344 6.65401 0.0527344ZM6.65401 1.08155C5.11151 1.08155 3.85646 2.33587 3.85646 3.87837C3.85143 5.41582 5.0971 6.66942 6.63312 6.67518L6.65401 7.18959V6.67518C8.19579 6.67518 9.45011 5.42015 9.45011 3.87837C9.45011 2.33587 8.19579 1.08155 6.65401 1.08155Z" fill="#6D6D6D"></path>
                                                    </svg>
                                                    <p>My profile</p>
                                                </Link>
                                                <a className="dflex align-center" onClick={handleLogOut}>
                                                    <svg fill="currentColor" viewBox="0 0 17 16" height="16" width="17px" xmlns="http://www.w3.org/2000/svg" className="svg-icon">
                                                        <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" d="M11.8799 0.841797C14.3491 0.841797 16.3577 2.85032 16.3577 5.32029V10.6791C16.3577 13.1483 14.3491 15.1576 11.8799 15.1576H5.42576C2.95654 15.1576 0.947266 13.1483 0.947266 10.6791V5.32029C0.947266 2.85032 2.95654 0.841797 5.42576 0.841797H11.8799ZM11.8799 1.96179H5.42576C3.57403 1.96179 2.06726 3.46856 2.06726 5.32029V10.6791C2.06726 12.5308 3.57403 14.0376 5.42576 14.0376H11.8799C13.7316 14.0376 15.2377 12.5308 15.2377 10.6791V10.47L12.7752 10.4705C11.3588 10.4705 10.206 9.31837 10.2052 7.9027C10.2052 6.48553 11.3581 5.33268 12.7752 5.33194L15.2377 5.33149V5.32029C15.2377 3.46856 13.7316 1.96179 11.8799 1.96179ZM15.2377 6.45148L12.7752 6.45193C11.9756 6.45268 11.3252 7.10302 11.3252 7.90195C11.3252 8.70014 11.9763 9.35048 12.7752 9.35048L15.2377 9.35003V6.45148ZM13.1169 7.29566C13.426 7.29566 13.6769 7.54654 13.6769 7.85566C13.6769 8.16478 13.426 8.41566 13.1169 8.41566H12.884C12.5748 8.41566 12.324 8.16478 12.324 7.85566C12.324 7.54654 12.5748 7.29566 12.884 7.29566H13.1169ZM8.92537 4.23023C9.23449 4.23023 9.48537 4.48111 9.48537 4.79023C9.48537 5.09935 9.23449 5.35023 8.92537 5.35023H4.89413C4.58501 5.35023 4.33413 5.09935 4.33413 4.79023C4.33413 4.48111 4.58501 4.23023 4.89413 4.23023H8.92537Z" fill="#969696"></path>
                                                    </svg>
                                                    <p>Log out</p>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li>
                            :
                            null
                    }
                </ul>
            </div>
            <div className="overlay-menu"></div>
        </div>
    );
}

export default HeaderDashBoard;