import React, { createContext, useContext, useState, useEffect } from 'react';

const TonContext = createContext();

export function useTon() {
    return useContext(TonContext);
}

export function TonProvider({ children }) {
    const [tonBalance, setTonBalance] = useState(() => {
        const savedTonBalance = localStorage.getItem('myTonBalance');
        return savedTonBalance ? JSON.parse(savedTonBalance) : 0; 
    });

    useEffect(() => {
        localStorage.setItem('myTonBalance', JSON.stringify(tonBalance));
    }, [tonBalance]);

    return (
        <TonContext.Provider value={{ tonBalance, setTonBalance }}>
            {children}
        </TonContext.Provider>
    );
}