

import { racer_car } from '../../../assets/images/index';

export { data_typeCar };

const data_typeCar = [
    {
        "car_id": "Car_001",
        "name": "PROTOTYPE",
        "description": "Car_001",
        "prefab": "Car_001",
        "class_car": 3,
        "image": racer_car[0]
    },
    {
        "car_id": "Car_002",
        "name": "LRED",
        "description": "Car_002",
        "prefab": "Car_002",
        "class_car": 3,
        "image": racer_car[1]
    },
    {
        "car_id": "Car_003",
        "name": "KOBRA245",
        "description": "Car_003",
        "prefab": "Car_003",
        "class_car": 2,
        "image": racer_car[2]
    },
    {
        "car_id": "Car_004",
        "name": "FORMULA1991",
        "description": "Car_004",
        "prefab": "Car_004",
        "class_car": 3,
        "image": racer_car[3]
    },
    {
        "car_id": "Car_005",
        "name": "GROUP B",
        "description": "Car_005",
        "prefab": "Car_005",
        "class_car": 2,
        "image": racer_car[4]
    },
    {
        "car_id": "Car_006",
        "name": "TOURING",
        "description": "Car_006",
        "prefab": "Car_006",
        "class_car": 2,
        "image": racer_car[5]
    },
    {
        "car_id": "Car_007",
        "name": "GSX88",
        "description": "Car_007",
        "prefab": "Car_007",
        "class_car": 2,
        "image": racer_car[6]
    },
    {
        "car_id": "Car_008",
        "name": "GSXRED",
        "description": "Car_008",
        "prefab": "Car_008",
        "class_car": 2,
        "image": racer_car[7]
    },
    {
        "car_id": "Car_009",
        "name": "GSXSAMURAI",
        "description": "Car_009",
        "prefab": "Car_009",
        "class_car": 2,
        "image": racer_car[8]
    },
    {
        "car_id": "Car_010",
        "name": "GSXWHITE",
        "description": "Car_010",
        "prefab": "Car_010",
        "class_car": 2,
        "image": racer_car[9]
    },
    {
        "car_id": "Car_011",
        "name": "SHAWMENDET",
        "description": "Car_011",
        "prefab": "Car_011",
        "class_car": 1,
        "image": racer_car[10]
    },
    {
        "car_id": "Car_012",
        "name": "THE EDGE",
        "description": "Car_012",
        "prefab": "Car_012",
        "class_car": 1,
        "image": racer_car[11]
    },
    {
        "car_id": "Car_013",
        "name": "DRAKE",
        "description": "Car_013",
        "prefab": "Car_013",
        "class_car": 1,
        "image": racer_car[12]
    },
    {
        "car_id": "Car_014",
        "name": "OTL88",
        "description": "Car_014",
        "prefab": "Car_014",
        "class_car": 1,
        "image": racer_car[13]
    },
    {
        "car_id": "Car_015",
        "name": "DICABARO",
        "description": "Car_015",
        "prefab": "Car_015",
        "class_car": 1,
        "image": racer_car[14]
    },
    {
        "car_id": "Car_016",
        "name": "JARED LETO",
        "description": "Car_016",
        "prefab": "Car_016",
        "class_car": 1,
        "image": racer_car[15]
    },
    {
        "car_id": "Car_017",
        "name": "SONIC90",
        "description": "Car_017",
        "prefab": "Car_017",
        "class_car": 2,
        "image": racer_car[16]
    },
    {
        "car_id": "Car_018",
        "name": "RITAORA",
        "description": "Car_018",
        "prefab": "Car_018",
        "class_car": 2,
        "image": racer_car[17]
    },
    {
        "car_id": "Car_019",
        "name": "ANGLE19",
        "description": "Car_019",
        "prefab": "Car_019",
        "class_car": 2,
        "image": racer_car[18]
    },
    {
        "car_id": "Car_020",
        "name": "FLCRAMBO",
        "description": "Car_020",
        "prefab": "Car_020",
        "class_car": 1,
        "image": racer_car[19]
    },
    {
        "car_id": "Car_021",
        "name": "CAYENNERED PORSCHE",
        "description": "Car_021",
        "prefab": "Car_021",
        "class_car": 1,
        "image": racer_car[20]
    },
    {
        "car_id": "Car_022",
        "name": "EVQQUEJAIAN",
        "description": "Car_022",
        "prefab": "Car_022",
        "class_car": 1,
        "image": racer_car[21]
    },
    {
        "car_id": "Car_023",
        "name": "EDZONDA",
        "description": "Car_023",
        "prefab": "Car_023",
        "class_car": 2,
        "image": racer_car[22]
    },
    {
        "car_id": "Car_024",
        "name": "HURACAN ACE",
        "description": "Car_024",
        "prefab": "Car_024",
        "class_car": 2,
        "image": racer_car[23]
    },
    {
        "car_id": "Car_025",
        "name": "BENZ LUF",
        "description": "Car_025",
        "prefab": "Car_025",
        "class_car": 2,
        "image": racer_car[24]
    },
    {
        "car_id": "Car_026",
        "name": "MCLAREN ZORO",
        "description": "Car_026",
        "prefab": "Car_026",
        "class_car": 2,
        "image": racer_car[25]
    },
    {
        "car_id": "Car_027",
        "name": "NAMIFERREI RACCX",
        "description": "Car_027",
        "prefab": "Car_027",
        "class_car": 3,
        "image": racer_car[26]
    },
    {
        "car_id": "Car_028",
        "name": "M6",
        "description": "Car_028",
        "prefab": "Car_028",
        "class_car": 1,
        "image": racer_car[27]
    },
    {
        "car_id": "Car_029",
        "name": "COUPEPREFAB",
        "description": "Car_029",
        "prefab": "Car_029",
        "class_car": 2,
        "image": racer_car[28]
    },
    {
        "car_id": "Car_030",
        "name": "CAMARO",
        "description": "Car_030",
        "prefab": "Car_030",
        "class_car": 2,
        "image": racer_car[29]
    },
    {
        "car_id": "Car_031",
        "name": "CARRERA",
        "description": "Car_031",
        "prefab": "Car_031",
        "class_car": 1,
        "image": racer_car[30]
    },
    {
        "car_id": "Car_032",
        "name": "KOBRA",
        "description": "Car_032",
        "prefab": "Car_032",
        "class_car": 1,
        "image": racer_car[31]
    },
    {
        "car_id": "Car_033",
        "name": "SEAGULL",
        "description": "Car_033",
        "prefab": "Car_033",
        "class_car": 1,
        "image": racer_car[32]
    },
    {
        "car_id": "Car_034",
        "name": "WHEN LAMBO",
        "description": "Car_034",
        "prefab": "Car_034",
        "class_car": 3,
        "image": racer_car[33]
    },
    {
        "car_id": "Car_035",
        "name": "TERBUKTI DIPASARAN",
        "description": "Car_035",
        "prefab": "Car_035",
        "class_car": 1,
        "image": racer_car[34]
    },
    {
        "car_id": "Car_036",
        "name": "JONEWICK GREEN",
        "description": "Car_036",
        "prefab": "Car_036",
        "class_car": 2,
        "image": racer_car[35]
    },
    {
        "car_id": "Car_037",
        "name": "ENDURANCE B00",
        "description": "Car_037",
        "prefab": "Car_037",
        "class_car": 3,
        "image": racer_car[36]
    },
    {
        "car_id": "Car_038",
        "name": "ENDURANCE A01",
        "description": "Car_038",
        "prefab": "Car_038",
        "class_car": 3,
        "image": racer_car[37]
    },
    {
        "car_id": "Car_039",
        "name": "ENDURANCE M56",
        "description": "Car_039",
        "prefab": "Car_039",
        "class_car": 3,
        "image": racer_car[38]
    },
    {
        "car_id": "Car_040",
        "name": "MONARCH",
        "description": "Car_040",
        "prefab": "Car_040",
        "class_car": 3,
        "image": racer_car[39]
    },
    {
        "car_id": "Car_041",
        "name": "SALVATOR",
        "description": "Car_041",
        "prefab": "Car_041",
        "class_car": 1,
        "image": racer_car[40]
    },
    {
        "car_id": "Car_042",
        "name": "ALPINIST",
        "description": "Car_042",
        "prefab": "Car_042",
        "class_car": 2,
        "image": racer_car[41]
    },
    {
        "car_id": "Car_043",
        "name": "BISON",
        "description": "Car_043",
        "prefab": "Car_043",
        "class_car": 2,
        "image": racer_car[42]
    },
    {
        "car_id": "Car_044",
        "name": "TIPO",
        "description": "Car_044",
        "prefab": "Car_044",
        "class_car": 2,
        "image": racer_car[43]
    },
    {
        "car_id": "Car_045",
        "name": "MURRAH",
        "description": "Car_045",
        "prefab": "Car_045",
        "class_car": 1,
        "image": racer_car[44]
    }
]