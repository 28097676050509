import React, { useEffect, useRef, useState } from "react";
import { TonConnectButton, useTonAddress } from '@tonconnect/ui-react';
import Pagination from 'react-bootstrap/Pagination';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';

import {
    BrowserRouter as Router,
    Link, useNavigate
} from "react-router-dom";

import {
    FiClock,
    FiBriefcase,
    FiStar, 
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import 'bootstrap/dist/css/bootstrap.min.css';
import './RacerBox.css';

import EpicBox from '../../../assets/images/box/box-save2.png';
import BgEpic from '../../../assets/images/box/bg-epic.svg';
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';
import NotFound from '../../../assets/images/dashboard/notfound.svg';


import { GetHistoryBuyBox } from "../../../api/api";

const NotFoundData = () => {
    return (
        <div className="data-notFound">
            <img src={NotFound} alt="" />
            <span className="text-primary">Sorry, no result found.</span>
        </div>
    )
}

const HistoryTonTable = ({ data }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data ? data.slice(indexOfFirstItem, indexOfLastItem) : data;

    const totalItems = data && data.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="table-responsive pt-4 history-whitelist">
                <table className="table table-bordered">
                    <thead>
                        <tr className="text-center text-white">
                            <th>Date</th>
                            <th>Amount Buy</th>
                            <th>Quantity</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItems.length ?
                                currentItems.map((item, index) => {
                                    return (
                                        <tr className="text-center" key={index}>
                                            <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                            <td>
                                                <span className="fw-bolder d-flex align-items-center justify-content-center">{item.amountSend} <img className="ml-1" src={TonCoin} alt="" width={16} /></span>
                                            </td>
                                            <td>
                                                <span className="fw-bolder">{item.quantity ? item.quantity : 0} </span>
                                            </td>
                                            <td>
                                                {item.status === "DONE" && <span className="fw-bolder text-success">{item.status}</span>}
                                                {item.status === "PENDING" && <span className="fw-bolder text-warning">{item.status}</span>}
                                                {item.status === "WAIT" && <span className="fw-bolder text-primary">{item.status}</span>}
                                                {item.status === "FAIL" && <span className="fw-bolder text-danger">{item.status}</span>}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={4}>
                                        <NotFoundData />
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                data && data.length ?
                    <Pagination className="justify-content-end">
                        <Pagination.Prev
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage > 1 ? prevPage - 1 : prevPage
                                )
                            }
                            disabled={prevDisabled}
                        >
                            <FiChevronsLeft className="text-primary" />
                        </Pagination.Prev>
                        {pageNumbers.map((number) => (
                            <Pagination.Item
                                key={number}
                                active={number === currentPage}
                                onClick={() => handlePageChange(number)}
                            >
                                {number}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                )
                            }
                            disabled={nextDisabled}
                        >
                            <FiChevronsRight className="text-primary" />
                        </Pagination.Next>
                    </Pagination>
                    :
                    ""
            }
        </>
    );

}

const RacerBox = () => {

    const history = useNavigate();
    const userFriendlyAddress = useTonAddress();

    const TonApiKey = process.env.REACT_APP_TON_API_KEY;
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');


    const [numberOfRemainBox, setNumberOfRemainBox] = useState(100);
    const [limitBox, setLimitBox] = useState(3000); 
    const [totalBoxUserBuy, setTotalBoxUserBuy] = useState(100);   
     
    const [priceUSD, setpriceUSD] = useState(0);    
    const [historyWhiteListData, setHistoryWhiteListData] = useState([]);
    const [onLoading, setOnLoading] = useState(false);

    const loadHistoryListInfo = async () => {
        if (token) {
            setOnLoading(true);
            const historyData = await GetHistoryBuyBox(token);
            if (historyData.status.code === 200) {
                setLimitBox(historyData.data.availableBox);
                setNumberOfRemainBox(historyData.data.remainBox);
                setTotalBoxUserBuy(historyData.data.totalBoxUserBuy);
                setHistoryWhiteListData(historyData.data.listHistoryBuyBox);
                setOnLoading(false);
            } else {
                setHistoryWhiteListData([]);
                setOnLoading(false);
            }
        }
    }

    useEffect(() => {

        loadHistoryListInfo();

    }, []);

    return (
        <>
            <section className="racerbox-wrapper">
                <div className="box-title-main">
                    <div className="title-main padding-title">
                        MetaRacers Box
                    </div>
                    <span className="text-center">MetaRacer contains varius cars with cetain drop rates. <br /> The higher quality of the Meta RacerBox is, the higher the drop rate for the high-quality Cars is.</span>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <FiBriefcase size={24} />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">AVAILABLE</span>
                                    <h3 className="text-primary">{limitBox}</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <FiClock size={24} />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">TIME REMAINING</span>
                                    <h3 className="text-primary">UNLIMTED TIME</h3> 
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <FiStar size={32} />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">PURCHASED</span>
                                    <h3 className="text-primary">{totalBoxUserBuy ? totalBoxUserBuy : 100 - numberOfRemainBox}/100</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row racerBox-row align-items-center justify-content-center">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="racerBox">
                                <div className="racerBox-img" style={{ backgroundImage: `url(${BgEpic})` }}>
                                    <img src={EpicBox} alt="" />
                                </div>
                                <div className="racerBox-text text-center">
                                    <h3 className="text-primary">RACER BOX</h3>
                                    <span>Remainning boxes: <strong className="text-primary">{numberOfRemainBox}</strong></span>
                                    <Link to="/racer-box-detail" className="btn-openBox">
                                        <span className="racerBoxPrice">2 TON <img src={TonCoin} alt="" width={24} /></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row racerBox-row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="box flex-direction-column">
                                <h4 className="d-block text-uppercase">HISTORY BUY RACER BOX</h4>
                                {
                                    historyWhiteListData.length > 0 ?
                                    <>
                                        <HistoryTonTable data={historyWhiteListData} />
                                    </>
                                    :
                                    <>
                                        <HistoryTonTable data={[]} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                onLoading && (
                    <div className="loading-wrapper">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                )
            }
        </>

    );
}

export default RacerBox;
