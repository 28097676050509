import React, { useEffect, useRef, useState } from "react";
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { useToast } from 'rc-toastr';
import { beginCell } from '@ton/ton';
import axios from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import moment from 'moment';
import 'moment-timezone';

import Decimal from 'decimal.js';

import Countdown from 'react-countdown';
import {
    BrowserRouter as Router,
    Link, useNavigate
} from "react-router-dom";

import {
    FiClock,
    FiBriefcase,
    FiCheckCircle,
    FiXCircle,
    FiChevronsLeft,
    FiChevronsRight
} from "react-icons/fi";

import 'bootstrap/dist/css/bootstrap.min.css';
import './whiteList.css';


import RacerCoin from '../../../assets/images/coin/coin.svg';
import BgEpic from '../../../assets/images/box/bg-epic.svg';
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';
import NotFound from '../../../assets/images/dashboard/notfound.svg';
import WhiteListImage from '../../../assets/images/whiteList.jpg';


import {
    CheckWhitelist,
    BuyWhitelist
} from "../../../api/api";

import { useUser } from '../../../context/UserContext';



const NotFoundData = () => {
    return (
        <div className="data-notFound">
            <img src={NotFound} alt="" />
            <span className="text-primary">Sorry, no result found.</span>
        </div>
    )
}

const HistoryTonTable = ({ data }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20; // Số dòng trên mỗi trang;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data ? data.slice(indexOfFirstItem, indexOfLastItem) : data;

    const totalItems = data && data.length;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    };

    const prevDisabled = currentPage === 1;
    const nextDisabled = currentPage === Math.ceil(totalItems / itemsPerPage);

    // Hàm chuyển trang
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            <div className="table-responsive pt-4 history-whitelist">
                <table className="table table-bordered">
                    <thead>
                        <tr className="text-center text-white">
                            <th>Date</th>
                            <th>Amount Deposit</th>
                            <th>MRS Receive</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItems.length ?
                                currentItems.map((item, index) => {
                                    return (
                                        <tr className="text-center" key={index}>
                                            <td><span className="text-primary fw-bolder">{moment.unix(parseInt(item.createdAtTimestamp)).tz("UTC").format("MM/DD/YYYY HH:mm:ss")}</span></td>
                                            <td>
                                                <span className="fw-bolder">{item.amountSend} <img src={TonCoin} alt="" width={16} /></span>
                                            </td>
                                            <td>
                                                <span className="fw-bolder">{item.mrs_receive ? item.mrs_receive : 0} <img src={RacerCoin} alt="" width={16} /></span>
                                            </td>
                                            <td>
                                                {item.status === "DONE" && <span className="fw-bolder text-success">{item.status}</span>}
                                                {item.status === "PENDING" && <span className="fw-bolder text-warning">{item.status}</span>}
                                                {item.status === "WAIT" && <span className="fw-bolder text-primary">{item.status}</span>}
                                                {item.status === "FAIL" && <span className="fw-bolder text-danger">{item.status}</span>}
                                            </td>
                                        </tr>
                                    )
                                })
                                :
                                <tr>
                                    <td colSpan={5}>
                                        <NotFoundData />
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
            {
                data && data.length ?
                    <Pagination className="justify-content-end">
                        <Pagination.Prev
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage > 1 ? prevPage - 1 : prevPage
                                )
                            }
                            disabled={prevDisabled}
                        >
                            <FiChevronsLeft className="text-primary" />
                        </Pagination.Prev>
                        {pageNumbers.map((number) => (
                            <Pagination.Item
                                key={number}
                                active={number === currentPage}
                                onClick={() => handlePageChange(number)}
                            >
                                {number}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next
                            onClick={() =>
                                setCurrentPage((prevPage) =>
                                    prevPage < pageNumbers.length ? prevPage + 1 : prevPage
                                )
                            }
                            disabled={nextDisabled}
                        >
                            <FiChevronsRight className="text-primary" />
                        </Pagination.Next>
                    </Pagination>
                    :
                    ""
            }
        </>
    );

}

const WhiteListPage = () => {

    const history = useNavigate();
    const { toast } = useToast();
    const { user, setUser } = useUser();
    const userFriendlyAddress = useTonAddress();
    // TON Config
    const TonApiKey = process.env.REACT_APP_TON_API_KEY;
    const addressWallet = localStorage.getItem('addressWallet');
    const addressBalance = user ? user.addressWallet : userFriendlyAddress;

    const accountData = localStorage.getItem('myAccount') && JSON.parse(localStorage.getItem('myAccount'));
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
    const hash = localStorage.getItem("hash") && localStorage.getItem("hash").replace(/"/g, '');
    const [tonConnectUI, setOptions] = useTonConnectUI();

    const minWithdraw = 200,
        maxWithdraw = 500,
        rateFeeWithdraw = 0.03,
        feeChargable = 0.1;

    const timeRemain = 1644888600000;
    //Date and time (GMT): Tuesday, February 15, 2022 1:30:00 AM
    //Date and time (your time zone): Tuesday, February 15, 2022 8:30:00 AM GMT+07:00

    const [numberOfRemainBox, setNumberOfRemainBox] = useState(0);
    const [limitBox, setLimitBox] = useState(0);

    const [onLoading, setOnLoading] = useState(false);
    const [amountDepositTon, setAmountDepositTon] = useState(0);
    const [checkAddressWhiteList, setCheckAddressWhiteList] = useState(false);
    const [timeEndWhiteList, setTimeEndWhiteList] = useState(0);
    const [historyWhiteListData, setHistoryWhiteListData] = useState([]);
    const [MRS_Whitelist_Receive, setMRS_Whitelist_Receive] = useState(0);
    const [TON_Buy_Whitelist, setTON_Buy_Whitelist] = useState(0);
    const [disableButtonDeposit, setDisableButtonDeposit] = useState(false);


    const SendTonTransaction = async (seqno, comment, amountDepositTonInput) => {
        setDisableButtonDeposit(true);
        const body = beginCell()
            .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            .storeStringTail(comment) // write our text comment
            .endCell();

        try {
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: process.env.REACT_APP_DEPOSIT,
                        amount: String(amountDepositTonInput),
                        payload: body.toBoc().toString("base64"),
                        // address: hexAddress,
                        // // amount: tonweb.utils.toNano(amountDepositTon)
                        // amount: amountDeposit
                    }
                ]
            }
            const send = await tonConnectUI.sendTransaction(transaction);
            if (send && String(send.boc).length > 0) {
                const checkTon = await BuyWhitelist(amountDepositTonInput, comment, seqno, token, hash);
                if (checkTon.status.code === 200) {
                    await getHistoryWhiteList();
                    setOnLoading(true);
                    setTimeout(async () => {
                        setOnLoading(false);
                    }, 2000)
                } else {
                    toast.warning(checkTon.status.message);
                }
            }

        } catch (error) {
            console.error("Error during transaction:", error);
        } finally {
            setDisableButtonDeposit(false);  // Re-enable button in finally block to ensure it's always re-enabled
        }
    }

    const handleBuyWhitelist = async () => {
        if (amountDepositTon < 1 || amountDepositTon > 100) {
            toast.warning('The minimum Deposit Amount is 1 TON and the maximum is 100 TON.');
        } else {
            // const address = new tonweb.utils.Address(process.env.REACT_APP_DEPOSIT);
            // const hexAddress = address.toString(false, true, true);
            const amountDepositTonInput = (amountDepositTon * 10 ** 9);

            const currentDate = new Date();
            const currentDateTimestamp = (currentDate.setSeconds(currentDate.getSeconds())) / 1000;

            const comment = "WL " + amountDepositTon + " TON :" + String(parseInt(currentDateTimestamp));

            //Lấy cho a cái số seqno trước khi sendTransaction
            //`https://tonapi.io/v2/wallet/${user.addressWallet}/seqno`

            const config_addressBalance = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/wallet/${userFriendlyAddress}/seqno`,
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${TonApiKey}`
                }
            };
            const response = await axios.request(config_addressBalance);
            const data = typeof response.data == 'undefined' ? null : response.data;
            SendTonTransaction(data.seqno, comment, amountDepositTonInput, addressWallet);
        }
    }

    const getHistoryWhiteList = async () => {
        const historyData = await CheckWhitelist(token);
        setHistoryWhiteListData(historyData.status.code === 200 ? historyData.data.listHisWhitelist : []);
    }

    // const initData = async () => {
    //     setOnLoading(true);
    //     try {
    //         const data = await CheckWhitelist(token);
    //         if( data.status.code === 200){

    //             setTimeEndWhiteList(data.data.time_end_whitelist);
    //             setCheckAddressWhiteList(data.data.isWhitelist);
    //             setHistoryWhiteListData(data.data.listHisWhitelist);
    //             setMRS_Whitelist_Receive(data.data.MRS_Whitelist_Receive);
    //             setTON_Buy_Whitelist(data.data.TON_Buy_Whitelist);

    //             setOnLoading(false);
    //         }
    //     } catch (error) { 
    //         setOnLoading(false);
    //     }
    // }

    // useEffect(() => {

    //     initData();
    // }, []);

    const loadWhiteListInfo = async () => {
        if (token) {
            setOnLoading(true);
            const historyData = await CheckWhitelist(token);
            if (historyData.status.code === 200) {
                setTimeEndWhiteList(historyData.data.time_end_whitelist);
                setCheckAddressWhiteList(historyData.data.isWhitelist);
                setHistoryWhiteListData(historyData.data.listHisWhitelist);
                setMRS_Whitelist_Receive(historyData.data.MRS_Whitelist_Receive);
                setTON_Buy_Whitelist(historyData.data.TON_Buy_Whitelist);
                setOnLoading(false);
            } else {
                setHistoryWhiteListData([]);
                setOnLoading(false);
            }
        }
    }

    useEffect(() => {

        loadWhiteListInfo();

    }, []);

    return (
        <>
            <section className="racerbox-wrapper whitelist-wrapper">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 col-12">
                            <div className="box-title-main">
                                <div className="title-main padding-title">
                                    WhiteList MRS
                                </div>
                                <span className="text-center">The MRS project is conducting a whitelist sale on the TON Blockchain, providing an exclusive opportunity for early supporters to secure their allocation before the public sale. By participating in the whitelist, you can get in on the ground floor of this promising project and potentially benefit from any price appreciation after the public launch. Don't miss your chance to be among the first investors in MRS - purchase your whitelist allocation on the TON Blockchain today!</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <FiBriefcase size={24} />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">TOTAL MRS AVAILABLE</span>
                                    <h3 className="text-primary">{Number(parseFloat(new Decimal(MRS_Whitelist_Receive)).toFixed(8))} <img src={RacerCoin} alt="" /></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <FiClock size={24} />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">TIME REMAINING</span>
                                    {/* <h3 className="text-primary">9D 3H 23M</h3>  */}
                                    {
                                        timeEndWhiteList > 0 &&
                                        <Countdown
                                            // date={Date.now() + (timeEndWhiteList * 1000)}

                                            date={timeEndWhiteList * 1000}
                                            renderer={({ days, hours, minutes, seconds, completed }) => {
                                                var day, hour, minute, second;
                                                String(days).length === 1 ? day = '0' + days : day = days
                                                String(hours).length === 1 ? hour = '0' + hours : hour = hours
                                                String(minutes).length === 1 ? minute = '0' + minutes : minute = minutes
                                                String(seconds).length === 1 ? second = '0' + seconds : second = seconds
                                                if (completed) {
                                                    // Render a completed state
                                                    return (
                                                        <h3 className="text-primary">{day}D {hour}H {minute}M {second}S</h3>
                                                    );
                                                } else {
                                                    // Render a countdown
                                                    return (
                                                        <h3 className="text-primary">{day}D {hour}H {minute}M {second}S</h3>
                                                    );
                                                }
                                            }}
                                        />
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <div className="box">
                                <div className="box-icon">
                                    <img src={TonCoin} alt="" />
                                </div>
                                <div className="box-text">
                                    <span className="text-uppercase">TON PURCHASED</span>
                                    <h3 className="text-primary">{TON_Buy_Whitelist}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row racerBox-row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-6 col-12">
                            <div className="racerBox">
                                <div className="racerBox-img">
                                    <img src={WhiteListImage} alt="" />
                                </div>
                                <div className="racerBox-text text-center">
                                    <h3 className="text-primary">WHITELIST MRS</h3>
                                    <span className="address-check">
                                        <span>{userFriendlyAddress && userFriendlyAddress}</span>
                                        {
                                            checkAddressWhiteList ?
                                                <FiCheckCircle className="text-success" />
                                                :
                                                <FiXCircle className="text-danger" />
                                        }
                                    </span>
                                    <div className="d-flex flex-column align-items-center justify-content-between p-2 mt-3">
                                        <div className="token-info-emrs bg-dark mt-0 mb-0">
                                            <span>Rate:  1 <b className="text-primary">TON</b> = 7500 <b className="text-primary">MRS</b></span>
                                            <span>The minimum participation is 1 <b className="text-primary">TON</b> per deposit.</span>
                                            <span>The maximum participation is 100 <b className="text-primary">TON</b> per deposit.</span>
                                            <span>You will be able to claim 25% of the total MRS tokens you purchased each month for 4 months after MRS is listed on a decentralized exchange (DEX).</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between iconToken-box">
                                            <div className="iconToken-wrap deposit-wrap">
                                                <input placeholder="Amount" className="amout" type="number" onChange={(e) => setAmountDepositTon(e.target.value)} value={amountDepositTon}
                                                />
                                                <div className="d-flex align-items-center justify-content-between icon-token">
                                                    <img src={TonCoin} alt="" />
                                                    <span>TON</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-claim p-3 w-100" disabled={disableButtonDeposit} onClick={handleBuyWhitelist}>JOIN</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row racerBox-row align-items-center justify-content-center">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="box flex-direction-column">
                                <h4 className="d-block text-uppercase">Whitelist</h4>
                                <div className="address-check">
                                    <div className="text-center">
                                        <span>{userFriendlyAddress && userFriendlyAddress}</span>
                                        {
                                            checkAddressWhiteList ?
                                                <FiCheckCircle className="text-success" />
                                                :
                                                <FiXCircle className="text-danger" />
                                        }
                                        <div className="text-center"><button className="btn btn-claim" disabled>CLAIM</button></div>

                                    </div>
                                </div>
                                {
                                    historyWhiteListData.length > 0 ?
                                        <>
                                            <HistoryTonTable data={historyWhiteListData} />
                                        </>
                                        :
                                        <>
                                            <HistoryTonTable data={[]} />
                                        </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                onLoading && (
                    <div className="loading-wrapper">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                )
            }
        </>

    );
}

export default WhiteListPage;
