import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { QRCode } from 'react-qrcode-logo';
import { useToast } from 'rc-toastr';
import NumberFormat from 'react-number-format';

import Button from '@mui/material/Button';

import {
    FiX,
    FiCopy,
    FiArrowDown
} from "react-icons/fi";

import RacerCoin from '../../../assets/images/coin/coin.svg';
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';

import {
    checkExistAccountWithAddress, ChangeName, GetBalanceEMRS, GetNFTFromAddress, GetNumberBox,
    getBalanceOfToken, GetDeviceId, ResetDeviceId, DepositToken, WithdrawToken, getBalanceOfBNB, getPrice,
    GetMRSBalanceFromAddress, GetBNBBalanceFromAddress, GetNFTSelling, GetNFTPlaying,
    InviteUser,
    AcceptInvite,
    GetListReferral,
    GetListHistoryOfWhoInvite,
    LoadReferralToDatabase, GetSponsor,
    GetNumberUnbox
} from '../../../api/api';

import { useUser } from '../../../context/UserContext';

const ConvertSettingPage = ({ web3, provider, onConnect, resetApp, address, bnbPrice, usdPrice, balanceBNB, balanceMRS, bnbUsdt }) => {
    const { toast } = useToast();
    const { user, setUser } = useUser();
    const history = useNavigate();
    

    const [balanceBNBState, setBalanceBNBState] = useState(balanceBNB);
    const [balanceMRState, setBalanceMRState] = useState(balanceMRS);
    const [usdPriceState, setUsdPriceState] = useState(usdPrice);
    const [bnbPriceState, setBnbPriceState] = useState(bnbPrice);
    const [balanceEMRS, setBalanceEMRS] = useState(0);

    const [openDeposit, setopenDeposit] = useState(false);
    const [openDepositTonQR, setopenDepositTonQR] = useState(false);
    const [openClaim, setopenClaim] = useState(false);


    const [textDepositButton, setTextDepositButton] = useState("Request Convert");
    const [disableButtonDeposit, setDisableButtonDeposit] = useState(false);
    const [amountDeposit, setAmountDeposit] = useState(0);
    const minDeposit = 30, maxDeposit = 2000;

    const copyToClipBoard = async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            toast.success('Copied successfully');
        } catch (err) {
            toast.warning('Copy failed');
        }
    };

    const ImportMRS = () => {
        var tokenName = 'Metaracers',
            tokenSymbol = 'MRS',
            tokenDecimals = 18,
            tokenAddress = '0x9e05e646a18bb4caB41aa7992959f019D0aac124',
            tokenImage = 'https://meta-racers.com/favicon.ico',
            tokenNet = '1',
            message = '',
            errorMessage = '',
            net = '1';
        provider.sendAsync({
            method: 'wallet_watchAsset', //metamask_watchAsset
            params: {
                "type": "ERC20",
                "options": {
                    "address": tokenAddress,
                    "symbol": tokenSymbol,
                    "decimals": tokenDecimals,
                    "image": tokenImage,
                },
            },
            id: Math.round(Math.random() * 100000),
        }, (err, added) => {
            // console.log('provider returned', err, added)
        })
    }

    const handleDeposit = async () => {
        toast.warning('Please wait !');
    }

    return (
        <>
            <h3 className="title_main">CONVERT</h3>
            <div className="convert-wrapper">
                <div className="wallet-box wallet-boxIcon">
                    <div>
                        <h3>CONVERT TON TO EMRS</h3>
                        <span className="font-12">You are about to convert TON to eMRS token</span>
                        {/* <span className="font-12">Available: {availableTokenDeposit}</span> */}
                        <NumberFormat type="text" value={parseFloat(balanceMRState).toFixed(2)} displayType={'text'} thousandSeparator={true}
                            renderText={(value, props) => <span className="font-12">Available: {value}</span>} />
                        <div className="d-flex flex-column align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between iconToken-box">
                                <div className="iconToken-wrap deposit-wrap">
                                    <input placeholder="Amount" className="amout" type="number" onChange={(e) => setAmountDeposit(e.target.value)} value={amountDeposit} />
                                    <div className="d-flex align-items-center justify-content-between icon-token">
                                        <img src={TonCoin} alt="" />
                                        <span>TON</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="convertBoxIcon">
                            <div className="icon">
                                <FiArrowDown />
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-between iconToken-box">
                                <div className="iconToken-wrap deposit-wrap">
                                    <input placeholder="Amount" className="amout" type="number" onChange={(e) => setAmountDeposit(e.target.value)} value={amountDeposit} />
                                    <div className="d-flex align-items-center justify-content-between icon-token">
                                        <img src={RacerCoin} alt="" />
                                        <span>EMRS</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="d-block mt-2 text-left text-primary font-12">*Min convertable: 30 | Max convertable: 2000</span>
                        {/* <Alert severity="error">Reach rank Bronze 1 to start deposit your token</Alert> */}
                        <button disabled={disableButtonDeposit} className="btn btn-claim w-100" onClick={handleDeposit}>{textDepositButton}</button>
                    </div>
                </div>
            </div>
        </>
    );

}

export default ConvertSettingPage;
