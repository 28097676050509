import axios from 'axios';
import { host } from './connectApi';

export {
    getBalanceOfToken,
    getBalanceOfBNB,
    getPrice,
    checkExistAccountWithAddress,
    LoginInWeb,
    CreateNewUser,
    ChangeName,
    GetBalanceEMRS,
    GetNFTFromAddress,
    CreatePaymentBuyBox,
    GetNumberBox,
    OpenBoxWithWeb,
    GetRemainingBox,
    GetDeviceId,
    ResetDeviceId,
    DepositToken,
    WithdrawToken,
    GetInfoCarDetail,
    GetMRSBalanceFromAddress,
    GetBNBBalanceFromAddress,
    ResetPassword,
    ChangePasswordForget,
    SellNFT,
    GetNFTSelling,
    CancelNFT,
    GetDataMarket,
    BuyNFT,
    GetDefautCarInGame,
    SortDataMarket,
    GetNFTPlaying,
    InviteUser,
    AcceptInvite,
    GetListReferral,
    GetListHistoryOfWhoInvite,
    LoadReferralToDatabase,
    CreateAccountWithRef,
    GetSponsor,
    LoadBuyBoxUnlimit,
    GetNumberUnbox,
    CheckDepositTon,
    GetUserInfor,
    HistoryDepositTon,
    BuyWhitelist,
    CheckWhitelist,
    CheckBuyBox,
    BuyBoxWithTon,
    GetHistoryBuyBox
}

const urlCheckExistAccountWithAddress = host + 'checkExistAccountWithAddress';
const urlLoginInWeb = host + 'loginweb';
const urlCreateNewUser = host + 'intoUserDB';
const urlChangeName = host + 'changenameuser';
const urlGetBalanceEMRS = host + 'getBalanceEMRS';
const urlGetNFTFromAddress = host + 'getListNFTFromDB';
const urlCreatePaymentBuyBox = host + 'createPaymentBuyBox';
const urlGetNumberBox = host + 'getNumberBox';
const urlOpenBoxWithWeb = host + 'openBoxWithWeb';
const urlGetRemainingBox = host + 'getRemainingBox';
const urlGetDeviceId = host + 'getDeviceId';
const urlResetDeviceId = host + 'resetDeviceId';
const urlDepositToken = host + 'depositToken';
const urlWithdrawToken = host + 'withdrawToken';
const urlGetInfoCarDetail = host + 'getInfoCar';
const urlGetMRSBalanceFromAddress = host + 'getMRSBalanceFromAddress';
const urlGetBNBBalanceFromAddress = host + 'getBNBBalanceFromAddress';
const urlGetPriceMRS = host + 'getPriceMRS';
const urlResetPassword = host + 'resetPassword';
const urlChangePasswordForget = host + 'changePasswordForget';
const urlSellNFT = host + 'sellnft';
const urlGetNFTSelling = host + 'getnftselling';
const urlCancelNFT = host + 'cancelnft';
const urlGetDataMarket = host + 'getDataMarket';
const urlBuyNFT = host + 'buynft';
const urlGetDefautCarInGame = host + 'getDefautCarInGame';
const urlSortDataMarket = host + 'sortDataMarket';
const urlGetNFTPlaying = host + 'getnftplaying';
const urlInviteUser = host + 'inviteUser';
const urlAcceptInvite = host + 'acceptInvite';
const urlGetListReferral = host + 'getListReferral';
const urlGetListHistoryOfWhoInvite = host + 'getListHistoryOfWhoInvite';
const urlLoadReferralToDatabase = host + 'loadReferralToDatabase';
const urlCreateAccountWithRef = host + 'addNewUserWithRef';
const urlGetSponsor = host + 'getSponsor';
const urlLoadBuyBoxUnlimit = host + 'loadBuyBoxUnlimit';
const urlGetNumberUnbox = host + 'getNumberUnbox';
//TON
const urlCheckDepositTon = host + 'checkDepositTon';
const urlGetUserInfor = host + 'getUserInfor';
const urlHistoryDepositTon = host + 'historyDepositTon';
//Whitelist
const urlCheckWhitelist = host + 'checkWhitelist';
const urlBuyWhitelist = host + 'buyWhitelist';
// Buy Box
const urlCheckBuyBox = host + 'checkBuyBox';
const urlBuyBoxWithTon = host + 'buyBoxWithTon';
const urlGetHistoryBuyBox = host + 'getHistoryBuyBox';


const GetBNBBalanceFromAddress = async (addressWallet) => {
    return axios.post(urlGetBNBBalanceFromAddress,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}


const GetMRSBalanceFromAddress = async (addressWallet) => {
    return axios.post(urlGetMRSBalanceFromAddress,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const getBalanceOfToken = async (address) => {

    const getData = await axios.get('https://deep-index.moralis.io/api/v2/' + address + '/erc20?chain=bsc', { // 'https://deep-index.moralis.io/api/v2/' + address + '/erc20?chain=bsc'
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'X-API-Key': 'D3liRoD9cZ2O3ImfX45JLRgppAMl4TctL8jznLE5T5hPu0VxsdPaVxLjievRffAs'
        }
    });

    var balance = 0;
    if (getData.data.length > 0) {
        balance = getData.data.filter(r => r.token_address == "0x9e05e646a18bb4cab41aa7992959f019d0aac124".toLowerCase())[0]; //0x9e05e646a18bb4cab41aa7992959f019d0aac124
        if (typeof balance == 'undefined') {
            return 0;
        } else {
            return balance.balance;
        }
    }
    return balance;
}

const getBalanceOfBNB = async (address) => {

    const getData = await axios.get('https://deep-index.moralis.io/api/v2/' + address + '/balance?chain=bsc', {
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'X-API-Key': 'D3liRoD9cZ2O3ImfX45JLRgppAMl4TctL8jznLE5T5hPu0VxsdPaVxLjievRffAs'
        }
    });
    var balance = 0
    if (getData.data.length > 0) {
        balance = getData.data.balance;
    }
    return getData;
}

const getPrice = async () => {

    var bnbPrice, usdPrice, bnbUsdt;
    await axios({
        method: "post",
        url: urlGetPriceMRS,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    }).then(function (response) {
        const price = response.data.price
        bnbPrice = price.mrsPriceToBnb;
        usdPrice = price.mrsPriceToUsdt;
    })

    await axios({
        method: "get",
        url: 'https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT',
        headers: {
            'accept': 'application/json',
        },
    }).then(function (response) {
        const data = response.data;
        bnbUsdt = data.price;
    })

    return { usdPrice: parseFloat(usdPrice).toFixed(3), bnbPrice: parseFloat(bnbPrice).toFixed(5), bnbUsdt: parseFloat(bnbUsdt).toFixed(2) };

}

const checkExistAccountWithAddress = async (addressWallet) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCheckExistAccountWithAddress,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Check your Header
        const response = await common_axios.post(urlCheckExistAccountWithAddress, {
            addressWallet: addressWallet,
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}

const LoginInWeb = async (email, addressWallet, password) => {

    try {
        var common_axios = axios.create({
            baseURL: urlLoginInWeb,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Check your Header
        const response = await common_axios.post(urlLoginInWeb, {
            addressWallet: addressWallet,
            email: email,
            password: password
        },);
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}

const CreateNewUser = async (email, addressWallet, password) => {
    try {
        var common_axios = axios.create({
            baseURL: urlCreateNewUser,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
        });
        // Check your Header
        const response = await common_axios.post(urlCreateNewUser, {
            addressWallet: addressWallet,
            email: email,
            password: password
        },);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const GetUserInfor = async (token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetUserInfor,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetUserInfor);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const ChangeName = async (email, addressWallet, name, token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlChangeName,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlChangeName,{
            addressWallet: addressWallet,
            email: email,
            name: name
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const CheckDepositTon = async (addressWallet, email, amountDeposit, comment , seqno, token, hash) => {

    try {
        var common_axios = axios.create({
            baseURL: urlCheckDepositTon,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        common_axios.defaults.headers.common['validationhash'] = hash;
        // Check your Header
        const response = await common_axios.post(urlCheckDepositTon,{
            addressWallet: addressWallet,
            email: email,
            amountDeposit: amountDeposit,
            comment: comment,
            seqno: seqno
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const CheckWhitelist = async (token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlCheckWhitelist,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckWhitelist);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const BuyWhitelist = async (amountDeposit, comment , seqno, token, hash) => {

    try {
        var common_axios = axios.create({
            baseURL: urlBuyWhitelist,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        common_axios.defaults.headers.common['validationhash'] = hash;
        // Check your Header
        const response = await common_axios.post(urlBuyWhitelist,{
            amountDeposit: amountDeposit,
            comment: comment,
            seqno: seqno
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const HistoryDepositTon = async (token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlHistoryDepositTon,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // common_axios.defaults.headers.common['validationhash'] = hash;
        // Check your Header
        const response = await common_axios.post(urlHistoryDepositTon);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const CheckBuyBox = async (amountDeposit,token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlCheckBuyBox,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlCheckBuyBox,{
            amountDeposit: amountDeposit
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const BuyBoxWithTon = async (amountDeposit,comment, seqno, token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlBuyBoxWithTon,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlBuyBoxWithTon,{
            amountDeposit: amountDeposit,
            comment: comment,
            seqno: seqno
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const GetHistoryBuyBox = async (token) => {

    try {
        var common_axios = axios.create({
            baseURL: urlGetHistoryBuyBox,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // common_axios.defaults.headers.common['validationhash'] = hash;
        // Check your Header
        const response = await common_axios.post(urlGetHistoryBuyBox);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const GetBalanceEMRS = async (email, addressWallet) => {

    return axios.post(urlGetBalanceEMRS,
        {
            addressWallet: addressWallet,
            email: email,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });

}

const GetNumberBox = async (addressWallet, email) => {

    return axios.post(urlGetNumberBox,
        {
            addressWallet: addressWallet,
            email: email,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });

}

const GetNFTFromAddress = async (addressWallet, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetNFTFromAddress,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetNFTFromAddress,{
            addressWallet: addressWallet,
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }

}

const CreatePaymentBuyBox = async (addressWallet, email, numberBox) => {
    return axios.post(urlCreatePaymentBuyBox,
        {
            addressWallet: addressWallet,
            email: email,
            numberBox: numberBox
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}


const OpenBoxWithWeb = async (addressWallet, email) => {
    return axios.post(urlOpenBoxWithWeb,
        {
            addressWallet: addressWallet,
            email: email,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetRemainingBox = async (addressWallet, email) => {
    return axios.post(urlGetRemainingBox,
        {
            addressWallet: addressWallet,
            email: email,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetDeviceId = async (addressWallet, email, token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlGetDeviceId,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
        });
         // Set default headers to common_axios ( as Instance )
         common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlGetDeviceId, {
            addressWallet: addressWallet,
            email: email,
        },);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const ResetDeviceId = async (addressWallet, email,token) => {
    try {
        var common_axios = axios.create({
            baseURL: urlResetDeviceId,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Set default headers to common_axios ( as Instance )
        common_axios.defaults.headers.common['Authorization'] = token;
        // Check your Header
        const response = await common_axios.post(urlResetDeviceId,{
            addressWallet: addressWallet,
            email: email,
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const DepositToken = async (addressWallet, email, amount, createdAt, transactionHash) => {
    return axios.post(urlDepositToken,
        {
            addressWallet: addressWallet,
            email: email,
            amount: amount,
            createdAt: createdAt,
            transactionHash: transactionHash
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const WithdrawToken = async (addressWallet, email, totalClaimReceive, amountWithdraw, feeClaim, rateFeeWithdraw, feeChargable) => {
    return axios.post(urlWithdrawToken,
        {
            addressWallet: addressWallet,
            email: email
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetInfoCarDetail = async (tokenId, carId) => {
    return axios.post(urlGetInfoCarDetail,
        {
            tokenId: tokenId,
            carId: carId,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const ResetPassword = async (addressWallet, email) => {
    try {
        var common_axios = axios.create({
            baseURL: urlResetPassword,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Check your Header
        const response = await common_axios.post(urlResetPassword, {
            addressWallet: addressWallet,
            email: email,
        },);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const ChangePasswordForget = async (addressWallet, email, password, pincode) => {
    try {
        var common_axios = axios.create({
            baseURL: urlChangePasswordForget,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // Check your Header
        const response = await common_axios.post(urlChangePasswordForget, {
            addressWallet: addressWallet,
            email: email,
            password: password,
            pincode: pincode
        },);
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

const SellNFT = async (addressWallet, email, itemId, tokenId, priceToSell) => {
    return axios.post(urlSellNFT,
        {
            addressWallet: addressWallet,
            email: email,
            itemId: itemId,
            tokenId: tokenId,
            priceToSell: priceToSell
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetNFTSelling = async (addressWallet, email, tokenIdArray) => {
    return axios.post(urlGetNFTSelling,
        {
            addressWallet: addressWallet,
            email: email,
            tokenIdArray: tokenIdArray
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const CancelNFT = async (addressWallet, email, tokenId, itemId) => {
    return axios.post(urlCancelNFT,
        {
            addressWallet: addressWallet,
            email: email,
            tokenId: tokenId,
            itemId: itemId
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetDataMarket = async (rarity, carId, priceMin, priceMax, sort) => {
    return axios.post(urlGetDataMarket,
        {
            rarity: rarity,
            carId: carId,
            priceMin: priceMin,
            priceMax: priceMax,
            sort: sort
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const BuyNFT = async (seller, addressWallet, email, tokenId, itemId) => {
    return axios.post(urlBuyNFT,
        {
            seller: seller,
            addressWallet: addressWallet,
            email: email,
            tokenId: tokenId,
            itemId: itemId
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetDefautCarInGame = async (addressWallet, email) => {

    return axios.post(urlGetDefautCarInGame,
        {
            addressWallet: addressWallet,
            email: email
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const SortDataMarket = async (sort) => {
    return axios.post(urlSortDataMarket,
        {
            sort: sort,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetNFTPlaying = async (tokenIdArray) => {
    return axios.post(urlGetNFTPlaying,
        {
            tokenIdArray: tokenIdArray
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const InviteUser = async (addressWallet, userAddressWallet) => {
    return axios.post(urlInviteUser,
        {
            addressWallet: addressWallet,
            userAddressWallet: userAddressWallet
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const AcceptInvite = async (addressWallet, sponsor) => {
    return axios.post(urlAcceptInvite,
        {
            addressWallet: addressWallet,
            sponsor: sponsor
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetListReferral = async (addressWallet) => {
    return axios.post(urlGetListReferral,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetListHistoryOfWhoInvite = async (addressWallet) => {
    return axios.post(urlGetListHistoryOfWhoInvite,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const LoadReferralToDatabase = async (addressWallet) => {
    return axios.post(urlLoadReferralToDatabase,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const CreateAccountWithRef = async (addressWallet, email, password, sponsor) => {
    return axios.post(urlCreateAccountWithRef,
        {
            addressWallet: addressWallet,
            email: email,
            password: password,
            sponsor: sponsor
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetSponsor = async (addressWallet) => {
    return axios.post(urlGetSponsor,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const LoadBuyBoxUnlimit = async (addressWallet) => {
    return axios.post(urlLoadBuyBoxUnlimit,
        {
            addressWallet: addressWallet,
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}

const GetNumberUnbox = async (addressWallet, email) => {
    return axios.post(urlGetNumberUnbox,
        {
            addressWallet: addressWallet,
            email: email
        },
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
        }
    ).then(response => response.data
    ).then(function (data) {
        return data;
    });
}