import React, { useState, useEffect, useCallback } from "react";
import { FiPlus, FiMinus, FiArrowLeft, FiX } from "react-icons/fi";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Detail.css';

import { TonConnectButton, useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';

import axios from 'axios';
import { beginCell } from '@ton/ton';


import { cars } from '../../../assets/images/index';
import EpicBox from '../../../assets/images/box/box-save2.png';

import { useNavigate } from "react-router-dom";
import { useToast } from 'rc-toastr';

import NumberFormat from 'react-number-format';

import Countdown from "react-countdown";

import { useTon } from '../../../context/TONContext';


import { 
    checkExistAccountWithAddress, 
    GetHistoryBuyBox, 
    CheckBuyBox,
    BuyBoxWithTon
} from '../../../api/api';

import MRSTOKEN from './../abi/MRSToken.json';
import RegisterBuyBoxABI from './../abi/registerBuyBox.json';
import UnlimitBuyBoxABI from './../abi/UnlimitBuyBox.json';

const LoadingPage = ({ content }) => {
    return (
        <div className="loading-wrapper loading-Openbox">
            <div className="loading-container">
                <p className="mb-0">Please wait</p>
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                {
                    content ?
                        <span className="please-wait-text">{content}</span>
                        :
                        ''
                }
            </div>
        </div>
    )
}

const RacerBoxDetail = ({ web3, address, usdPrice, balanceMRS, getBalanceTokenOfAddress, balanceBNB, bnbUsdt }) => {

    const history = useNavigate();
    const { toast } = useToast();
    const timeRemain = 1644888600000;
    //Date and time (GMT): Tuesday, February 15, 2022 1:30:00 AM
    //Date and time (your time zone): Tuesday, February 15, 2022 8:30:00 AM GMT+07:00
    const TonApiKey = process.env.REACT_APP_TON_API_KEY;

    const accountData = localStorage.getItem('myAccount') && JSON.parse(localStorage.getItem('myAccount'));
    const token = localStorage.getItem("token") && localStorage.getItem("token").replace(/"/g, '');
    const hash = localStorage.getItem("hash") && localStorage.getItem("hash").replace(/"/g, '');

    const { tonBalance, setTonBalance } = useTon();
    const userFriendlyAddress = useTonAddress();
    const [tonConnectUI, setOptions] = useTonConnectUI();



    const [quantily, setCount] = useState(0);
    const [price, setPrice] = useState(0);
    const [checkDisable, setCheckDisable] = useState(false);
    const [checkDisablePlus, setCheckDisablePlus] = useState(false);
    const [showCheckOut, setShowCheckOut] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [amountDeposit, setAmountDeposit] = useState(0);


    const defaultPrice = 2;

    const [eMRSBalance, setEMRSBalance] = useState(0);


    //const [buyBoxStageText, setBuyBoxStageText] = useState('APPROVE TO BUY');
    const [buyBoxStageText, setBuyBoxStageText] = useState('BUY RACER BOX');
    const [disableBuyBoxStage, setDisableBuyBoxStage] = useState(false);

    const [numberOfRemainBox, setNumberOfRemainBox] = useState(100);
    const [limitBox, setLimitBox] = useState(3000);    
    const [totalBoxUserBuy, setTotalBoxUserBuy] = useState(100); 
    const [priceUSD, setpriceUSD] = useState(0);    


    const handleClose = () => setShowCheckOut(false);

    function handlePlusClick() {
        setCount(quantily + 1);
        const pricePlus = quantily * defaultPrice
        if (quantily >= 10) {
            setCount(1);
            setAmountDeposit(pricePlus);
        } else {
            setAmountDeposit(pricePlus);
        }

    }

    function handleMinusClick() {
        if (quantily === 0) {
            setCount(0);
            setAmountDeposit(0);
        } else {
            setCount(quantily - 1);
            const pricePlus = quantily * defaultPrice
            setAmountDeposit(pricePlus);
        }
    }

    const handleConfirmCheckOut = async () => {
        //  setOnLoading(true);
        if (address !== '') {
            setOnLoading(true);
            const myAccount = await JSON.parse(localStorage.getItem('myAccount'));
            if (myAccount != null) {

            }
        }
    }

    useEffect(() => {
        if (quantily === 0) {
            setCheckDisable(true);
        } else if (quantily === 10) {
            setCheckDisablePlus(true);
        } else {
            setCheckDisable(false);
            setCheckDisablePlus(false);
        }



    }, [address, quantily]);

    const goToRacerBox = () => {
        history('/racer-box')
    }

    const validateCheckOut = () => {
        const amountTon = quantily * defaultPrice;
        if (amountTon > tonBalance) {
            toast.warning("You don't have enough TON for this payment!")
            return false;
        } else if (quantily < 1) {
            toast.warning("You must buy at least 1 box!")
            return false;
        }
        return true;
    }

    const handleCheckOut = async () => {
        const myAccount = await JSON.parse(localStorage.getItem('myAccount'));
        if (myAccount != null) {
            const { email, addressWallet } = myAccount;
            if (addressWallet !== '' && email !== '') {
                const valid = await validateCheckOut();
                if (valid) {
                    setShowModal(true);
                }
            }
        }
    }

    const handleUnlimitBuyBox = async () => {
        const valid = validateCheckOut();
        if (valid) {
            if (userFriendlyAddress !== '' && quantily > 0) {
                setOnLoading(true);
                setDisableBuyBoxStage(true);
                console.log(quantily)
                const amountTon = quantily * defaultPrice;
                const amountDepositTonInput = (amountTon * 10 ** 9);
    
                var checkValidateBuyBox = await CheckBuyBox(amountDepositTonInput, token);
                if(checkValidateBuyBox.status.code === 200){
    
                    const currentDate = new Date();
                    const currentDateTimestamp = (currentDate.setSeconds(currentDate.getSeconds())) / 1000;
    
                    const comment = "BB " + amountTon + " TON :" + String(parseInt(currentDateTimestamp));
    
                    //Lấy cho a cái số seqno trước khi sendTransaction
                    //`https://tonapi.io/v2/wallet/${user.addressWallet}/seqno`
    
                    const config_addressBalance = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: `https://tonapi.io/v2/wallet/${userFriendlyAddress}/seqno`,
                        headers: {
                            'accept': 'application/json',
                            'Authorization': `Bearer ${TonApiKey}`
                        }
                    };
                    const response = await axios.request(config_addressBalance);
                    const data = typeof response.data == 'undefined' ? null : response.data
                    SendTonTransaction(data.seqno, comment, amountDepositTonInput);
                } else{
                    toast.warning(checkValidateBuyBox.status.message);
                    setOnLoading(false);
                    setDisableBuyBoxStage(false);
                }
            }
        }
    }

    const SendTonTransaction = async (seqno, comment, amountDepositTonInput) => {
        
        const body = beginCell()
            .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
            .storeStringTail(comment) // write our text comment
            .endCell();

        try {
            const transaction = {
                validUntil: Math.floor(Date.now() / 1000) + 360,
                messages: [
                    {
                        address: process.env.REACT_APP_DEPOSIT,
                        amount: String(amountDepositTonInput),
                        payload: body.toBoc().toString("base64"),
                    }
                ]
            }
            const send = await tonConnectUI.sendTransaction(transaction);
            if (send && String(send.boc).length > 0) {
                const buyBox = await BuyBoxWithTon(amountDepositTonInput, comment, seqno, token);
                if(buyBox.status.code === 200) {
                    history('/racer-box');
                    setOnLoading(false);
                    setDisableBuyBoxStage(false);
                } else{
                    toast.warning(buyBox.status.message);
                    setOnLoading(false);
                    setDisableBuyBoxStage(false);
                }
            } 

        } catch (error) {
            console.log(error);
            setOnLoading(false);
            setDisableBuyBoxStage(false);
        }
    }

    const loadHistoryListInfo = async () => {
        if (token) {
            setOnLoading(true);
            const historyData = await GetHistoryBuyBox(token);
            if (historyData.status.code === 200) {
                setLimitBox(historyData.data.availableBox);
                setNumberOfRemainBox(historyData.data.remainBox);
                setTotalBoxUserBuy(historyData.data.totalBoxUserBuy);
                setOnLoading(false);
            } else {
                setOnLoading(false);
            }

            const config_addressBalanceUsdt = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `https://tonapi.io/v2/rates?tokens=ton&currencies=usd`,
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${TonApiKey}`
                }
            };

            try {
                const response = await axios.request(config_addressBalanceUsdt);
                const data = typeof response.data == 'undefined' ? null : response.data;
                if (data != null) {
                    const ratesArray = Object.entries(data.rates);
                    setpriceUSD(ratesArray[0][1].prices.USD);
                } else{
                    setpriceUSD(0);
                }
            } catch (error) {
                
            }
        }
    }

    const fetchDataTonBalance = async () => {
        const config_addressBalance = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://tonapi.io/v2/blockchain/accounts/${userFriendlyAddress}`,
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${TonApiKey}`
            }
        };

        try {
            const response = await axios.request(config_addressBalance);
            const data = typeof response.data == 'undefined' ? null : response.data;
            if (data != null) {
                const balance = (data.balance / 10 ** 9).toFixed(5);
                setTonBalance(balance);
                localStorage.setItem('myTonBalance', balance);
            } else {
                }
        } catch (error) { }
    };

    useEffect(() => {

        loadHistoryListInfo();
        fetchDataTonBalance();

    }, []);

    return (
        <>
            <section className="racerbox-wrapper">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <a className="back-page" onClick={() => goToRacerBox()}>
                                    <FiArrowLeft />
                                    <span>Go Back</span>
                                </a>
                                <div className="reacerBox-title">
                                    RACER BOX
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="RacerBoxDetail">
                                <div className="RacerBoxDetailLeft text-center">
                                    <div className="box-img">
                                        <img src={EpicBox} alt="" />
                                    </div>
                                    <div className="box-remaining">
                                        Remaining boxes: <span>{numberOfRemainBox}</span>
                                    </div>
                                    {/* <div className="box-eMrs">
                                        <span>Your eMRS balance: </span>
                                        <NumberFormat type="text" value={parseFloat(eMRSBalance).toFixed(3)} displayType={'text'} thousandSeparator={true} suffix={' eMRS'}
                                            renderText={(value, props) => <span className="text-primary"><b>{value}</b></span>} />
                                    </div> */}
                                    {/* <div className="box-eMrs">
                                        <span>Your MRS balance: </span>
                                        <NumberFormat type="text" value={parseFloat(balanceMRS).toFixed(2)} displayType={'text'} thousandSeparator={true} suffix={' MRS'}
                                            renderText={(value, props) => <span className="text-primary"><b>{value}</b></span>} />
                                    </div> */}
                                    <div className="box-eMrs">
                                        <span>Your TON balance: </span>
                                        <NumberFormat type="text" value={parseFloat(tonBalance).toFixed(4)} displayType={'text'} thousandSeparator={true} suffix={' TON'}
                                            renderText={(value, props) => <span className="text-primary"><b>{value}</b></span>} />
                                    </div>
                                    {/* <div className="box-price">
                                        <h3>{price} BNB</h3>
                                        <span>( ${(price * usdPrice).toFixed(2)} )</span>
                                    </div> */}
                                    <div className="box-price">
                                        <h3>{(defaultPrice * quantily).toFixed(1)} TON</h3>
                                        <span>( ${(2 * quantily * priceUSD).toFixed(2)} )</span>
                                    </div>
                                    <div className="box-quantily">
                                        <button className="btn-minus btn" onClick={handleMinusClick} disabled={checkDisable ? true : false}>
                                            <FiMinus size={24} />
                                        </button>
                                        <span className="box-quantilyNumber">{quantily}</span>
                                        <button className="btn-plus btn" onClick={handlePlusClick} disabled={checkDisablePlus ? true : false}>
                                            <FiPlus size={24} />
                                        </button>
                                    </div>
                                    {/* <button className="btn btn-buyRacerBox" onClick={handleOpen}>BUY RACER BOX</button> */}
                                    {/* <button className="btn btn-buyRacerBox" onClick={handleBuyBoxStage} disabled={disableBuyBoxStage}>{buyBoxStageText}</button> */}
                                    <button className="btn btn-buyRacerBox" onClick={handleUnlimitBuyBox} disabled={disableBuyBoxStage}>{buyBoxStageText}</button>
                                    <div className="state1">
                                        <span>Stage 4:</span>
                                        <Countdown
                                            date={timeRemain}
                                            renderer={({ days, hours, minutes, seconds, completed }) => {
                                                var day, hour, minute, second;
                                                String(days).length === 1 ? day = '0' + days : day = days
                                                String(hours).length === 1 ? hour = '0' + hours : hour = hours
                                                String(minutes).length === 1 ? minute = '0' + minutes : minute = minutes
                                                String(seconds).length === 1 ? second = '0' + seconds : second = seconds
                                                if (completed) {
                                                    // Render a completed state
                                                    return (
                                                        <h3 className="text-primary">00D 00H 00M</h3>
                                                    );
                                                } else {
                                                    // Render a countdown
                                                    return (
                                                        <h3 className="text-primary">{day}D {hour}H {minute}M</h3>
                                                    );
                                                }
                                            }}
                                        />
                                        {/* <span>You can only buy 1 box and box will be automatically transferred to your inventory. Please be patient.</span> */}
                                        <span>At this stage, you can buy unlimited boxes. Maximum 1 wallet address can only buy up to 100 boxes.
                                            You will receive the box in 1-2 hours. Please be patient.</span>
                                    </div>

                                </div>
                                <div className="RacerBoxDetailRight">
                                    <div className="boxDetail-wrap">
                                        <div className="boxDetail-threeCol">
                                            <div className="box-text">
                                                <span>Available</span>
                                                <h3 className="text-primary">{limitBox}</h3>
                                            </div>
                                            <div className="box-text">
                                                <span>Time Remaining</span>
                                                <h3 className="text-primary">Unlimited</h3> 
                                            </div>
                                            <div className="box-text">
                                                <span>Purchased</span>
                                                <h3 className="text-primary">{totalBoxUserBuy ? totalBoxUserBuy : 100 - numberOfRemainBox}/100</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="RacerBox-rate">
                                        <div className="RacerBox-title">
                                            <span>Drop Rate Detail</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span>Common Car</span>
                                                <h5 className="text-primary">60%</h5>
                                            </li>
                                            <li>
                                                <span>Epic Car</span>
                                                <h5 className="text-primary">32%</h5>
                                            </li>
                                            <li>
                                                <span>Lengendary Car</span>
                                                <h5 className="text-primary">8%</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="RacerBox-available">
                                        <div className="RacerBox-title">
                                            <span>Available cars : 45</span>
                                        </div>
                                        <div className="RacerBox-wrap">
                                            {
                                                cars.map((item, index) => {
                                                    return (
                                                        <div className="RacerBox-car" key={index}>
                                                            <img src={item} alt="" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    showCheckOut && (
                        <div className="popUp show">
                            <div className="popUp_Content popUp_Checkout">
                                <FiX className="close-popUp" size={24} onClick={() => { handleClose(!showCheckOut) }} />
                                <h3 className="text-primary">CHECKOUT</h3>
                                <span>You are about to purchase Racer Box</span>
                                <div className="price">
                                    <span>Price</span>
                                    <span>{price} eMRS</span>
                                </div>
                                <div className="text-checkOut">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>Your balance</span>
                                        {/* <span><b>{eMRSBalance} eMRS</b></span> */}
                                        <NumberFormat type="text" value={parseFloat(eMRSBalance).toFixed(3)} displayType={'text'} thousandSeparator={true} suffix={' eMRS'}
                                            renderText={(value, props) => <span><b>{value}</b></span>} />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>Amount</span>
                                        <span><b>{quantily}</b></span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>Total Price</span>
                                        <span><b>{price} eMRS</b></span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between btn-wrapCheckOut">
                                    <button className="btn btn-cancel" onClick={() => { handleClose(!showCheckOut) }}>Cancel</button>
                                    <button className="btn btn-confirmCheckOut" onClick={() => { handleCheckOut() }}>Checkout</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    showModal && (
                        <div className="popUp show">
                            <div className="popUp_Content popUp_Checkout">
                                <FiX className="close-popUp" size={24} onClick={() => { setShowModal(!showModal) }} />
                                <span>You are about buy RacerBox</span>
                                <div className="d-flex align-items-center justify-content-between btn-wrapCheckOut">
                                    <button className="btn btn-cancel" onClick={() => { setShowModal(!showModal) }}>NO</button>
                                    <button className="btn btn-confirmCheckOut" onClick={() => { handleConfirmCheckOut() }}>YES</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    onLoading && (
                        <LoadingPage />
                    )
                }
            </section>
        </>
    );
}

export default RacerBoxDetail;
