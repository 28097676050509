import React, { useState } from 'react';
import { useTonAddress } from '@tonconnect/ui-react';

import './dashboard.css';

import HeaderDashBoard from '../../components/DashBoard/Header';
import Footer from '../../components/Footer/Footer';

import {
  getPrice, 
  GetMRSBalanceFromAddress, 
  GetBNBBalanceFromAddress
} from './../../api/api';

const DashBoardPage = ({children}) => {

  const [address, setAddress] = useState("");
  const [balanceMRS, setBalanceMRS] = useState(0);
  const [balanceBNB, setBalanceBNB] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [bnbUsdt, setBnbUsdt] = useState(0);

  const userFriendlyAddress = useTonAddress();


  const onConnect = async (address) => {
    setAddress()
    getBalanceTokenOfAddress(address);
    getBalanceOfAddress(address);
    getPriceOfMetaracers();
  };


  const getBalanceTokenOfAddress = async (address) => {
    if (address !== '') {
      GetMRSBalanceFromAddress(address).then((balance) => {
        if (balance.isSuccess) {
          const bal = parseFloat(balance.MRSBalance) / 10 ** 18;
          setBalanceMRS(bal);
        }
      })
    }
  }

  const getBalanceOfAddress = async (address) => {
    if (address !== '') {
      GetBNBBalanceFromAddress(address).then((result) => {
        if (result.isSuccess) {
          const bal = isNaN(parseFloat(result.BNBBalance)) ? 0 : (parseFloat(result.BNBBalance) / 10 ** 18);
          setBalanceBNB(bal);
        }
      })
    }
  }

  const getPriceOfMetaracers = async (address) => {
    if (address !== '') {
      getPrice().then((result) => {        
        setUsdPrice(result.usdPrice);
        setBnbPrice(result.bnbPrice);
        setBnbUsdt(result.bnbUsdt);
      })
    }
  }


  return (
      <div className="layout-wrapper">
        <HeaderDashBoard />
          <div className="layout-main">{children}</div>
          <Footer className="footer-dashboard" />
      </div>
  );

}

export default DashBoardPage;
