import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useToast } from 'rc-toastr';

import { FiBook, FiArrowRight, FiX, FiCopy } from "react-icons/fi";
import axios from 'axios';
// Import Components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/BackToTop/BackToTop";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';

// Import Index styles
import './Index.css';

// Import Aos
import AOS from 'aos';
import "aos/dist/aos.css";

import { FiLinkedin, FiBookmark, FiNavigation } from "react-icons/fi";

// Import Images
import images, { cars } from '../../assets/images/index';
import { homes } from "../../assets/images/index";
import { games_play } from "../../assets/images/index";
import { partners } from "../../assets/images/index";
import { teams_img } from "../../assets/images/index";
import { advisors } from "../../assets/images/index";

//partner
import Develop_bg from '../../assets/images/home/develop-bg.png';
import FooterBg from '../../assets/images/home/footer.png';
import family from '../../assets/images/partner/logo-8k.png';
import Brayang from '../../assets/images/partner/logobrayangstudio.png';
import Certik from '../../assets/images/home/certik-logo.svg';

import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
SwiperCore.use([Navigation, Pagination, EffectFade]);

const IndexPage = ({ onConnect, resetApp, address }) => {


	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const roadMap_prevRef = useRef(null);
	const roadMap_nextRef = useRef(null);
	const nft_prevRef = useRef(null);
	const nft_nextRef = useRef(null);
	const team_prevRef = useRef(null);
	const team_nextRef = useRef(null);

	const [lightboxOpen, setlightboxOpen] = useState(false);
	const [showConstract, setConstract] = useState(false);

	const FormWithToasts = ({data}) => {
		const { toast } = useToast();

		const copyToClipBoard = async copyMe => {
			try {
				await navigator.clipboard.writeText(copyMe);
				toast.success('Copied Successfully');
			} catch (err) {
				toast.warning('Failed to copy!');
			}
		};
		return (
			<FiCopy className="copy" onClick={() => copyToClipBoard(data)} />
		)
	};

	// Gameplay content
	const gamePlay = [
		{
			img: games_play.SoloRacer,
			icon: games_play.PvEIcon,
			className: 'item-battle',
			name: 'PvE Mode',
			desc: 'Compete with campaign for the PvE rewards.'
		},
		{
			img: games_play.BattleRacer,
			icon: games_play.PvPIcon,
			className: 'item-battle',
			name: 'PvP Mode',
			desc: 'Compete with other players for the top rewards.'
		},
		{
			img: games_play.EventRacer,
			icon: games_play.EventIcon,
			className: 'item-death',
			name: 'Events',
			desc: 'Every month, the first place winner receives attractive rewards.'
		},
		{
			img: games_play.MarketPlace,
			icon: games_play.MarketPlaceIcon,
			className: 'item-super',
			name: 'Marketplace',
			desc: 'Buy and Sell in-game items.'
		}
	];
	// Roadmap
	const roadMap = [
		{
			time: 'Q2 2023',
			info: [
				'Discuss drafting content, come up with ideas', 'Refer and experience similar products', 'Sift through multiple ideas to select a few that work best', 'Concept development and testing', 'Financial plan analysis'
			]
		},
		{
			time: 'Q3 2023',
			info: [
				'Design UI Game and create content game', 'Issue logo for game', 'Issue pitchdesk', 'Issue MRS Token', 'Create and Launch Social media channels'
			]
		},
		{
			time: 'Q1 2024',
			info: [
				'Website launching', 'Open private round', 'Issue guide to play', 'Start Whitelist', 'Release demo app game version', 'Open Test-net version register'
			]
		},
		{
			time: 'Q2 2024',
			info: [
				'Airdrop for early player', 'Release game on test-net', 'Release beta game', 'NFT Marketplace launching', 'Sale NFT Cars box', 'Release PvP Battle'
			]
		},
		{
			time: 'Q3 2024',
			info: [
				'NFT Staking', 'Listing on CoinMarketcap and Coingecko', 'Listing on CEX'
			]
		},
		{
			time: 'Q4 2024',
			info: [
				'Multichain launching', 'Announce game partnership', 'Update new features in game'
			]
		},
		{
			time: 'Q1 2025',
			info: [
				'Implement Metaverse', 'Release demo game in Virtual Reality (VR)', 'Target 200,000 members in game'
			]
		}
	];

	const roadMap_1 = [
		{
			time: 'Q2 2023',
			info: [
				'Discuss drafting content, come up with ideas', 'Refer and experience similar products', 'Sift through multiple ideas to select a few that work best', 'Concept development and testing', 'Financial plan analysis'
			]
		},
		{
			time: 'Q3 2023',
			info: [
				'Design UI Game and create content game', 'Issue logo for game', 'Issue pitchdesk', 'Issue MRS Token', 'Create and Launch Social media channels'
			]
		},
		{
			time: 'Q1 2024',
			info: [
				'Website launching', 'Open private round', 'Issue guide to play', 'Start Whitelist', 'Release demo app game version', 'Open Test-net version register'
			]
		},
		{
			time: 'Q2 2024',
			info: [
				'Airdrop for early player', 'Release game on test-net', 'Release beta game', 'NFT Marketplace launching', 'Sale NFT Cars box', 'Release PvP Battle'
			]
		},
	];

	const roadMap_2 = [
		{
			time: 'Q2 2024',
			info: [
				'Airdrop for early player', 'Release game on test-net', 'Release beta game', 'NFT Marketplace launching', 'Sale NFT Cars box', 'Release PvP Battle'
			]
		},
		{
			time: 'Q3 2024',
			info: [
				'NFT Staking', 'Listing on CoinMarketcap and Coingecko', 'Listing on CEX'
			]
		},
		{
			time: 'Q4 2024',
			info: [
				'Multichain launching', 'Announce game partnership', 'Update new features in game'
			]
		},
		{
			time: 'Q1 2025',
			info: [
				'Implement Metaverse', 'Release demo game in Virtual Reality (VR)', 'Target 200,000 members in game'
			]
		}
	]
	// Partners
	const partnersData = [
		partners.Bsc,
		partners.AU21,
		partners.Onebit,
		partners.SGNCapital,
		partners.BDVentures,
		partners.KingdomGame,
		partners.CoinLab,
		partners.GCrypto,
		partners.ESVentures,
		partners.DIF,
		partners.Lotus,
		partners.MGVentures,
		partners.Nextz,
		partners.JLaunchpad,
		partners.AlphaMoon,
		partners.Lambo,
		partners.VNDC,
		partners.FIM,
		partners.Halfen,
		partners.Coincu,
		partners.Goldenshovel,
		partners.LFG,
		partners.WildCat,
		partners.AKA,
		partners.BIC,
		partners.FVentures,
		partners.Cato,
		partners.Naut,
		partners.Jadelab,
		partners.Moniwar,
		partners.BAS,
		partners.MCV,
		partners.DareNFT,
		partners.Dreamboat,
		partners.Daku,
		partners.CRVN,
		partners.CryptoLeakVN,
		partners.Traveler,
		partners.GCafe,
		partners.Legion,
		partners.Senpark,
		partners.BTCMonday,
		partners.KVVentures,
		partners.RocketOne,
		partners.MIFU,
		partners.Infinity,
		partners.MHVentures,
		partners.Vespertine
	]
	// Teams
	const teams = [
		{
			img: teams_img.CoreTeam_HuyLe,
			name: 'Huy Le',
			office: 'CEO',
			linkedin: 'https://www.linkedin.com/in/huy-l%C3%AA-343134154'
		},
		{
			img: teams_img.CoreTeam_KhoaCao,
			name: 'Khoa Cao',
			office: 'Game Leader',
			linkedin: 'https://www.linkedin.com/in/khoa-cao-3ba6a478/'
		},
		{
			img: teams_img.CoreTeam_Nhile,
			name: 'Nhi Le',
			office: 'Unity Game Developer',
			linkedin: 'https://www.linkedin.com/in/nhi-l%C3%AA-133172224/'
		},
		{
			img: teams_img.CoreTeam_PhuongNguyen,
			name: 'Phuong Nguyen',
			office: 'Unity Game Developer',
			linkedin: 'https://www.linkedin.com/in/thanh-ph%C6%B0%C6%A1ng-nguy%E1%BB%85n-b5916b224/'
		},
		{
			img: teams_img.CoreTeam_KhanhNguyen,
			name: 'Khanh Nguyen',
			office: 'Art Designer',
			linkedin: 'https://www.linkedin.com/in/khanh-nguyen-19a638224/'
		},
		{
			img: teams_img.CoreTeam_HaoNguyen,
			name: 'Nhat Hao',
			office: 'Frontend Developer',
			linkedin: 'https://www.linkedin.com/in/nguyen-nhat-hao-05794a167'
		},
		{
			img: teams_img.CoreTeam_DungLe,
			name: 'Dung Le',
			office: 'Blockchain Developer',
			linkedin: 'https://www.linkedin.com/in/tu%E1%BA%A5n-d%C5%A9ng-l%C3%AA-5bb8391a0'
		},
		{
			img: teams_img.CoreTeam_KhoaNguyen,
			name: 'Khoa Nguyen',
			office: 'CMO',
			linkedin: 'https://www.linkedin.com/in/khoa-nguy%E1%BB%85n-37b259223'
		},
		{
			img: teams_img.CoreTeam_TienDung,
			name: 'Tien Dung',
			office: 'Global Lead Marketing',
			linkedin: 'https://www.linkedin.com/in/edwarditmo07'
		},
		{
			img: teams_img.CoreTeam_CamCo,
			name: 'Co Ha',
			office: 'Project Marketing',
			linkedin: 'https://www.linkedin.com/in/c%C6%A1-h%C3%A0-007068216'
		}
	];
	// Advisor
	const advisor = [
		{
			img: advisors.Advisor1,
			name: 'Mr. Scofield',
			office: 'Co-founder of BSCStation',
		},
		{
			img: advisors.Advisor2,
			name: 'Mr. Tony',
			office: 'Co-founder of Onebit Ventures',
		},
		{
			img: advisors.Advisor3,
			name: 'Mr. Teddy',
			office: 'Co-founder of DareNFT',
		},
		{
			img: advisors.Advisor4,
			name: 'Mr. Larry',
			office: 'Founder of Basics Capital',
		}
	];

	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);


	return (
		<div className="App">
			<Header />
			<main className="fullpage">
				{/* About section  */}
				<section className="section-full about-section" id="about-section" style={{ backgroundImage: `url(${homes.BannerHome})` }} >
					<div className="box-section">
						<div className="content-about">
							<div className="box-info">
								<div className="box-title-racer">
									<div className="title-racer animated">
										Meta
										<br />
										<span>Racers</span>
									</div>
								</div>
								<div className="desc-racers" data-aos="fade-up" data-aos-delay="50">
									<p>MetaRacers is a blockchain-based arcade-style racing game in which you race against other players from all over the world and earn money for your efforts.</p>
									<p>MetaRacers: The Exhilarating Racing Game Coming to Ton Blockchain
Buckle up, gamers and crypto enthusiasts! MetaRacers is revving up to be the hottest new racing game on the Ton Blockchain. Get ready for an adrenaline-pumping experience like no other.
</p>
								</div>
								<div className="box-download">
									<div className="item" data-aos="fade-up" data-aos-delay="200">
										<div className="box-btn-main">
											<a href="https://play.google.com/store/apps/details?id=com.by.CryptoRacers&hl=en&gl=US" className="btn-download">
												<img src={homes.BtnAndroid} alt="" />
											</a>
										</div>
									</div>
									<div className="item" data-aos="fade-up" data-aos-delay="200">
										<div className="box-btn-main">
											<a href="https://drive.google.com/drive/folders/1yPzYnpGH_HmikbERDpL7kK9KxRj6WSdd?fbclid=IwAR1QWGhDQjUKPjQ9v3N_Y3J1U1Z6kxqMmMDHPG8E-Cf8FBUBbPUi_ulHgQ4" className="btn-download">
												<img src={homes.BtnIos} alt="" />
											</a>
										</div>
									</div>
								</div>
								{/* <div className="box-launchpad">
									<a href="https://racestarter.finance/" target="_blank" className="btn-launchpad btn-main btn">
										Lanchpad RaceStarter <FiNavigation />
									</a>
								</div> */}
								
								{/* <img className="car_home" src={homes.CarBanner} alt="" width="100%" /> */}
							</div>
						</div>
						{/* <div className="box-img-about">
							<div className='btn-demo-wrap' style={{ backgroundImage: `url(${homes.BtnBg})` }}>
								<a href="https://drive.google.com/drive/folders/1yPzYnpGH_HmikbERDpL7kK9KxRj6WSdd?fbclid=IwAR1QWGhDQjUKPjQ9v3N_Y3J1U1Z6kxqMmMDHPG8E-Cf8FBUBbPUi_ulHgQ4" rel="noreferrer" target="_blank">Play TestNet</a>
							</div>
						</div> */}
						<div className="box-line">
							<img src={homes.Line_Home} alt="" />
							<a href="https://drive.google.com/drive/folders/1yPzYnpGH_HmikbERDpL7kK9KxRj6WSdd?fbclid=IwAR1QWGhDQjUKPjQ9v3N_Y3J1U1Z6kxqMmMDHPG8E-Cf8FBUBbPUi_ulHgQ4" rel="noreferrer" target="_blank">
								<img className="img-play" src={homes.Play_Home} alt="" />
							</a>
						</div>
					</div>
				</section>
				{/* Video section */}
				<section className="section-full video-section" id="video-section">
					<div className="about_wrap">
						<div className="about_left_wrap">
							<div className="about_us_left">
								<div className="box-title-main">
									<span data-aos="fade-up" data-aos-delay="200">FREE TO PLAY - AVAILABLE NOW!</span>
									<div className="title-main padding-title" data-aos="fade-up" data-aos-delay="200">
										About MetaRacers
									</div>
								</div>
								<p data-aos="fade-up" data-aos-delay="200">MetaRacers is a blockchain racing game with a PvP mode between 2 players directly on the track. When the player wins, the publisher will reward Tokens.</p>
								<p data-aos="fade-up" data-aos-delay="200">The NFT Cars collection will be available in limited quantities and editions, with rarity increasing with level.</p>
								<p data-aos="fade-up" data-aos-delay="200">Besides the Play To Earn element, the game also offers a pleasant gaming experience with high-end visuals, creative gameplay, and a competitive PvP system.</p>
								<p data-aos="fade-up" data-aos-delay="200">
									<strong>Let’s play and enjoy the game.</strong>
								</p>
								<div className="box-download box-btn-wrap">
									<div className="item" data-aos="fade-up" data-aos-delay="200">
										<div className="box-btn-main">
											<a href="https://docs.meta-racers.com/" className="btn-icon">
												DOCUMENT
												<FiBook />
											</a>
										</div>
									</div>
									<div className="item" data-aos="fade-up" data-aos-delay="200">
										<div className="box-btn-main">
											<a href="#" className="btn-icon btn-nobackground">
												MARKET PLACE
												<FiArrowRight />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="about_right_wrap">
							<div className="about_us_right">
								<div className="btn-car">
									<a data-aos="fade-up" data-aos-delay="200" href="https://drive.google.com/drive/folders/1yPzYnpGH_HmikbERDpL7kK9KxRj6WSdd?fbclid=IwAR1QWGhDQjUKPjQ9v3N_Y3J1U1Z6kxqMmMDHPG8E-Cf8FBUBbPUi_ulHgQ4" className="btn-car-action">
										<img src={homes.HomeCar1} alt="" />
										<div className="btn-car-content">
											<span>BUY CARS</span>
											{/* <img src={homes.ArrowRight} alt="" /> */}
										</div>
									</a>
									<a data-aos="fade-up" data-aos-delay="200" href="https://drive.google.com/drive/folders/1yPzYnpGH_HmikbERDpL7kK9KxRj6WSdd?fbclid=IwAR1QWGhDQjUKPjQ9v3N_Y3J1U1Z6kxqMmMDHPG8E-Cf8FBUBbPUi_ulHgQ4" className="btn-car-action">
										<img src={homes.HomeCar2} alt="" />
										<div className="btn-car-content">
											<span>GARAGE</span>
											{/* <img src={homes.ArrowRight} alt="" /> */}
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section-full video2-section">
					<div className="box-video">
						<div className="box-video-right">
							<div className="desc-inner">
								<p className="text-uppercase" data-aos="fade-up" data-aos-delay="200" >Meta Racers is built to allow player PLAY-to-EARN with an EASY-to-PLAY gameplay and still provides an addictive experience.</p>
							</div>
						</div>
						<div className="box-video-left">
							<img className="line-video" src={homes.VideoBanner_Line} alt="" />
							<div data-type="youtube" data-id="KU2sSZ_90PY" data-width="800" data-height="600" className="lightbox" data-aos="fade-up" data-aos-delay="200">
								<img src={homes.CarVideoBanner} alt="" />
								<span className="play" onClick={() => setlightboxOpen(!lightboxOpen)}>
									<i className="icon-play"></i>
								</span>
							</div>
							<img className="line2-video"  src={homes.VideoBanner_Line2} alt="" />
						</div>
					</div>
					
				</section>
				{/* Nfts section */}
				<section className="section-full nfts-section padding-section" id="nfts-section">
					{/* <div className="game-background-overlay" style={{ backgroundImage: `url(${homes.NftBanner})` }}></div> */}
					<div className="box-section">
						<div className="box-title-main" data-aos="fade-up">
							<div className="title-main padding-title text-white" data-aos="fade-up" data-aos-delay="100" >
								NFTs Item
							</div>
							<div className="desc-main" data-aos="fade-up" data-aos-delay="200" >
								Earn your NFTs item by playing the game and sell it on the marketplace to make money
							</div>
						</div>
						<div className="box-1700">
							<div className="nfts-slider" data-sequence='500'>
								<Swiper
									slidesPerView={3}
									spaceBetween={20}
									pagination={true}
									navigation={{
										prevEl: nft_prevRef.current,
										nextEl: nft_nextRef.current,
									}}
									onBeforeInit={(swiper) => {
										swiper.params.navigation.prevEl = nft_prevRef.current;
										swiper.params.navigation.nextEl = nft_nextRef.current;
									}}
									breakpoints={{
										"@0.00": {
											"slidesPerView": 1,
											"spaceBetween": 10
										},
										"@0.75": {
											"slidesPerView": 2,
											"spaceBetween": 20
										},
										"@1.00": {
											"slidesPerView": 3,
											"spaceBetween": 30
										},
										"@1.50": {
											"slidesPerView": 5,
											"spaceBetween": 30
										}
									}}
									className="js-nfts-slider"
								>
									{
										cars.map((item, index) => {
											return (
												<SwiperSlide className="item" key={index}>
													<div className="img-nfts" style={{ backgroundImage: `url(${homes.OverlayItem})` }}>
														<div className="img-1">
															<img src={item} alt="" data-aos="fade-up" data-aos-delay="200" />
														</div>
													</div>
												</SwiperSlide>
											)
										})
									}
									<div className="js-pagination">
										<div className="nfts-swiper-pagination swiper-pagination"></div>
									</div>
								</Swiper>
							</div>
						</div>
					</div>
				</section>
				{/* Game Play */}
				<section className="section-full gameplay-section padding-section" id="gameplay-section">
					<div className="box-section">
						<div className="box-title-main" data-aos="fade-up">
							<div className="title-main padding-title">
								Gameplay
							</div>
							<div className="desc-main" data-aos="fade-up">
								<p>Challenge yourself with a wide range of game modes, including PvP, PvE, and Duo, all with including monthly updates and immense rewards</p>
							</div>
						</div>
						<div className="gameplay-slider js-gameplay-slider" data-aos="fade-up" data-aos-delay="200">
							<Swiper
								slidesPerView={1}
								spaceBetween={30}
								pagination={true}
								navigation={{
									prevEl: prevRef.current,
									nextEl: nextRef.current,
								}}
								onBeforeInit={(swiper) => {
									swiper.params.navigation.prevEl = prevRef.current;
									swiper.params.navigation.nextEl = nextRef.current;
								}}
								className="js-gameplay-slider"
							>
								{
									gamePlay.map((item, index) => {
										return (
											<SwiperSlide className={`item ${item.className}`} key={index}>
												<div className="box-content">
													
													<div className="box-right">
														<div className="box-content-right">
															<div className="icon-game">
																<div className="bg-icon-game" style={{ backgroundImage: `url(${homes.BgIconGame})` }}></div>
																<img src={item.icon} alt="" />
															</div>
															<div className="name-game">{item.name}</div>
															<div className="desc-game">
																{item.desc}
															</div>
														</div>
													</div>
													<div className="box-left battle-royal">
														<div className="image img-1">
															<img src={item.img} alt="" />
														</div>
													</div>
												</div>
											</SwiperSlide>
										)
									})
								}
							</Swiper>
						</div>
					</div>
				</section>
				{/* What the coin */}
				<section className="section-full coin-section padding-section bg-section" id="coin-section">
					<div className="coin_bg" style={{ backgroundImage: `url(${homes.GamePlayBg})` }}></div>
					<div className="box-section bg-section">
						<div className="box-title-main">
							<div className="title-main text-white" data-aos="fade-up">
								WHAT IS METARACERS TOKEN (MRS)?
							</div>
							<div className="desc-main" data-aos="fade-up" data-aos-delay="200">
								MRS is our primary in-game currency, which can be obtained by participating in various game modes and other activities.
							</div>
						</div>
						<div className="content-coin box-1700">
							<div className="box box-first">
								<div className="list-item-coin list-1">
									<div className="item-coin first-item-coin" data-aos="fade-up" data-aos-delay="200" style={{ backgroundImage: `url(${homes.FirstBg})` }}>
										<div className="box-left">
											<div className="img-coin box-img-flex">
												<img src={games_play.Icon_Play2earn} alt="" />
											</div>
											<div className="name-coin">Play to earn</div>
										</div>
										<div className="box-right">
											<div className="list-coin">
												<span>Battle Racing</span>
												<span>Ranking Rewards</span>
												<span>Special Events</span>
												<span>PvE</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="box box-middle">
								<div className="arrow-1 arrow-single" data-aos="fade-up" data-aos-delay="200">
									<img src={games_play.Icon2} alt="" />
								</div>
								<div className="logo-coin" data-aos="fade-up" data-aos-delay="200">
									<img src={games_play.RacerCoin} alt="" />
								</div>
								<div className="arrow-2" data-aos="fade-up" data-aos-delay="200">
									<img src={games_play.Icon1} alt="" />
								</div>
							</div>
							<div className="box box-last">
								<div className="list-item-coin list-3">
									<div className="item-coin" data-aos="fade-up" data-aos-delay="300">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.Exchange} alt="" />
											</div>
											<div className="name-coin" style={{ backgroundImage: `url(${games_play.Border_Coin})` }}>Exchange</div>
										</div>
									</div>
									<div className="item-coin" data-aos="fade-up" data-aos-delay="300">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.Trade} alt="" />
											</div>
											<div className="name-coin" style={{ backgroundImage: `url(${games_play.Border_Coin})` }}>Trade</div>
										</div>
									</div>
								</div>
								<div className="list-item-coin list-vertical">
									<div className="item-coin" data-aos="fade-up" data-aos-delay="400">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.Market} alt="" />
											</div>
											<div className="name-coin">Market</div>
										</div>
									</div>
									<div className="item-coin" data-aos="fade-up" data-aos-delay="400">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.Staking} alt="" />
											</div>
											<div className="name-coin">Staking</div>
										</div>
									</div>
									<div className="item-coin" data-aos="fade-up" data-aos-delay="400">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.Evolve} alt="" />
											</div>
											<div className="name-coin">Upgrade Cars</div>
										</div>
									</div>
									<div className="item-coin" data-aos="fade-up" data-aos-delay="500">
										<div className="box-left box-inline ">
											<div className="img-coin box-img-flex">
												<img src={games_play.SummonHero} alt="" />
											</div>
											<div className="name-coin">Metaracers Box</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="coin_line" style={{ backgroundImage: `url(${homes.GamePlay_Line})` }}></div>
				</section>
				{/* Gem section */}
				{/* <section className="section-full coin-section gem-section padding-section" id="gem-section">
					<div className="box-section">
						<div className="box-title-main" data-aos="fade-up" data-aos-delay="200">
							<div className="title-main padding-title">
								What's Thetan Gem?
							</div>
							<div className="desc-main" data-aos="fade-up" data-aos-delay="200">
								Apart from Thetan Coin, Thetan Gem is an exclusive currency that makes players become investors.
							</div>
						</div>
						<div className="content-coin box-1700">
							<div className="box">
								<div className="list-item-coin list-2" data-aos="fade-up" data-aos-delay="200">
									<div className="item-coin item-center">
										<div className="box-center">
											<div className="img-coin box-img-flex">
												<img src={Playtorearn} alt="" />
											</div>
											<div className="name-coin">Free to earn</div>
										</div>
									</div>
									<div className="item-coin item-center" data-aos="fade-up" data-aos-delay="300">
										<div className="box-center">
											<div className="img-coin box-img-flex">
												<img src={Market} alt="" />
											</div>
											<div className="name-coin">Market</div>
										</div>
									</div>
								</div>
							</div>
							<div className="box">
								<div className="list-item-coin list-2">
									<div className="item-coin item-center"  data-aos="fade-up" data-aos-delay="500">
										<div className="box-left">
											<div className="img-coin box-img-flex">
												<img src={Evolve} alt="" />
											</div>
											<div className="name-coin">Evolve Heroes</div>
										</div>
									</div>
									<div className="item-coin item-center"  data-aos="fade-up" data-aos-delay="500">
										<div className="box-left">
											<div className="img-coin box-img-flex">
												<img src={Staking} alt="" />
											</div>
											<div className="name-coin">Staking</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{/* Road map */}
				<section className="section-full roadmap-section padding-section bg-section" id="roadmap-section">
					<div className="roadmap-background-overlay" style={{ backgroundImage: `url(${homes.RoadmapBg})`, backgroundSize: 'cover' }} ></div>
					<div className="box-section">
						<div className="box-title-main">
							<div className="title-main" data-aos="fade-up">
								Roadmap
							</div>
						</div>
						<div className="bg-process">
							<div className="box-process">
								<div className="list-step roadmap-desk">
									<div className="roadmap-desk-bg" style={{ backgroundImage: `url(${homes.RoadmapTimeline})`}}></div>
									<Swiper
										slidesPerView={1}
										spaceBetween={20}
										pagination={true}
										navigation={{
											prevEl: roadMap_prevRef.current,
											nextEl: roadMap_nextRef.current,
										}}
										onBeforeInit={(swiper) => {
											swiper.params.navigation.prevEl = roadMap_prevRef.current;
											swiper.params.navigation.nextEl = roadMap_nextRef.current;
										}}
										className="js-gameplay-slider"
									>
										<SwiperSlide className="item-step step-right">
											<div className="road-map-item-wrap">
												{
													roadMap_1.map((item, index) => {
														return (
															<div className="info-step" data-aos="fade-up" data-aos-delay="200" key={index}>
																<div className="time" style={{ backgroundImage: `url(${homes.RoadmapTitle})` }}>
																	{item.time}
																	<span className="alpha">{item.phase}</span>
																</div>
																<ul>
																	{
																		item.info.map((item_info, index) => {
																			return (
																				<li className="info" key={index}>
																					{item_info}
																				</li>
																			)
																		})
																	}
																</ul>
															</div>
														)
													})
												}
											</div>
										</SwiperSlide>
										<SwiperSlide className="item-step step-right">
											<div className="road-map-item-wrap">
												{
													roadMap_2.map((item, index) => {
														return (
															<div className="info-step" data-aos="fade-up" data-aos-delay="200" key={index}>
																<div className="time" style={{ backgroundImage: `url(${homes.RoadmapTitle})` }}>
																	{item.time}
																	<span className="alpha">{item.phase}</span>
																</div>
																<ul>
																	{
																		item.info.map((item_info, index) => {
																			return (
																				<li className="info" key={index}>
																					{item_info}
																				</li>
																			)
																		})
																	}
																</ul>
															</div>
														)
													})
												}
											</div>
										</SwiperSlide>
									</Swiper>
								</div>
							</div>
							<div className="box-process-mobile box-mobile">
								<div className="list-step">
									{
										roadMap.map((item, index) => (
											<div className="item-step step-right" key={index}>
												<div className="icon-step">
													<img src={games_play.Point} alt="" />
												</div>
												<div className="info-step">
													<div className="time">
														{item.time}
														<span className="alpha">{item.phase}</span>
													</div>
													{
														item.info.map((item_info, index) => {
															return (
																<div className="info" key={index}>
																	{item_info}
																</div>
															)
														})
													}
												</div>
											</div>
										))
									}
								</div>
							</div>
						</div>
					</div>
				</section>
				{/* Team */}
				{/* <section className="section-full team-section padding-section" id="team-section">
					<div className="team-background-overlay" style={{ backgroundImage: `url(${homes.MemPhis})` }} ></div>
					<div className="box-section">
						<div className="box-title-main">
							<div className="title-main" data-aos="fade-up">
								Core Team
							</div>
							<div className="desc-main" data-aos="fade-up" data-aos-delay="200">
								Teams always play an important role in the success of a project.
								<br />We've been working as a team for almost 5 years, and everyone has extensive experience in their respective fields.
							</div>
						</div>
						<div className="box-team-slider">
							<div className="box-1700">
								<div className="team-slider">
									<Swiper
										slidesPerView={4}
										spaceBetween={20}
										pagination={true}
										onBeforeInit={(swiper) => {
											swiper.params.navigation.prevEl = team_prevRef.current;
											swiper.params.navigation.nextEl = team_nextRef.current;
										}}
										breakpoints={{
											"@0.00": {
												"slidesPerView": 1,
												"spaceBetween": 10
											},
											"@0.75": {
												"slidesPerView": 2,
												"spaceBetween": 20
											},
											"@1.00": {
												"slidesPerView": 3,
												"spaceBetween": 40
											},
											"@1.50": {
												"slidesPerView": 4,
												"spaceBetween": 50
											}
										}}
										className="js-team-slider"
									>
										{
											teams.map((item, index) => {
												return (
													<SwiperSlide className="item" key={index}>
														<div className="item-team" data-aos="fade-up" data-aos-delay="100">
															<div className="avatar" style={{ backgroundImage: `url(${homes.TeamBgItem})` }}>
																<img src={item.img} alt="" />
															</div>
															<div className="name">{item.name}</div>
															<div className="position">{item.office}</div>
															<a href={item.linkedin} rel="noreferrer" className="linkedin" target='_blank'>
																<FiLinkedin />
															</a>
														</div>
													</SwiperSlide>
												)
											})
										}
									</Swiper>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{/* Advisor */}
				<section className="section-full advisor-section padding-section" id="team-section">
					{/* <div className="advisor-background-overlay" style={{ backgroundImage: `url(${homes.AdvisorBg})` }} ></div> */}
					<div className="box-section">
						<div className="box-title-main">
							<div className="title-main text-white" data-aos="fade-up">
								Advisor
							</div>
						</div>
						<div className="box-advisor-wrap">
							<div className="box-1700">
								<div className="advisor-slider">
									{
										advisor.map((item, index) => {
											return (
												<div className="item-advisor" key={index} data-aos="fade-up" data-aos-delay="100">
													<div className="avatar" style={{ backgroundImage: `url(${homes.AdvisorBgItem})` }}>
														<img src={item.img} alt="" />
													</div>
													<div className="item-advisor-content">
														<div className="name">{item.name}</div>
														<div className="position">{item.office}</div>
													</div>
												</div>
											)
										})
									}
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* Partner */}
				<section className="section-full partner-section padding-section" id="partner-section">
					<div className="partner-background-overlay" style={{ backgroundImage: `url(${Develop_bg})` }}></div>
					<div className="container-wrap">
						<div className="box-partner">
							<div className="box-title-main">
								<div className="title-main">
									Backers & Partners
								</div>
							</div>
							<div className="list-partner list-partner-tech">
								{
									partnersData.map((partner, index) =>
										<div className="item-partner" key={index} data-aos="fade-up" data-aos-delay="200">
											<img src={partner} alt="" />
										</div>
									)
								}
							</div>
						</div>

						<div className="box-partner">
							<div className="box-title-main">
								<div className="title-main">
									Developer
								</div>
							</div>
							<div className="list-partner list-partner-tech">
								<div className="item-partner" data-aos="fade-up" data-aos-delay="200">
									<img src={Brayang} alt="" />
								</div>
								<div className="item-partner" data-aos="fade-up" data-aos-delay="200">
									<img src={family} alt="" />
								</div>
							</div>
						</div>


						<div className="box-partner">
							<div className="box-title-main">
								<div className="title-main">
									Our tech partner
								</div>
							</div>
							<div className="list-partner list-partner-tech">
								{
									images.map((image, index) => {
										return (
											<div className="item-partner" key={index} data-aos="fade-up" data-aos-delay="200">
												<img src={image} alt="" />
											</div>
										)
									})
								}
							</div>
						</div>

						<div className="box-partner">
							<div className="box-title-main">
								<div className="title-main">
									AUDIT BY
								</div>
							</div>
							<div className="list-partner list-partner-tech">
								<div className="item-partner" data-aos="fade-up" data-aos-delay="200">
									<img src={Certik} alt="" />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
			<ScrollToTop />
			<div className="btn-smartConstractWrapper">
				<button className="btn-smartConstract" onClick={() => setConstract(!showConstract)}>Show Contract Address <FiBookmark /></button>
			</div>
			{
				showConstract && (
					<div className="smartConstractWrapper">
						<div className="smartConstractItem">
							<FiX className="close-Constract" onClick={() => setConstract(!showConstract)} />
							<img src={homes.LogoMeta} alt="" />
							<span>$MRS Contract Address</span>
							<div className="smartConstractItem-content">
								<span className="text-primary">JETTON</span>
								<span>{process.env.REACT_APP_JETTON_ADDRESS}</span>
								<FormWithToasts data={process.env.REACT_APP_JETTON_ADDRESS} />
							</div>
							<div className="smartConstractItem-content">
								<span className="text-primary">BEP20</span>
								<span>0x9e05e646a18bb4caB41aa7992959f019D0aac124</span>
								<FormWithToasts data="0x9e05e646a18bb4caB41aa7992959f019D0aac124" />

							</div>
						</div>
					</div>
				)
			}

			{/* {lightboxOpen && (
				<div className="video-lightbox">
					<ReactImageVideoLightbox
						data={[
							{
								url: "https://www.youtube.com/embed/Dwf1phM5XDk",
								type: "video",
								title: "MetaRacers | Gameplay Demo",
							}
						]}
						startIndex={0}
						showResourceCount={true}
						onCloseCallback={() => setlightboxOpen(!lightboxOpen)}
					/>
				</div>
			)} */}

		</div>
	);
}

export default IndexPage;
