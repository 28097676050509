import React from "react";
import './Footer.css';
import Logo from '../../assets/images/logo-final.png';
import LogoMeta from '../../assets/images/logo_Metacar.png';
import FooterBg from '../../assets/images/home/footer.png';
import { socail } from '../../assets/images';

function Footer({className}){
    return(
        <div>
            <footer className={className ? `footer section-full fp-auto-height ${className}` : "footer section-full fp-auto-height"}>
                <div className="box-section" style={{ backgroundImage: `url(${FooterBg})` }}>
                    <div className="item-footer">
                        <div className="box-title-main">
							<div className="title-main text-white" data-aos="fade-up">
                                Community
							</div>
						</div>
                        <div className="list-social">
                            {/* <li>
                                <a className="box-img-flex" href="https://www.facebook.com/Metaracers" rel="noreferrer">
                                    <img src={socail.Facebook} alt="" />
                                </a>
                            </li> */}
                            <li>
                                <a className="box-img-flex" href="https://twitter.com/metaracersTON" rel="noreferrer" target="_blank">
                                    <img src={socail.Twitter} alt="" />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="https://discord.gg/bRH38jzt" rel="noreferrer" target="_blank">
                                    <img src={socail.Discord} alt="" />
                                </a>
                            </li>
                            <li>
                                <a className="box-img-flex" href="https://t.me/MetaRacersBsc_Global" rel="noreferrer" target="_blank">
                                    <img src={socail.Telegram} alt="" />
                                </a>
                            </li>
                            {/* <li>
                                <a className="box-img-flex" href="mailto:info@meta-racers.com" rel="noreferrer" target="_blank">
                                    <img src={socail.Gmail} alt="" />
                                </a>
                            </li> */}
                            {/* <li>
                                <a className="box-img-flex" href="https://medium.com/@8kfamilycapital" rel="noreferrer" target="_blank">
                                    <img src={socail.Medium} alt="" />
                                </a>
                            </li> */}
                        </div>
                    </div>
                    <div className="item-footer">
                        <div className="logo-footer box-img-flex">
                            <img src={LogoMeta} alt="" />
                        </div>
                        <div className="copyright">Copyright @MetaRacers Game 2024
                        </div>
                    </div>
                   
                </div>
            </footer>
        </div>
    );
}

export default Footer;