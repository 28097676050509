import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


import { 
    FiDatabase,
    FiAlignRight
} from "react-icons/fi";

import { IoIosLink } from "react-icons/io";


import './Header.css';
import LogoMeta from '../../assets/images/logo_Metacar.png';

const Header = ({ onConnect, resetApp, address, balanceMRS, balanceBNB }) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [accountOpen, setAccountOpen] = useState(false);
    const toggleClassName = menuOpen ? 'show' : '';
    const toggleAccountClassName = accountOpen ? 'show' : '';

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.header-section');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    return (
        <div>
            <header className="header header-section fixed">
                <div className="logo-header">
                    <div className="box-img-flex">
                        <img src={LogoMeta} alt="" />
                    </div>
                </div>
                <div className="menu-main" id="menu-main">
                    <ul>
                        <li data-menuanchor="home" className="active">
                            <a href="#about-section">
                                <span>HOME</span>
                            </a>
                        </li>
                        <li data-menuanchor="video">
                            <a href="#video-section">
                                <span>ABOUT US</span>
                            </a>
                        </li>
                        <li data-menuanchor="gameplay">
                            <a href="#gameplay-section">
                                <span>GAMEPLAY</span>
                            </a>
                        </li>
                        <li data-menuanchor="nfts-item">
                            <a href="#nfts-section">
                                <span>NFT</span>
                            </a>
                        </li>
                        <li data-menuanchor="team">
                            <a href="#team-section">
                                <span>TEAM</span>
                            </a>
                        </li>
                        <li data-menuanchor="roadmap">
                            <a href="#roadmap-section">
                                <span>ROADMAP</span>
                            </a>
                        </li>
                        <li data-menuanchor="partner">
                            <a href="#partner-section">
                                <span>PARTNER</span>
                            </a>
                        </li>
                        <li data-menuanchor="" className="button-wallet">
                            <div className="box-btn-main">
                                <Link to="/connect" className="btn-main">
                                    <span> <IoIosLink /> Connect</span>
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
                <a className="btn-menu-mobile" href="/#" onClick={() => setMenuOpen(!menuOpen)}>
                   <FiAlignRight />
                </a>
            </header>
            <div className={`y-mobile-menu ${toggleClassName}`}>
                <div className="m-menu__title">
                    <a href="/#" className="m-menu-close" onClick={() => setMenuOpen(false)}>+</a>
                </div>
                <ul>
                    <li><a href="#about-section">Home</a></li>
                    <li><a href="#video-section">About us</a></li>
                    <li><a href="#gameplay-section">Gameplay</a></li>
                    <li><a href="#nfts-section">Nfts</a></li>
                    <li><a href="#coin-section">Metaracers Coin</a></li>
                    <li><a href="#team-section">Team</a></li>
                    <li><a href="#roadmap-section">Roadmap</a></li>
                    <li><a href="#partner-section">Partner</a></li>
                    <li data-menuanchor="" className="button-wallet">
                        <div className="box-btn-main">
                            <Link to="/market" className="btn-main">
                                <span>Market place</span>
                            </Link>
                        </div>
                    </li>
                    {
                        address === '' ?
                            <li data-menuanchor="" className="button-wallet">
                                <div className="box-btn-main">
                                    <a className="btn-connect-wallet" onClick={() => onConnect()}>
                                        <span>Connect Wallet</span>
                                    </a>
                                </div>
                            </li>
                            :
                            null
                    }

                </ul>
            </div>
            <div className="overlay-menu"></div>
        </div>
    );
}

export default Header;