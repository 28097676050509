import React, { useState, useEffect } from "react";
import { TonConnectButton, useTonAddress, useTonWallet } from '@tonconnect/ui-react';
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiX, FiCheck } from "react-icons/fi";
import { useToast } from 'rc-toastr';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import './Index.css';

import Banner from "../../../assets/images/dashboard/banner_doc.png";
import TonCoin from '../../../assets/images/coin/ton_symbol.svg';

import { 
    checkExistAccountWithAddress, 
    LoginInWeb, 
    CreateNewUser, 
    ResetPassword, 
    ChangePasswordForget 
} from '../../../api/api';

import { useUser } from '../../../context/UserContext';
import { useTon } from '../../../context/TONContext';

const ConnectPage = ({ onConnect }) => {

    const navigate = useNavigate();
    const { toast } = useToast();
    const { user, setUser } = useUser();
    const { tonBalance, setTonBalance } = useTon();

    const goToMarket = () => {
        navigate('/market');
    }

    const userFriendlyAddress = useTonAddress();

    const [showConnect, setShowConnect] = useState(false);
    const [showGameProfile, setShowGameProfile] = useState(false);
    const [showCreateProfile, setShowCreateProfile] = useState(false);
    const [showForgotPass, setShowForgotPass] = useState(false);
    const [checkForgotField, setShowForgotField] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [openLinkSuccess, setOpenLinkSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openLinkError, setOpenLinkError] = useState(false);
    const [onLoading, setOnLoading] = useState(false);

    const [isExist, setIsExist] = useState(false);

    const [disableButtonLink, setDisableButtonLink] = useState(false);
    const [emailInput, setEmailInput] = useState('');
    const [disableButtonResetPass, setDisableButtonResetPass] = useState(false);
    const [disableButtonCreateNewPass, setDisableButtonCreateNewPass] = useState(false);
    const [passwordInput, setPasswordInput] = useState('');

    //forgot
    const [emailForgot, setEmailForgot] = useState('');
    const [newPassWord, setNewPassWord] = useState('');
    const [repasswordInput, setRePasswordInput] = useState('');
    const [codeInput, setCodeInput] = useState('');

    const [disableButtonLinkAccount, setDisableButtonLinkAccount] = useState(false);
    const [disableButtonCreate, setDisableButtonCreate] = useState(false);
    const [emailCreate, setEmailCreate] = useState('')
    const [passwordCreate, setPasswordCreate] = useState('');
    const [rePasswordCreate, setRePasswordCreate] = useState('');

    const LoginWithTon = async () => {
        if (userFriendlyAddress !== '') {
            handleShowConnect();
        } else {
            toast.warning('Please connect your TON Wallet!')
        }
    }

    const TonApiKey = process.env.REACT_APP_TON_API_KEY;

    
    const handleShowConnect = async () => {
        setOnLoading(true);
        const checkExistAccount = await checkExistAccountWithAddress(userFriendlyAddress);
        if (checkExistAccount.isSuccess === false && checkExistAccount.status.code === 400) {
            setIsExist(false);
            setShowConnect(!showConnect);
            setOnLoading(false);
        } else{
            setIsExist(true);
            handleShowProfile();
            setOnLoading(false);
        }
    }

    const handleShowProfile = () => {
        setShowConnect(!showConnect);
        setShowGameProfile(true);
    }

    const handleCreateProfile = () => {
        setShowConnect(!showConnect);
        setShowCreateProfile(true);
    }

    const handleLinkAccount = async () => {
        var validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailInput == null || emailInput == '') {
            toast.warning('Email is required !');
            return false;
        } else if (validEmail.test(String(emailInput.trim()).toLowerCase()) === false) {
            toast.warning('Input email wrong!');
            return false;
        } else{
            if (userFriendlyAddress !== '' && emailInput !== '' && passwordInput !== '') {
                setOnLoading(true);
                setDisableButtonLinkAccount(true);
                const login = await LoginInWeb(emailInput, userFriendlyAddress, passwordInput);
                if (login.isSuccess) {

                    fetchDataTonBalance();

                    const { email, addressWallet, name, eMRSBalanceInGame } = login.data.userInfo;
                    let myAccount = { 
                        email: email, 
                        addressWallet: addressWallet, 
                        name: name, 
                        eMRSBalanceInGame: eMRSBalanceInGame 
                    };

                    setUser(myAccount);
                    localStorage.setItem('myAccount', JSON.stringify(myAccount));
                    localStorage.setItem('token', JSON.stringify(login.data.jwt));
                    localStorage.setItem('hash', JSON.stringify(login.data.dataValid));
                    
                    setShowGameProfile(false);
    
                    setOnLoading(false);
                    setDisableButtonLinkAccount(false);
    
                    navigate('/my-account');
                } else {
                    toast.warning('Link your account fail! Try again.');
                    setOnLoading(false);
                    setDisableButtonLinkAccount(false);
                }
            }
        }
    }

    function validateCreateAccount(emailCreate, passwordCreate, rePasswordCreate) {
        var validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailCreate == null || emailCreate == '') {
            toast.warning('Email is required !');
            return false;
        } else if (validEmail.test(String(emailCreate.trim()).toLowerCase()) === false) {
            toast.warning('Input email wrong !');
            return false;
        } else if (passwordCreate == null || passwordCreate == '') {
            toast.warning('Password is required !');
            return false;
        }
        else if (rePasswordCreate == null || rePasswordCreate == '') {
            toast.warning('Renew-password is required !');
            return false;
        }
        else if (passwordCreate !== rePasswordCreate) {
            toast.warning("Password and Renew-password don't math !");
            return false;
        }
        return true;
    }

    const handleCreateAccount = async () => {
        if (userFriendlyAddress != '') {
            var checkValidate = await validateCreateAccount(emailCreate, passwordCreate, rePasswordCreate);
            if (checkValidate) {
                setDisableButtonCreate(true);
                setOnLoading(true);
                const createUser = await CreateNewUser(emailCreate, userFriendlyAddress, passwordCreate);
                if (createUser.status.code === 200) {

                    fetchDataTonBalance();

                    setOnLoading(false);
                    setDisableButtonCreate(false);
                    setOpenSuccess(false);
                    setShowCreateProfile(!showCreateProfile);

                    const { email, addressWallet, name, eMRSBalanceInGame } = createUser.data.userInfo;
                    let myAccount = { email: email, addressWallet: addressWallet, name: name, eMRSBalanceInGame: eMRSBalanceInGame };
                    localStorage.setItem('myAccount', JSON.stringify(myAccount));
                    localStorage.setItem('token', JSON.stringify(createUser.data.jwt));
                    localStorage.setItem('addressWallet', JSON.stringify(createUser.data.userInfo.addressWallet));
                    localStorage.setItem('hash', JSON.stringify(createUser.data.hash));
                    navigate('/my-account');
                } else {
                    toast.warning(createUser.status.message);
                    setDisableButtonCreate(false);
                    setOnLoading(false);
                }
            }
        } else {
            toast.warning('Please connect to your TON Wallet.');
            setDisableButtonCreate(false);
        }

    }

    const handleShowForgotPass = () => {
        setShowConnect(!showConnect);
        setShowForgotPass(true);
    }

    const handleSendCodeViaEmail = async () => {
        setDisableButtonResetPass(true);
        setOnLoading(true);
        if (userFriendlyAddress !== '' && emailForgot !== '') {
            const resetPass = await ResetPassword(userFriendlyAddress, emailForgot);
            if (resetPass.status.code === 200) {
                setOnLoading(false);
                setShowForgotField(true);
                setDisableButtonResetPass(false);

            } else {
                setOnLoading(false);
                toast.warning(resetPass.status.message);
                setDisableButtonResetPass(false);
            }
        } else {
            toast.warning('Please input your email');
            setOnLoading(false);
            setDisableButtonResetPass(false);
        }
    }

    const handleChangePassword = async () => {
        var validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailForgot == null || emailForgot == '') {
            toast.warning('Email is required !');
            return false;
        } else if (validEmail.test(String(emailForgot.trim()).toLowerCase()) === false) {
            toast.warning('Input email wrong !');
            return false;
        } else if (codeInput == null || repasswordInput == '') {
            toast.warning('Pincode is required !');
            return false;
        } else if (newPassWord == null || newPassWord == '') {
            toast.warning('Password is required !');
            return false;
        }
        else if (repasswordInput == null || repasswordInput == '') {
            toast.warning('Renew-password is required !');
            return false;
        } else if (newPassWord !== repasswordInput) {
            toast.warning("Password and Renew-password don't math !");
            return false;
        }
        if (userFriendlyAddress !== '' && emailForgot !== '' && newPassWord !== '' && repasswordInput !== '' && codeInput !== '') {
            setOnLoading(true);
            setDisableButtonCreateNewPass(true);
            const valid = validateCreateAccount(emailForgot, newPassWord, repasswordInput);
            if (valid) {
                const changePass = await ChangePasswordForget(userFriendlyAddress, emailForgot, repasswordInput, codeInput);
                if (changePass.status.code === 200) {
                    setOnLoading(false);
                    setShowForgotField(false);
                    setShowForgotPass(false);
                    setShowGameProfile(true);
                    setDisableButtonCreateNewPass(false);
                    toast.success('You update new password successfully!');
                } else {
                    setOnLoading(false);
                    toast.warning(changePass.status.message);
                    setDisableButtonCreateNewPass(false);
                }
            }
        } else {
            toast.warning('Please input all fields');
            setOnLoading(false);
            setDisableButtonCreateNewPass(false);
        }
    }

    const fetchDataTonBalance = async () => {
        const config_addressBalance = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://tonapi.io/v2/blockchain/accounts/${userFriendlyAddress}`,
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${TonApiKey}`
            }
        };

        try {
            const response = await axios.request(config_addressBalance);
            const data = typeof response.data == 'undefined' ? null : response.data;
            if (data != null) {
                const balance = (data.balance / 10 ** 9).toFixed(5);
                setTonBalance(balance);
                localStorage.setItem('myTonBalance', balance);
            } else {
                }
        } catch (error) { }
    };

    const init = async () => {
        const myAccount = await JSON.parse(localStorage.getItem('myAccount'));
        const token = await JSON.parse(localStorage.getItem('token'));
        if (myAccount == null && token == null) {
            navigate('/connect');
        } else {
            navigate('/my-account');
        }
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <div className="racerConnect-wrapper">
            <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={12}>
                    <div className="sidebar">
                        <a className="back-page" onClick={() => goToMarket()}>
                            <FiArrowLeft />
                            <span>Go Back</span>
                        </a>
                    </div>
                </Grid>
                <Grid item lg={8} md={8} xs={12}>
                    <div className="main-content">
                        <h3 className="title_main">CONNECT WALLET</h3>
                        <span>Connect with your available wallet or create new wallet to join our marketplace</span>
                        <div className="mt-3 mb-3">
                            {
                                !userFriendlyAddress ?
                                <TonConnectButton />
                                :
                                <button className="btn btn-connectMetaMask" onClick={LoginWithTon}><img src={TonCoin} alt="" /> Connect with TON</button>
                            }
                        </div>
                        {/* <button className="btn btn-main text-white" onClick={() => setOnLoading(true)}>click show loading</button> */}
                        <span>We do not own your private keys and cannot access your funds without your confimation. <a href='https://docs.meta-racers.com/faqs/terms-of-use' target="_blank"><strong className="text-primary">See term of user</strong></a></span>
                    </div>
                </Grid>
            </Grid>
            {
                showConnect && (
                    <div className="popUp show popUpWrapper_LinkToAccount">
                        <div className="popUp_Content popUp_LinkToAccount">
                            <FiX className="close-popUp" size={24} onClick={() => { setShowConnect(!showConnect) }} />
                            <h3 className="text-primary">YOU ARE ALMOST THERE!</h3>
                            <span>Connect your game account to countinue on marketplace.</span>
                            {
                                isExist ?
                                    <button className="btn btn-linkAccount" onClick={handleShowProfile}>Link to Existing game account</button>
                                    :
                                    null
                            }
                            {
                                !isExist ?
                                    <button className="btn btn-createAccount" onClick={handleCreateProfile}>Create new game account</button>
                                    :
                                    null
                            }

                            <div className="forgotPassword">
                                <span>If you forgot your password, please click the link.</span>
                                <button className="btn btn-forgotPassword mb-0" onClick={handleShowForgotPass}>Forgot password</button>
                            </div>


                            {/* <button className="btn btn-main btn-linkAccount" onClick={handleShowProfile}>Link to Existing game account</button> */}
                            {/* <button className="btn btn-main btn-createAccount" onClick={handleShowProfile}>Create new game account</button> */}
                        </div>
                    </div>
                )
            }
            {
                showGameProfile && (
                    <div className="popUp show popUpWrapper_GameProfile">
                        <div className="popUp_GameProfile">
                            <FiX className="close-popUp" size={24} onClick={() => { setShowGameProfile(!showGameProfile) }} />
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={4} xs={12}>
                                    <img src={Banner} alt="" />
                                </Grid>
                                <Grid item lg={8} md={8} xs={12}>
                                    <div className="p5">
                                        <h3 className="title_main">LINK GAME ACCOUNT</h3>
                                        <TextField
                                            fullWidth
                                            className="text-white"
                                            id="outlined-required"
                                            label="Email"
                                            value={emailInput}
                                            placeholder="Email"
                                            onChange={e => setEmailInput(e.target.value)}
                                        />
                                        <TextField
                                            className="ip_password"
                                            id="outlined-password-input"
                                            label="Password"
                                            type="password"
                                            autoComplete="current-password"
                                            value={passwordInput}
                                            onChange={e => setPasswordInput(e.target.value)}
                                        />
                                        <Grid container spacing={2} className="mt-3">
                                            <Grid item lg={12} md={12} xs={12}>
                                                <button className="btn btn-CreateAccount" onClick={() => {
                                                    handleLinkAccount();
                                                }} disabled={disableButtonLinkAccount}>
                                                    Link Account
                                                </button>
                                                <span className="mt-2 font-14">
                                                    By continuing, you agree to <a href='https://docs.meta-racers.com/faqs/terms-of-use' target="_blank"><b className="text-primary">MetaRacers Terms of Services</b></a> and that you have read MetaRacers Terms
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )
            }

            {
                showCreateProfile && (
                    <div className="popUp show popUpWrapper_GameProfile">
                        <div className="popUp_GameProfile">
                            <FiX className="close-popUp" size={24} onClick={() => { setShowCreateProfile(!showCreateProfile) }} />
                            <Grid container spacing={2}>
                                <Grid item lg={4} md={4} xs={12}>
                                    <img src={Banner} alt="" />
                                </Grid>
                                <Grid item lg={8} md={8} xs={12}>
                                    <div className="p5">
                                        <h3 className="title_main">CREATE GAME ACCOUNT</h3>
                                        <TextField
                                            fullWidth
                                            className="text-white"
                                            id="create-required-email"
                                            label="Email"
                                            placeholder="Email"
                                            value={emailCreate}
                                            onChange={e => setEmailCreate(e.target.value)}
                                        />
                                        <TextField
                                            className="ip_password"
                                            id="create-password-ip"
                                            label="Password"
                                            type="password"
                                            autoComplete="current-password"
                                            value={passwordCreate}
                                            onChange={e => setPasswordCreate(e.target.value)}
                                        />
                                        <TextField
                                            className="ip_password"
                                            id="create-repassword-input"
                                            label="Re password"
                                            type="password"
                                            autoComplete="current-password"
                                            value={rePasswordCreate}
                                            onChange={e => setRePasswordCreate(e.target.value)}
                                        />
                                        <Grid container spacing={2} className="mt-3">
                                            <Grid item lg={12} md={12} xs={12}>
                                                <button className="btn btn-CreateAccount" onClick={() => {
                                                    handleCreateAccount();
                                                }} disabled={disableButtonCreate}>
                                                    Create Account
                                                </button>
                                                <span className="mt-2 font-14">
                                                    By continuing, you agree to <a href='https://docs.meta-racers.com/faqs/terms-of-use' target="_blank"><b className="text-primary">MetaRacers Terms of Services</b></a> and that you have read MetaRacers Terms
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )
            }

            {
                showForgotPass && (
                    <div className="popUp show popUpWrapper_GameProfile">
                        <div className="popUp_ForgotPassWord">
                            <FiX className="close-popUp" size={24} onClick={() => { setShowForgotPass(!showForgotPass) }} />
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} xs={12}>
                                    <div className="p5">
                                        <h3 className="title_main">FORGOT PASSWORD</h3>
                                        <div className="fotgotPassword-wrap">
                                            <TextField
                                                fullWidth
                                                className="text-white mb-3"
                                                id="forgot-required"
                                                label="Email"
                                                value={emailForgot}
                                                placeholder="Email"
                                                onChange={e => setEmailForgot(e.target.value)}
                                            />
                                            {
                                                !checkForgotField ?
                                                    <button className="btn btn-sendCodeEmail" onClick={handleSendCodeViaEmail} disabled={disableButtonResetPass}>
                                                        Send code via Email
                                                    </button>
                                                    :

                                                    (
                                                        <>
                                                            <TextField
                                                                fullWidth
                                                                className="text-white"
                                                                id="forgot-outlined-required"
                                                                label="Code"
                                                                value={codeInput}
                                                                placeholder="Code"
                                                                onChange={e => setCodeInput(e.target.value)}
                                                            />
                                                            <TextField
                                                                className="ip_password"
                                                                id="forgot-password-input"
                                                                label="Password"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                value={newPassWord}
                                                                onChange={e => setNewPassWord(e.target.value)}
                                                            />
                                                            <TextField
                                                                className="ip_password mb-3"
                                                                id="forgot-repassword-input"
                                                                label="Re Password"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                value={repasswordInput}
                                                                onChange={e => setRePasswordInput(e.target.value)}
                                                            />
                                                            <span className="text-primary mb-2 d-block font-12">We have sent a code to your email. Please check your email and enter the code.</span>
                                                            <button className="btn btn-CreateAccount" onClick={handleChangePassword} disabled={disableButtonCreateNewPass}>
                                                                Create new password
                                                            </button>
                                                        </>
                                                    )

                                            }
                                        </div>
                                        <span className="mt-4 d-block font-14 text-center">
                                            By continuing, you agree to <a href='https://docs.meta-racers.com/faqs/terms-of-use' target="_blank"><b className="text-primary">MetaRacers Terms of Services</b></a> and that you have read MetaRacers Terms
                                        </span>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                )
            }

            {
                openSuccess && (
                    <div className="popUp show popUpWrapper_claim">
                        <div className="popUp_Content popUp_claim">
                            <FiX className="close-popUp" size={24} onClick={() => { setOpenSuccess(!openSuccess) }} />
                            <FiCheck className="success" width={32} />
                            <span>Create account success</span>
                        </div>
                    </div>
                )
            }

            {
                openLinkSuccess && (
                    <div className="popUp show popUpWrapper_claim">
                        <div className="popUp_Content popUp_claim">
                            <FiX className="close-popUp" size={24} onClick={() => { setOpenLinkSuccess(!openLinkSuccess) }} />
                            <FiCheck className="success" width={32} />
                            <span>Link account success</span>
                        </div>
                    </div>
                )
            }

            {
                openError && (
                    <div className="popUp show popUpWrapper_claim">
                        <div className="popUp_Content popUp_claim">
                            <FiX className="close-popUp" size={24} onClick={() => { setOpenError(!openError) }} />
                            <FiX className="success" width={32} />
                            <span>Create account Error</span>
                        </div>
                    </div>
                )
            }

            {
                openLinkError && (
                    <div className="popUp show popUpWrapper_claim">
                        <div className="popUp_Content popUp_claim">
                            <FiX className="close-popUp" size={24} onClick={() => { setOpenLinkError(!openLinkError) }} />
                            <FiX className="success" width={32} />
                            <span>Link account Error</span>
                        </div>
                    </div>
                )
            }

            {
                onLoading && (
                    <div className="loading-wrapper">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                )
            }
        </div>
    );
}

export default ConnectPage;
