import React, { useEffect, useState, useMemo } from "react";
import { useTonAddress } from '@tonconnect/ui-react';

import {
    FiSettings,
    FiCodesandbox,
    FiCopy,
    FiCreditCard,
    FiUsers,
    FiRepeat,
} from "react-icons/fi";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { useToast } from 'rc-toastr';

import Grid from '@mui/material/Grid';

import Avatar from '../../../assets/images/dashboard/avatar.png';

import AccountSettingPage from "./accountSetting";
import WalletSettingPage from "./wallet";
import ConvertSettingPage from "./convert";
import ReferralSettingPage from "./referral";
import InventorySettingPage from "./inventory";

const AccountPage = () => {
    
    const { toast } = useToast();
    const userFriendlyAddress = useTonAddress();

    const name_account = userFriendlyAddress ? `${userFriendlyAddress.slice(0, 12)}...${userFriendlyAddress.slice(userFriendlyAddress.length - 8,userFriendlyAddress.length)}` : "";

    const FormWithToasts = () => {

        const copyToClipBoard = async copyMe => {
            try {
                await navigator.clipboard.writeText(copyMe);
                toast.success('Copied Successfully');
            } catch (err) {
                toast.warning('Failed to copy!');
            }
        };
        return (
            <FiCopy className="copy" onClick={() => copyToClipBoard(userFriendlyAddress)} />
        )
    };

    return (
        <>
            <section className="account-wrapper">
                <Tabs forceRenderTabPanel defaultIndex={0}>
                    <Grid container spacing={2}>
                        <Grid item lg={4} md={4} xs={12}>
                            <div className="sidebar">
                                <div className="account-settings">
                                    <div className="info-account">
                                        <img src={Avatar} alt="" />
                                        <h3 className="name"></h3>
                                        <div className="wallet-adress">
                                            <span>{name_account}</span>
                                            <FormWithToasts />
                                        </div>
                                    </div>
                                </div>
                                <TabList className="tabs-account">
                                    <Tab>
                                        <span><FiCreditCard size={32} /> Wallet</span>
                                    </Tab>
                                    <Tab>
                                        <span><FiRepeat size={32} /> Convert</span>
                                    </Tab>
                                    <Tab>
                                        <span><FiCodesandbox size={32} /> Account Setting</span>
                                    </Tab>
                                    <Tab>
                                        <span><FiSettings size={32} /> Inventory</span>
                                    </Tab>
                                    {/* <Tab>
                                        <span><FiUsers size={32} /> Referral Program</span>
                                    </Tab> */}
                                </TabList>
                            </div>
                        </Grid>
                        <Grid item lg={8} md={8} xs={12}>
                            <div className="main-content">
                                <TabPanel>
                                    <WalletSettingPage />
                                </TabPanel>
                                <TabPanel>
                                    <ConvertSettingPage />
                                </TabPanel>
                                <TabPanel>
                                    <AccountSettingPage />
                                </TabPanel>
                                <TabPanel>
                                    <InventorySettingPage />
                                </TabPanel>
                                {/* <TabPanel>
                                    <ReferralSettingPage />
                                </TabPanel> */}
                            </div>
                        </Grid>
                    </Grid>
                </Tabs>
            </section>
            
        </>
    );

}

export default AccountPage;
