import React, { useState, useEffect } from "react";
import { FiPlus, FiMinus, FiArrowLeft, FiX } from "react-icons/fi";
import Alert from '@mui/material/Alert';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Open.css';

import {
    BrowserRouter as Router,
    Link,
} from "react-router-dom";

import { cars } from '../../../assets/images/index';
import EpicBox from '../../../assets/images/box/box-save2.png';

import { useToast } from 'rc-toastr';
import { useNavigate } from "react-router-dom";

import { checkExistAccountWithAddress, GetNumberBox, OpenBoxWithWeb } from '../../../api/api';

const ColorType = ({ type }) => {
    return (
        <>
            {
                type === 'common' && (
                    <span className="type-common">Common</span>
                )
            }
            {
                type === 'epic' && (
                    <span className="type-epic">Epic</span>
                )
            }

            {
                type === 'legendary' && (
                    <span className="type-lengendary">Legendary</span>
                )
            }
        </>

    )
}

const OpenRacerBox = ({ address }) => {

    const history = useNavigate();
    const { toast } = useToast();

    const totalBox = 10;
    let carOpen = cars.slice(0, 1);

    const [quantily, setCount] = useState(0);
    const [checkDisableMinus, setCheckDisable] = useState(totalBox === 0 ? true : false);
    const [checkDisablePlus, setCheckDisablePlus] = useState(totalBox === 0 ? true : false);

    const [showCheckOut, setShowCheckOut] = useState(false);
    const [showCarOpen, setShowCarOpen] = useState(false);
    const [onLoading, setOnLoading] = useState(false);

    const [newCar, setNewCar] = useState({ carId: 'Car_001', name: 'NEW CAR' })


    const handleClose = () => setShowCheckOut(false);

    const handleOpen = () => {
        setShowCheckOut(true)
    };


    // function handlePlusClick() {
    //     setCount(quantily < totalBox ? quantily + 1 : quantily);
    // }

    // function handleMinusClick() {
    //     setCount(quantily === 0 ? 0 : quantily - 1);
    // }

    // const hanldeMaxQuantily = () => {
    //     setCount(totalBox);
    // }

    const LoadImagePosition = (carId) => {
        const param = "car_" + parseInt(carId.toLowerCase().split("_")[1]).toString() + "-min.png";
        return (<img src={require('../../../assets/images/car/' + param)} alt="" />)
    }

    const handleOpenBox = async () => {
        // setOnLoading(true);
        // setShowCheckOut(false);
        // setTimeout(() => {
        //     setOnLoading(false);
        //     setShowCarOpen(true);
        // }, 1000)

        setOnLoading(true);
        const myAccount = await JSON.parse(localStorage.getItem('myAccount'));
        if (myAccount != null) {
            const { email, addressWallet } = myAccount;
            const openBox = await OpenBoxWithWeb(addressWallet, email);
            if (openBox.status.code === 200) {

                setNewCar({ carId: openBox.data.carId, name: openBox.data.name, carClass: openBox.data.carClass });
                setShowCarOpen(true);
                setOnLoading(false);
                setShowCheckOut(false);
                setTimeout(() => {
                    // setOnLoading(false);
                    setShowCarOpen(true);
                }, 500)
            } else {
                toast.warning(openBox.status.message);
                setOnLoading(false);
                setShowCheckOut(false);
            }
        }
    }

    useEffect(() => {
        if (quantily === 0) {
            setCheckDisable(true);
        } else if (quantily === 10) {
            setCheckDisablePlus(true);
        } else {
            setCheckDisable(false);
            setCheckDisablePlus(false);
        }
    }, [quantily]);

    const goToRacerBox = () => {
        history.push('/racer-box')
    }


    return (
        <>
            <section className="racerbox-wrapper">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <a className="back-page" onClick={() => goToRacerBox()}>
                                    <FiArrowLeft />
                                    <span>Go Back</span>
                                </a>
                                <div className="reacerBox-title">
                                    RACER BOX
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12">
                            <div className="RacerBoxDetail">
                                <div className="RacerBoxDetailLeft OpenRacerDetailLeft text-center">
                                    <div className="box-img">
                                        <img src={EpicBox} alt="" />
                                    </div>
                                    {/* <div className="box-remaining mb-2">
                                        Choose box quantity to open
                                    </div>
                                    <div className="box-quantily">
                                        <button className="btn-minus btn" onClick={handleMinusClick} disabled={checkDisableMinus ? true : false}>
                                            <FiMinus size={24} />
                                        </button>
                                        <span className="box-quantilyNumber">{quantily} / {totalBox}</span>
                                        <div>
                                            <button className="btn-plus btn" onClick={handlePlusClick} disabled={checkDisablePlus ? true : false}>
                                                <FiPlus size={24} />
                                            </button>
                                            <button className="btn-plus btn ml-2" onClick={hanldeMaxQuantily} disabled={checkDisablePlus ? true : false}>
                                                MAX
                                            </button>
                                        </div>
                                    </div> */}
                                    <button className="btn btn-buyRacerBox" onClick={handleOpen}>OPEN RACER BOX</button>
                                </div>
                                <div className="RacerBoxDetailRight">
                                    <div className="RacerBox-rate">
                                        <div className="RacerBox-title">
                                            <span>Drop Rate Detail</span>
                                        </div>
                                        <ul>
                                            <li>
                                                <span>Common Car</span>
                                                <h5 className="text-primary">60%</h5>
                                            </li>
                                            <li>
                                                <span>Epic Car</span>
                                                <h5 className="text-primary">32%</h5>
                                            </li>
                                            <li>
                                                <span>Lengendary Car</span>
                                                <h5 className="text-primary">8%</h5>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="RacerBox-available">
                                        <div className="RacerBox-title">
                                            <span>Available cars : 45</span>
                                        </div>
                                        <div className="RacerBox-wrap">
                                            {
                                                cars.map((item, index) => {
                                                    return (
                                                        <div className="RacerBox-car" key={index}>
                                                            <img src={item} alt="" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {
                    showCheckOut && (
                        <div className="popUp show">
                            <div className="popUp_Content popUp_Checkout">
                                <FiX className="close-popUp" size={24} onClick={() => { handleClose(!showCheckOut) }} />
                                <span>You are about to open Racer box</span>
                                <div className="d-flex align-items-center justify-content-between btn-wrapCheckOut">
                                    <button className="btn btn-cancel" onClick={() => { handleClose(!showCheckOut) }}>NO</button>
                                    <button className="btn btn-confirmCheckOut" onClick={handleOpenBox}>YES</button>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    showCarOpen && (
                        <div className="popUp show">
                            <div className="popUp_Content popUp_CheckoutCar">
                                <FiX className="close-popUp" size={24} onClick={() => { setShowCarOpen(!showCarOpen) }} />
                                <div className="listCarOpen">
                                    <div className="RacerBox-car" >
                                        {LoadImagePosition(newCar.carId)}
                                        {/* <img src={item} alt="" /> */}
                                    </div>
                                    {/* {
                                        carOpen.map((item, index) => {
                                            return (
                                                <div className="RacerBox-car" key={index}>
                                                    {LoadImagePosition(item.carId)}
                                                    <img src={item} alt="" />
                                                </div>
                                            )
                                        })
                                    } */}
                                </div>
                                <div className="success-noti">
                                    <h3 className="text-success text-uppercase">Congratulations !!!</h3>
                                    <span>You have opened : <b className="text-primary">{newCar.name}</b></span>
                                    <div className="noti-classType">
                                        <span>Class: </span>
                                        <ColorType type={newCar.carClass == 1 ? "common" : (newCar.carClass == 2 ? "epic" : "legendary")} />
                                    </div>
                                </div>
                                <Link to="/my-account" className="text-primary">
                                    Go to account page
                                </Link>
                            </div>
                        </div>
                    )
                }

                {
                    onLoading && (
                        <div className="loading-wrapper loading-Openbox">
                            <p className="mb-0">Please wait</p>
                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>
                    )
                }
            </section>
        </>
    );
}

export default OpenRacerBox;
