import Bsc from './partner/bsc.svg';
import Unity from './partner/unity.svg';
import MetaMask from './partner/MetaMask.svg';

// home
import LogoMeta from './logo_Metacar.png';
import BgTop from './home/banner-min.png';
import Banner_Home from './home/banner.jpg';
import Play_Home from './home/play.png';
import Line_Home from './home/line-play.svg';
import CarBanner from './home/car_banner-min.png';
import BtnAndroid from './home/btn-android.svg';
import BtnIos from './home/btn-ios.svg';
import BtnBg from './home/btn-bg.svg';
import HomeCar1 from './home/car-about.png';
import HomeCar2 from './home/garage-about.png';
import ArrowRight from './home/arrow_right.svg';
import VideoBanner_Line from './home/line-video.svg';
import VideoBanner_Line2 from './home/line2-video.svg';
import CarVideoBanner from './home/video-banner.png';
import OverlayItem from './home/overlay-item.svg';
import NftBanner from './home/nft_banner.png';
import GamePlayBg from './home/game_play_bg.png';
import GamePlay_Line from './home/line_gameplay.svg';
import BoxGamePlayBg from './home/gameplay_bgitem.png';
import BgIconGame from './home/bg-text.svg';
import FirstBg from './home/first-box.png';
import SecondBg from './home/second_boxbg.svg';
import ThirdBg from './home/third_boxbg.svg';
import RoadmapTimeline from './home/roadmap-timeline.png';
import RoadmapTitle from './home/roadmap_bgtitle.svg';
import RoadmapBg from './home/backgroud_roadmap.png';
import TeamBgItem from './home/coreteam_bg.svg';
import AdvisorBgItem from './home/advisor-bg.svg';
import AdvisorBg from './home/advisor_bg.png';
import AdvisorContentBg from './home/advisor_item.svg';
import CoinBg from './home/coin_bg.png';
import Android from './about/Icon_android.svg';
import WhitePaper from './about/document.png';
import PlayGame from './about/play-game.png';
import Ios from './about/Icon_IOS.svg';
import PerCar from './media/panel-car.jpg';
import Linkedin from './team/linkedin.png';
import MemPhis from './home/bg-team.png';

// game play
import PvEIcon from './gameplay/pve-min.png';
import PvPIcon from './gameplay/pvp-min.png';
import EventIcon from './gameplay/event-min.png';
import MarketPlaceIcon from './gameplay/marketplace-min.png';
import First from './gameplay/1st-min.png';
import Second from './gameplay/2nd-min.png';
import Third from './gameplay/3rd-min.png';
import SoloRacer from './gameplay/pve-item.png';
import BattleRacer from './gameplay/2vs2-min.png';
import EventRacer from './gameplay/rank-min.png';
import MarketPlace from './gameplay/buy-min.png';
import Point from './roadmap/Icon_Point.png';
import Icon_Play2earn from './coin/free to earn-min.png';
import Icon1 from './coin/img_arrow1.png';
import Icon2 from './home/img_arrow2.svg';
import RacerCoin from './home/coin-base.svg';
import Exchange from './coin/exchange-min.png';
import Trade from './coin/coin-trade.png';
import Staking from './coin/staking-min.png';
import SummonHero from './coin/crypt-cracer.png';
import Market from './coin/market2-min.png';
import Evolve from './coin/evolve heroes-min.png';
import BgRacer from './bg-racer.jpg';
import Border_Coin from './coin/border-coin.svg';

// Import Full Car
import Car1 from './nfts/fullcar-min/car_1-min.png';
import Car2 from './nfts/fullcar-min/car_2-min.png';
import Car3 from './nfts/fullcar-min/car_3-min.png';
import Car4 from './nfts/fullcar-min/car_4-min.png';
import Car5 from './nfts/fullcar-min/car_5-min.png';
import Car6 from './nfts/fullcar-min/car_6-min.png';
import Car7 from './nfts/fullcar-min/car_7-min.png';
import Car8 from './nfts/fullcar-min/car_8-min.png';
import Car9 from './nfts/fullcar-min/car_9-min.png';
import Car10 from './nfts/fullcar-min/car_10-min.png';
import Car11 from './nfts/fullcar-min/car_11-min.png';
import Car12 from './nfts/fullcar-min/car_12-min.png';
import Car13 from './nfts/fullcar-min/car_13-min.png';
import Car14 from './nfts/fullcar-min/car_14-min.png';
import Car15 from './nfts/fullcar-min/car_15-min.png';
import Car16 from './nfts/fullcar-min/car_16-min.png';
import Car17 from './nfts/fullcar-min/car_17-min.png';
import Car18 from './nfts/fullcar-min/car_18-min.png';
import Car19 from './nfts/fullcar-min/car_19-min.png';
import Car20 from './nfts/fullcar-min/car_20-min.png';
import Car21 from './nfts/fullcar-min/car_21-min.png';
import Car22 from './nfts/fullcar-min/car_22-min.png';
import Car23 from './nfts/fullcar-min/car_23-min.png';
import Car24 from './nfts/fullcar-min/car_24-min.png';
import Car25 from './nfts/fullcar-min/car_25-min.png';
import Car26 from './nfts/fullcar-min/car_26-min.png';
import Car27 from './nfts/fullcar-min/car_27-min.png';
import Car28 from './nfts/fullcar-min/car_28-min.png';
import Car29 from './nfts/fullcar-min/car_29-min.png';
import Car30 from './nfts/fullcar-min/car_30-min.png';
import Car31 from './nfts/fullcar-min/car_31-min.png';
import Car32 from './nfts/fullcar-min/car_32-min.png';
import Car33 from './nfts/fullcar-min/car_33-min.png';
import Car34 from './nfts/fullcar-min/car_34-min.png';
import Car35 from './nfts/fullcar-min/car_35-min.png';
import Car36 from './nfts/fullcar-min/car_36-min.png';
import Car37 from './nfts/fullcar-min/car_37-min.png';
import Car38 from './nfts/fullcar-min/car_38-min.png';
import Car39 from './nfts/fullcar-min/car_39-min.png';
import Car40 from './nfts/fullcar-min/car_40-min.png';
import Car41 from './nfts/fullcar-min/car_41-min.png';
import Car42 from './nfts/fullcar-min/car_42-min.png';
import Car43 from './nfts/fullcar-min/car_43-min.png';
import Car44 from './nfts/fullcar-min/car_44-min.png';
import Car45 from './nfts/fullcar-min/car_45-min.png';


//Import partner
import BscPartners from './partner/logo-bsc.png';
// import Brayang from './partner/logobrayangstudio.png';
import AU21 from './partner/Logo_AU21.png';
import Onebit from './partner/logo_onebit2.png';
import SGNCapital from './partner/logo_SGN.png';
import BDVentures from './partner/Logo_BD2.png';
import KingdomGame from './partner/logo-KDG.png';
import CoinLab from './partner/Logo_coinlab.png';
import GCrypto from './partner/Logo_GCrypto.png';
import ESVentures from './partner/Logo_ES.png';
import DIF from './partner/Logo_DIF.png';
import Lotus from './partner/Logo_lotus.png';
import MGVentures from './partner/Logo_MG.png';
import Nextz from './partner/Logo_Nextz.png';
import JLaunchpad from './partner/Logo_jlaunchpad.png';
import AlphaMoon from './partner/Logo_AlphaMoonCapital.png';
import Lambo from './partner/Logo_Lambo.png';
// import Kommunitas from './partner/Logo_Kommunitas.png';
import VNDC from './partner/Logo_VNDC.png';
import FIM from './partner/Logo_FIM.png';
import Halfen from './partner/Logo_Halfen.png';
import Coincu from './partner/Logo_coincu.png';
import Goldenshovel from './partner/Logo_GoldenShovel2.png';
import LFG from './partner/Logo_LFG.png';
import WildCat from './partner/Logo_WildCat3.png';
import AKA from './partner/Logo_aka.png';
import BIC from './partner/Logo_bic.png';
// import Nuly from './partner/Logo_nuly.png';
import FVentures from './partner/Logo_FVentures3.png';
import Cato from './partner/Logo_cato.png';
import Naut from './partner/Logo_naut.png';
import Jadelab from './partner/Logo_jadelab.png';
import Moniwar from './partner/Logo_moniwar.png';
import BAS from './partner/Logo_BAS.png';
import DTS from './partner/Logo_DTS.png';
import Basic from './partner/Logo_Basic.png';
import MCV from './partner/Logo_MCV.png';
import DareNFT from './partner/Logo_DareNFT.png';
import Dreamboat from './partner/Logo_dreamboat2.png';
// import Binstarter from './partner/Logo_BinStarter.png';
import Daku from './partner/Logo_daku.png';
import CRVN from './partner/Logo_CRVN2.png';
import CryptoLeakVN from './partner/Logo_CryptoLeakVN2.png';
import Traveler from './partner/Logo_Traveler.png';
import GCafe from './partner/Logo_CafeCrypto.png';
import Legion from './partner/Logo_Legion.png';
import Senpark from './partner/Logo_Senpark.png';
import BTCMonday from './partner/Logo_BTCMonday.png';
import KVVentures from './partner/Logo_KVVentures.png';
import RocketOne from './partner/Logo_RocketOne2.png';
import MIFU from './partner/Logo_MIFU.png';
import Infinity from './partner/Logo_Infinity.png';
import MHVentures from './partner/Logo_MHVentures.png';
import Vespertine from './partner/Logo_Vespertine.png';

// Import Team
import CoreTeam_XuanCanh from './team/xuancanh.png';
import CoreTeam_TienDung from './team/tiendung.png';
import CoreTeam_HaoNguyen from './team/haonguyen.png';
import CoreTeam_DungLe from './team/dungle.png';
import CoreTeam_HuyTrinh from './team/huytrinh.png';
import CoreTeam_DucNguyen from './team/ducnguyen.png';
import CoreTeam_KhoaNguyen from './team/khoanguyen.png';
import CoreTeam_HuyLe from './team/huyle.png';
import CoreTeam_KhoaCao from './team/khoacao.png';
import CoreTeam_PhuongNguyen from './team/phuongle.png';
import CoreTeam_Nhile from './team/nhile.png';
import CoreTeam_KhanhNguyen from './team/khanhnguyen.png';
import CoreTeam_CamCo from './team/coha.png';

// Import Advisor
import Advisor1 from './advisor/1.png';
import Advisor2 from './advisor/2.png';
import Advisor3 from './advisor/3.jpeg';
import Advisor4 from './advisor/4.png';

// icon social
import Facebook from './media/Icon_facebook.png';
import Twitter from './media/Icon_Twitter.png';
import Discord from './media/Icon_Discord.png';
import Telegram from './media/icon_telegram.png';
import Gmail from './media/email.png';
import Medium from './media/iconmonstr_medium.png';

// Car market

import RacerCar1 from './car/car_1-min.png';
import RacerCar2 from './car/car_2-min.png';
import RacerCar3 from './car/car_3-min.png';
import RacerCar4 from './car/car_4-min.png';
import RacerCar5 from './car/car_5-min.png';
import RacerCar6 from './car/car_6-min.png';
import RacerCar7 from './car/car_7-min.png';
import RacerCar8 from './car/car_8-min.png';
import RacerCar9 from './car/car_9-min.png';
import RacerCar10 from './car/car_10-min.png';
import RacerCar11 from './car/car_11-min.png';
import RacerCar12 from './car/car_12-min.png';
import RacerCar13 from './car/car_13-min.png';
import RacerCar14 from './car/car_14-min.png';
import RacerCar15 from './car/car_15-min.png';
import RacerCar16 from './car/car_16-min.png';
import RacerCar17 from './car/car_17-min.png';
import RacerCar18 from './car/car_18-min.png';
import RacerCar19 from './car/car_19-min.png';
import RacerCar20 from './car/car_20-min.png';
import RacerCar21 from './car/car_21-min.png';
import RacerCar22 from './car/car_22-min.png';
import RacerCar23 from './car/car_23-min.png';
import RacerCar24 from './car/car_24-min.png';
import RacerCar25 from './car/car_25-min.png';
import RacerCar26 from './car/car_26-min.png';
import RacerCar27 from './car/car_27-min.png';
import RacerCar28 from './car/car_28-min.png';
import RacerCar29 from './car/car_29-min.png';
import RacerCar30 from './car/car_30-min.png';
import RacerCar31 from './car/car_31-min.png';
import RacerCar32 from './car/car_32-min.png';
import RacerCar33 from './car/car_33-min.png';
import RacerCar34 from './car/car_34-min.png';
import RacerCar35 from './car/car_35-min.png';
import RacerCar36 from './car/car_36-min.png';
import RacerCar37 from './car/car_37-min.png';
import RacerCar38 from './car/car_38-min.png';
import RacerCar39 from './car/car_39-min.png';
import RacerCar40 from './car/car_40-min.png';
import RacerCar41 from './car/car_41-min.png';
import RacerCar42 from './car/car_42-min.png';
import RacerCar43 from './car/car_43-min.png';
import RacerCar44 from './car/car_44-min.png';
import RacerCar45 from './car/car_45-min.png';


// Car detail icon

import Engine from './dashboard/Engine.svg';
import Acceleration from './dashboard/Acceleration.svg';
import Transmission from './dashboard/Transmission.svg';
import Nitro from './dashboard/Nitro.svg';
import Power from './dashboard/power.png';
import Mission from './dashboard/mission.png';

const detail_icon = [Engine, Acceleration, Transmission, Nitro, Power, Mission]

const racer_car = [
    RacerCar1, RacerCar2, RacerCar3, RacerCar4, RacerCar5, RacerCar6, RacerCar7, RacerCar8, RacerCar9, RacerCar10, RacerCar11,
    RacerCar12, RacerCar13, RacerCar14, RacerCar15, RacerCar16, RacerCar17, RacerCar18, RacerCar19, RacerCar20, RacerCar21,
    RacerCar22, RacerCar23, RacerCar24, RacerCar25, RacerCar26, RacerCar27, RacerCar28, RacerCar29, RacerCar30, RacerCar31,
    RacerCar32, RacerCar33, RacerCar34, RacerCar35, RacerCar36, RacerCar37, RacerCar38, RacerCar39, RacerCar40, RacerCar41,
    RacerCar42, RacerCar43, RacerCar44, RacerCar45
]

const socail = { Facebook, Twitter, Discord, Telegram, Gmail, Medium };
const images = [Bsc, Unity, MetaMask];
const cars = [
    Car1, Car2, Car3, Car4, Car5, Car6, Car7, Car8, Car9, Car10,
    Car11, Car12, Car13, Car14, Car15, Car16, Car17, Car18, Car19, Car20,
    Car21, Car22, Car23, Car24, Car25, Car26, Car27, Car28, Car29, Car30,
    Car31, Car32, Car33, Car34, Car35, Car36, Car37, Car38, Car39, Car40,
    Car41, Car42, Car43, Car44, Car45
];

const homes = {
    LogoMeta: LogoMeta,
    BannerHome: Banner_Home,
    Play_Home: Play_Home,
    Line_Home: Line_Home,
    BgTop: BgTop,
    CarBanner: CarBanner,
    BtnAndroid: BtnAndroid,
    BtnIos: BtnIos,
    BtnBg: BtnBg,
    HomeCar1: HomeCar1,
    HomeCar2: HomeCar2,
    ArrowRight: ArrowRight,
    CarVideoBanner: CarVideoBanner,
    VideoBanner_Line: VideoBanner_Line,
    VideoBanner_Line2: VideoBanner_Line2,
    OverlayItem: OverlayItem,
    MemPhis: MemPhis,
    NftBanner: NftBanner,
    GamePlayBg: GamePlayBg,
    GamePlay_Line: GamePlay_Line,
    BoxGamePlayBg: BoxGamePlayBg,
    BgIconGame: BgIconGame,
    FirstBg: FirstBg,
    SecondBg: SecondBg,
    ThirdBg: ThirdBg,
    RoadmapTimeline: RoadmapTimeline,
    RoadmapTitle: RoadmapTitle,
    RoadmapBg: RoadmapBg,
    TeamBgItem: TeamBgItem,
    AdvisorBgItem: AdvisorBgItem,
    AdvisorBg: AdvisorBg,
    AdvisorContentBg: AdvisorContentBg,
    CoinBg: CoinBg,
    Android: Android,
    WhitePaper: WhitePaper,
    PlayGame: PlayGame,
    Ios: Ios,
    PerCar: PerCar,
    Linkedin: Linkedin
}

const games_play = {
    PvEIcon: PvEIcon,
    PvPIcon: PvPIcon,
    EventIcon: EventIcon,
    MarketPlaceIcon: MarketPlaceIcon,
    First: First,
    Second: Second,
    Third: Third,
    SoloRacer: SoloRacer,
    BattleRacer: BattleRacer,
    EventRacer: EventRacer,
    MarketPlace: MarketPlace,
    Point: Point,
    Icon_Play2earn: Icon_Play2earn,
    Icon1: Icon1,
    Icon2: Icon2,
    RacerCoin: RacerCoin,
    Exchange: Exchange,
    Trade: Trade,
    Staking: Staking,
    SummonHero: SummonHero,
    Market: Market,
    Evolve: Evolve,
    BgRacer: BgRacer,
    Border_Coin: Border_Coin
}

const partners = {
    Bsc: BscPartners,
    AU21: AU21,
    Onebit: Onebit,
    SGNCapital: SGNCapital,
    BDVentures: BDVentures,
    KingdomGame: KingdomGame,
    CoinLab: CoinLab,
    GCrypto: GCrypto,
    ESVentures: ESVentures,
    DIF: DIF,
    Lotus: Lotus,
    MGVentures: MGVentures,
    Nextz: Nextz,
    JLaunchpad: JLaunchpad,
    AlphaMoon: AlphaMoon,
    Lambo: Lambo,
    VNDC: VNDC,
    FIM: FIM,
    Halfen: Halfen,
    Coincu: Coincu,
    Goldenshovel: Goldenshovel,
    LFG: LFG,
    WildCat: WildCat,
    AKA: AKA,
    BIC: BIC,
    FVentures: FVentures,
    Cato: Cato,
    Naut: Naut,
    Jadelab: Jadelab,
    Moniwar: Moniwar,
    BAS: BAS,
    DTS: DTS,
    Basic: Basic,
    MCV: MCV,
    DareNFT: DareNFT,
    Dreamboat: Dreamboat,
    Daku: Daku,
    CRVN: CRVN,
    CryptoLeakVN: CryptoLeakVN,
    Traveler: Traveler,
    GCafe: GCafe,
    Legion: Legion,
    Senpark: Senpark,
    BTCMonday: BTCMonday,
    KVVentures: KVVentures,
    RocketOne: RocketOne,
    MIFU: MIFU,
    Infinity: Infinity,
    MHVentures: MHVentures,
    Vespertine: Vespertine
}

const teams_img = {
    CoreTeam_XuanCanh: CoreTeam_XuanCanh,
    CoreTeam_TienDung: CoreTeam_TienDung,
    CoreTeam_HaoNguyen: CoreTeam_HaoNguyen,
    CoreTeam_DungLe: CoreTeam_DungLe,
    CoreTeam_HuyTrinh: CoreTeam_HuyTrinh,
    CoreTeam_DucNguyen: CoreTeam_DucNguyen,
    CoreTeam_KhoaNguyen: CoreTeam_KhoaNguyen,
    CoreTeam_HuyLe: CoreTeam_HuyLe,
    CoreTeam_KhoaCao: CoreTeam_KhoaCao,
    CoreTeam_PhuongNguyen: CoreTeam_PhuongNguyen,
    CoreTeam_Nhile: CoreTeam_Nhile,
    CoreTeam_KhanhNguyen: CoreTeam_KhanhNguyen,
    CoreTeam_CamCo: CoreTeam_CamCo
}

const advisors = {
    Advisor1: Advisor1,
    Advisor2: Advisor2,
    Advisor3: Advisor3,
    Advisor4: Advisor4

}

export default images;
export { socail };
export { cars };
export { homes };
export { games_play };
export { partners };
export { teams_img };
export { advisors };
export { racer_car };
export { detail_icon };
